import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
import * as api from '../services/api';
import * as blockui from '../../helpers/block-ui';
import { mixinz } from '../global/mixinz';
import upload from '../../components/shared/upload';
import Condition from '../../components/views/Form/Condition/Condition/Condition.vue';

export default {
  mixins: [mixinz],
  props: {
    slcQuestion: {
      default() {
        return {};
      },
      type: Object,
    },
    procedureTypeId: {
      type: Number,
    },
    questionGroups: {
      default() {
        return [];
      },
      type: Array,
    },
    isUpdate: { default: false },
  },
  data() {
    return {
      isUploadBusy: false,
      selectedPoint: {},
      selectedImage: {},
      fileType: 'Image',
      selectedQuestion: {},
      selectedDevices: [],
      // answerTypes: [],
      // questionStatuses: [],
      selectedFile: {},
      file: {},
      fileStatuses: {
        uploading: 1,
        uploaded: 2,
        canceled: 3,
        error: 4,
        deleted: 5,
      },

      pointDefinitions: [],
      comparisonOperatorList: [],
      operandTypeList: [],
      formSections: [],

      showErrors: false,
    };
  },
  components: {
    upload,
    condition: Condition,
  },

  async created() {
    blockui.blockModal(this);
    const promises = [
      api.getComparisonOperatorList(),
      api.getOperandTypeList(),
      this.$store.dispatch('getCapacityEquipmentBreadCrumbList'),
      this.$store.dispatch('getFormQuestionTypes'),
      this.$store.dispatch('getFormQuestionTypeComparisonOperators'),
      // this.$store.dispatch('getFormQuestionTypes'),
    ];
    const responses = await Promise.all(promises);
    this.comparisonOperatorList = responses[0].body;
    this.operandTypeList = responses[1].body;
    blockui.unBlockModal();
    if (this.isUpdate) {
      const res = await api.getQuestion(this.slcQuestion.Id);
      this.selectedQuestion = res.body;
      await api.getFormSectionList({ procedureTypeId: this.procedureTypeId }).then((res) => {
        this.formSections = res.body.Data;
        this.questionGroups.forEach((questionGroup) => {
          const formSection = this.formSections.find((formSection) => formSection.Id == questionGroup.FormSectionId);
          if (formSection) {
            Vue.set(questionGroup, 'section', this.formSections.find((formSection) => formSection.Id == questionGroup.FormSectionId).Name);
          }
        });
      });
      this.selectFiles();
    } else {
      this.selectedQuestion = this.slcQuestion;
    }

    if (this.isPointLinked) {
      const promises = [
        api.getPointDefinitionList(),
        api.getPoint(this.selectedQuestion.PointId),
      ];
      const responses = await Promise.all(promises);
      this.pointDefinitions = responses[0].body;
      this.selectedPoint = responses[1].body;

      if (!this.isUpdate) {
        Vue.set(this.selectedQuestion, 'QuestionTypeId', this.selectedPoint.FormQuestionTypeId);
        if (this.selectedPoint.FormQuestionTypeId == this.formQuestionType.multipleChoice) {
          this.selectedPoint.PointSelections.forEach((pointSelection) => {
            if (!this.selectedQuestion.Selections) {
              this.selectedQuestion.Selections = [];
            }
            this.selectedQuestion.Selections.push({
              PointSelectionId: pointSelection.Id,
              Name: pointSelection.Name,
              Order: pointSelection.Order,
              IsExpectedValue: false,
            });
          });
        }
      }
    }

    if (this.selectedQuestion && this.selectedQuestion.Selections && this.selectedQuestion.Selections.length > 0) {
      let index = 0;
      this.selectedQuestion.Selections = this.selectedQuestion.Selections.sort((x, y) => x.Order - y.Order).map((x) => {
        const newObj = { ...x, Index: index };
        index++;
        return newObj;
      });
    }
    this.selectFiles();
    // this.answerTypes = this.mockFormAnswerTypes; // MOCK
    // this.selectedQuestion = this.slcQuestion;
    // this.selectedImage = this.selectedQuestion.Image;
    // todo image
  },
  mounted() {
    const self = this;
    window.$('form').validate();
    this.onCloseModal('#question_modal');
    self.$nextTick(() => {
      Vue.loadAsteriks();
    });
  },
  computed: {
    ...mapState({
      capacityEquipmentList: (state) => state.capacity_equipment.capacityEquipmentBreadCrumbList,
      formQuestionType: (state) => state.enums.formQuestionType,
      formQuestionTypeComparisonOperatorList: (state) => state.form.formQuestionTypeComparisonOperatorList,
    }),
    ...mapGetters({
      answerTypes: 'getFormQuestionTypes',
    }),
    selectedQuestionGroup: {
      get() {
        const self = this;
        return self.selectGet('questionGroups', 'selectedQuestion.QuestionGroupId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedQuestion.QuestionGroupId', 'Id', newValue);
      },
    },
    currentComparisonOperators() {
      const filteredFormQuestionTypeComparisonOperators = this.formQuestionTypeComparisonOperatorList.filter((formQuestionTypeComparisonOperator) => formQuestionTypeComparisonOperator.FormQuestionTypeId == this.selectedQuestion.QuestionTypeId);
      return this.comparisonOperatorList.filter((comparisonOperator) => filteredFormQuestionTypeComparisonOperators.find((formQuestionTypeComparisonOperator) => formQuestionTypeComparisonOperator.ComparisonOperatorId == comparisonOperator.Id));
    },
    isPointLinked() {
      return this.selectedQuestion ? this.selectedQuestion.PointId > 0 : false;
    },
    getCurrentDevices() {
      const self = this;
      if (self.selectedQuestion.DeviceIds && self.selectedQuestion.DeviceIds.length > 0) {
        const currentDevices = [];
        self.selectedQuestion.DeviceIds.forEach((device) => {
          currentDevices.push(device);
        });
        return currentDevices;
      }
      return [];
    },
    filteredEquipmentList: {
      get() {
        if (this.$parent.slcForm.DeviceIds.length) {
          return this.capacityEquipmentList.filter((f) => this.$parent.slcForm.DeviceIds.includes(f.Value));
        }
        return this.capacityEquipmentList;
      },
    },
    answerType: {
      get() {
        const self = this;
        const selectedObj = self.answerTypes.find((answerType) => self.selectedQuestion.QuestionTypeId === answerType.Id);
        if (selectedObj) {
          return { Id: self.selectedQuestion.QuestionTypeId, Name: selectedObj.Name };
        }
        return { Id: self.selectedQuestion.QuestionTypeId, Name: 'loading' };
      },
      set(newValue) {
        Vue.set(this.selectedQuestion, 'QuestionTypeId', newValue.Id);
        return newValue;
      },
    },
    questionStatus: {
      get() {
        return { Id: this.selectedQuestion.StatusId, Name: this.selectedQuestion.StatusName };
      },
      set(newValue) {
        Vue.set(this.selectedQuestion, 'StatusId', newValue.Id);
        Vue.set(this.selectedQuestion, 'StatusName', newValue.Name);
        return newValue;
      },
    },
    selections() {
      return this.selectedQuestion.Selections;
    },
  },
  methods: {
    updateOrder(evt) {
      this.selectedQuestion.Selections.forEach((item, index) => {
        if (item.Order != index + 1) {
          item.Order = index + 1;
        }
      });
    },
    selectFiles() {
      const self = this;
      if (self.selectedQuestion.ImageId > 0) {
        const image = self.selectedQuestion.Image;
        this.file = {
          fileId: image.Id,
          title: image.Name,
          type: image.ContentType,
          isActive: true,
          status: self.fileStatuses.uploaded,
          isLocal: false,
        };
        this.selectedFile = this.file;
      }
    },
    updateAnswerType(item) {
      const self = this;
      this.selectedQuestion.AnswerCondition = null; // Metin 
      this.selectedQuestion.Selections = null; // Metin 
      if (this.selectedQuestion.QuestionTypeId == this.formQuestionType.multipleChoice) {
        this.selectedQuestion.Selections = [{ Name: '', Order: 1, Index: 1 }, { Name: '', Order: 2, Index: 2 }];
        this.selectedQuestion.Answer = null;
      } else if (this.selectedQuestion.QuestionTypeId == this.formQuestionType.checkBox) {
        this.selectedQuestion.Selections = [{ Name: '', Order: 1, Index: 1 }];
        this.selectedQuestion.Answer = null;
      } else {
        this.selectedQuestion.Selections = [];
        this.selectedQuestion.Answer = null;
      }
      
      self.$nextTick(() => {
        Vue.loadAsteriks();
      });
    },
    addOption() {
      const self = this;
      const maxOrder = this.selectedQuestion.Selections.reduce((max, selection) => (max > selection.Order ? max : selection.Order), 0);
      const maxIndex = this.selectedQuestion.Selections.reduce((max, selection) => (max > selection.Index ? max : selection.Index), 0);
      this.selectedQuestion.Selections.push({ Name: '', Order: maxOrder + 1, Index: maxIndex + 1 });
      self.$nextTick(() => {
        Vue.loadAsteriks();
      });
    },
    async removeOption(item, index) {
      const self = this;
      self.selectedQuestion.Selections.splice(index, 1);
      self.selectedQuestion.Selections.forEach((item, index) => {
        if (item.Order != index + 1) {
          item.Order = index + 1;
        }
      });
    },
    setSelectedDevices(e) {
      const self = this;
      self.selectedDevices = [];
      e.forEach((deviceId) => {
        self.selectedDevices.push(deviceId);
      });
    },
    isConditionValid(condition) {
      let isValid = true;
      if (!condition) {
        return true;
      }

      for (const child of condition.Children) {
        isValid = !isValid ? false : this.isConditionValid(child);
      }

      for (const answerConditionRule of condition.AnswerConditionRules) {
        isValid = !isValid ? false : this.isConditionRuleValid(answerConditionRule);
      }

      return isValid;
    },
    isSecondValueNeeded(comparisonOperatorId) {
      const selectedComparisonOperator = this.comparisonOperatorList.find((comparisonOperator) => comparisonOperator.Id == comparisonOperatorId);
      if (selectedComparisonOperator) {
        return selectedComparisonOperator.IsSecondValueNeeded;
      }
      return false;
    },
    isConditionRuleValid(conditionRule) {
      return conditionRule.ComparisonOperatorId > 0 && !!conditionRule.FirstValue && (!this.isSecondValueNeeded(conditionRule.ComparisonOperatorId) || !!conditionRule.SecondValue);
    },
    async onSubmit(e) {
      const self = this;
      const form = window.$(this.$refs.questionForm);
      form.validate();
      if (!form.valid() || !self.isConditionValid(self.selectedQuestion.AnswerCondition)) {
        self.showErrors = true;
        return;
      }
      this.selectedQuestion.ImageId = self.file ? self.file.fileId > 0 ? self.file.fileId : null : null;
      try{
        blockui.blockElement(self, self.$el);
        var aa = self.selectedQuestion;
        debugger;
        const response = await this.$globalApiClient.question.questionSave(self.selectedQuestion);
        if (response.data.IsSuccess) {
          toastr.success(self.translate('save_successfully'));
          self.$eventHub.$emit('getFormQuestions');
          $('#question_modal').modal('toggle');
        } else {
          toastr.error(response.data.Message);
        }
        blockui.unBlockElement(self.$el);
      }catch(error){
        toastr.error(error);
        blockui.unBlockElement(self.$el);
      }
    },
  },
  watch: {
    selections(e) {
      const self = this;
      self.$nextTick(() => {
        Vue.loadAsteriks();
      });
    },
  },
  updated() {
    const self = this;
    self.$nextTick(() => {
      Vue.loadAsteriks();
    });
  },
};
