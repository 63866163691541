






























































































































import CFMDialog from '@/scripts/CFMDialog'
import {Component} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'
import {required} from "vuelidate/lib/validators";

import { CFMDTODistrict, CFMDTOCountry, CFMDTOCity, CFMDTOCampus } from '@/scripts/services/ClientApiAuto'

@Component<CampusDialog>({

    components: {
        'cfm-multiselect': CFMMultiselect,
    },

    validations() {
        return {
        campus:{
            Name: {required},
            RelativeHumidity:0,
            Altitude:0
        },
        selectedContinent: {
            ContinentId: {required}
        },
        selectedCountry: {
            CountryId: {required}
        },
        selectedCity: {
            CityId: {required}
        },
        selectedDistrict: {
            DistrictId : {required}
        }
        }
    },
})
export default class CampusDialog extends CFMDialog {
    campus: CFMDTOCampus = {Name: '', IsActive: true};
    continentList : {Name:string, Id:number}[] = [];
    countryList : {Name:string, Id:number}[] = [];
    cityList : {Name:String, Id:Number}[] = [];
    districtList : {Name:String, Id:Number}[] = [];

    selectedContinent :CFMDTOCountry = {Name: ''}
    selectedCountry: CFMDTOCity = {Name: ''};
    selectedCity: CFMDTODistrict = {Name: '', Code: ''};
    selectedDistrict: CFMDTOCampus = {Name: '', IsActive: true};
    

    async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.campus.campusGetById({id: <number>this.id});
        this.campus = res.data;
      }
      const promises = [
        this.getContinents()
      ];
      if(this.isUpdate){
        if(this.campus && this.campus.DistrictId){
          this.selectedDistrict = this.campus;
          promises.push(this.getDistrict( <number>this.selectedDistrict.District?.CityId ));
          if (this.campus.District && this.campus.District.CityId) {
              this.selectedCity = this.campus.District;
              promises.push(this.getCities( <number>this.selectedCity.City?.CountryId ));
              if (this.campus.District.City && this.campus.District.City.CountryId) {
                  this.selectedCountry = this.campus.District.City;
                  promises.push(this.getCountries( <number>this.selectedCountry.Country?.ContinentId ));
                  if (this.campus.District.City.Country && this.campus.District.City.Country.ContinentId) {
                    this.selectedContinent = this.campus.District.City.Country;
                  }
              }
          }
        } 
      await Promise.all(promises);
      blockui.unBlockModal();
      }
    }

    async getContinents(){
      const response = await this.apiClient.continent.continentGetContinents();
      this.continentList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getCountries(continentId: number){
      const response = await this.apiClient.country.countryGetCountries({continentId: continentId});
      this.countryList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getCities(countryId: number){
      const response = await this.apiClient.city.cityGetCities({countryId: countryId});
      this.cityList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getDistrict(cityId: number){
      const response = await this.apiClient.district.districtGetDistricts({cityId: cityId});
      this.districtList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    
    twoDecimal(yourNumber:number){
      return (Math.round(yourNumber*100) / 100).toFixed(2);
    }


    onContinentChanged(args: any){
      this.getCountries(args.ContinentId);
      this.selectedCountry = {Name:''};
      this.selectedCity = {Name:'',Code:''};
      this.selectedDistrict = {Name:'', IsActive: true};

    }
    onCountryChanged(args: any){
      this.getCities(args.CountryId);
      this.selectedCity = {Name:'',Code:''};
      this.selectedDistrict = {Name:'', IsActive: true};
    }
    onCityChanged(args: any){
      this.getDistrict(args.CityId);
      this.selectedDistrict = {Name:'', IsActive: true};
    }

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }

      let formData:CFMDTOCampus = {
        Id: this.campus.Id,
        Name: this.campus.Name,
        CompanyId: this.campus.CompanyId,
        DistrictId: this.selectedDistrict.DistrictId,
        //@ts-ignore
        RelativeHumidity: this.campus.RelativeHumidity ? this.twoDecimal(this.campus.RelativeHumidity)  : null,
        Altitude: this.campus.Altitude,
        IsActive: true
      };
      console.log(formData.RelativeHumidity);
      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.campus.campusSave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }
}
