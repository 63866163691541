import Vue from 'vue';
import { mapState } from 'vuex';
import * as api from '../services/api';
import * as blockui from '../../helpers/block-ui';

export default {
  props: ['workOrderId', 'from'],
  data() {
    return {
      selectedWorkOrder: {},
      subStatusList: [],
      workOrderStatuses: {},
      workOrderSubStatuses: {},
      modalId: 'update_work_order_status_modal',
    };
  },
  async mounted() {
    const self = this;
    window.$('form').validate();
    blockui.blockElementPromise(self, self.$el);

    const promises = [
      api.getWorkOrderSubStatusList().then((res) => {
        res.body.forEach((workOrderSubStatus) => {
          self.workOrderSubStatuses[workOrderSubStatus.Id] = workOrderSubStatus;
        });
      }),
      api.getWorkOrder(this.workOrderId).then((res) => {
        self.selectedWorkOrder = res.body;
      }),
    ];
    await Promise.all(promises);
    await api.getWorkOrderStatusList(self.selectedWorkOrder.OrderSourceId).then((res) => {
      res.body.Data.forEach((workOrderStatus) => {
        self.workOrderStatuses[workOrderStatus.Id] = workOrderStatus;
      });
    }),
    await self.$store.dispatch('getSuitableStatusList', self.selectedWorkOrder.Id);

    blockui.unBlockElement(self.$el);

    $(`#${self.modalId} select[name='OrderStatusId']`).on('change', (e) => {
      blockui.blockModal(self);
      self.subStatusList = [];
      if (e.target.value) {
        api.getWorkOrderSubStatuses({ statusId: e.target.value }).then((res) => {
          self.subStatusList = res.body;
          blockui.unBlockModal();
        });
      } else {
        blockui.unBlockModal();
      }
    });

    $(`#${self.modalId}`).on('hidden.bs.modal', (e) => {
      self.$emit('close');
    });
  },
  methods: {
    onSubmit(e) {
      const self = this;
      const form = window.$(e.target);
      form.validate();
      if (form.valid()) {
        const formData = {};
        blockui.blockModal(self);
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        self.$globalApiClient.workOrder.workOrderUpdateStatus(formData)
        .then((response) => {
          if (response.data.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
            self.closeModal();
            if (self.$route.meta.xyz) {
              Vue.getDatatables('.work_order');
            } else if (self.from === 'WorkOrder') {
              self.reloadDataTable();
            } else if (self.from === 'MyWorkOrder') {
              self.reloadMyWorkOrder();
            }
          } else {
            toastr.error(response.data.Message);
          }
          blockui.unBlockModal();
        }, (error) => {
          blockui.unBlockModal();
        });
      }
    },
    reloadMyWorkOrder() {
      Vue.getMyWorkOrder();
    },
    reloadDataTable() {
      Vue.getDatatables();
    },
    closeModal() {
      $(`#${this.modalId}`).modal('toggle');
    },
  },
  computed: {
    isSubStatusListEmpty() {
      const self = this;
      return jQuery.isEmptyObject(self.subStatusList);
    },
    ...mapState({
      suitableStatusList: (state) => state.suitable_status.suitableStatusList,
      workOrderSourceEnums: (state) => state.enums.workOrderSource,
    }),
  },
};
