












































import { Component, Vue } from 'vue-property-decorator';
import { DataResult, DataStateChangeEventArgs, DetailRow, Sort, Group, Page, Toolbar, Filter, ExcelExport, PdfExport, CommandColumn } from "@syncfusion/ej2-vue-grids";
import { Predicate } from '@syncfusion/ej2-data';
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations';
import CFMGridPage from '@/scripts/CFMGridPage'

import * as swal from '@/helpers/swal'
import * as blockui from '@/helpers/block-ui'

import { CFMModelFormDetailView } from '@/scripts/services/ClientApiAuto'
import FormModal from './FormModal.vue'
import FormCloneDialog from './FormCloneDialog.vue';
import QuestionModal from '@/components/partials/modals/QuestionModal.vue';
import QuestionGroupModal from '@/components/partials/modals/QuestionGroupModal.vue';
@Component<Form>({
    components: {
        'form-modal': FormModal,
        'form-clone-dialog': FormCloneDialog,
        'question-modal': QuestionModal,
        'question-group-modal': QuestionGroupModal,
    },
    provide() {
        return {
            grid: [Sort, Group, Page, Toolbar, Filter, ExcelExport, PdfExport, CommandColumn, DetailRow]
        };
    },
})
export default class Form extends CFMGridPage<CFMModelFormDetailView> {
    // expandProperties = ["ProcedureType/Name","FormStatus/Color/Code"];
    entityType = "Form";
    sorts:{field:string,direction:string}[] = [{ field:"Id", direction:"Descending" }];
    entityState: DataStateChangeEventArgs = {where:[new Predicate('isActive', 'equal', true, false), new Predicate('parentId', 'equal', null, false)]};

    childEntityState: DataStateChangeEventArgs = {where:[new Predicate('isActive', 'equal', true, false)]};

    childGridDataSource: DataResult | {} = {};
    selectedFormCloneId: number | null = null;
    isFormCloneDialogActive = false;


    slcForm= {};
    questionModalProps= {};
    slcQuestionGroup= {};
    showFormModal= false;
    showQuestionModal= false;
    showQuestionGroupModal= false;
    isUpdate= false;
    isQuestionUpdate= false;
    isQuestionGroupUpdate= false;

    created(){
        this.toolbar = [{
          text: 'Excel Export', tooltipText: 'Excel Export', prefixIcon: 'e-excelexport', id: 'ExcelExport'
        }, {
          text: 'CSV Export', tooltipText: 'CSV Export', prefixIcon: 'e-csvexport', id: 'CSVExport'
        }, {
          text: 'PDF Export', tooltipText: 'PDF Export', prefixIcon: 'e-pdfexport', id: 'PDFExport'
        },{
            text: this.translate('add'), tooltipText: this.translate('add'), prefixIcon: 'e-add', id: 'Add',
        }];
    }

    get childGrid (){
        return {
        queryString: "Id",
        columns: [
          { field: "Id", headerText: "Id", width: 120 },
          { field: "Version", headerText: this.translate('version') },
          { field: "RevisionDate", headerText: this.translate('in_use_date'), format: {format: 'dd/MM/yyyy', type: 'datetime'} },
          { field: "FormStatusName", headerText: this.translate('form_status'), width: 150, template: this.statusTemplate },
          { headerText: this.translate('action'), width: 400, template: this.actionTemplate },

        ]
      }
    }
     
    beforeDestroy() {
        this.$eventHub.$off('form-grid-command');
    }

    actionTemplate () {
        return {
            template: Vue.component('actionTemplate', {
                template: `
                <div>
                    <span class="e-toolbar-item" aria-disabled="false" :title="translate('clone')">
                        <button class="e-tbar-btn e-control e-btn e-lib" @click="command('clone', data, $event)" type="button" tabindex="-1" :aria-label="translate('clone')" style="width: auto;">
                            <span class="e-btn-icon e-copy e-icons">
                            </span>
                        </button>
                    </span>
                    <span class="e-toolbar-item" aria-disabled="false" :title="translate('version')">
                        <button class="e-tbar-btn e-control e-btn e-lib" @click="command('version', data, $event)" type="button" tabindex="-1" :aria-label="translate('version')" style="width: auto;">
                            <span class="e-btn-icon e-align-top e-icons">
                            </span>
                        </button>
                    </span>
                    <span v-if="data.IsEditable" class="e-toolbar-item" aria-disabled="false" :title="translate('edit')">
                        <button class="e-tbar-btn e-control e-btn e-lib" @click="command('edit', data, $event)" type="button" tabindex="-1" :aria-label="translate('edit')" style="width: auto;">
                            <span class="e-btn-icon e-edit e-icons">
                            </span>
                        </button>
                    </span>
                    <span v-else class="e-toolbar-item" aria-disabled="false" :title="translate('show')">
                        <button class="e-tbar-btn e-control e-btn e-lib" @click="command('show', data, $event)" type="button" tabindex="-1" :aria-label="translate('show')" style="width: auto;">
                            <span class="e-btn-icon e-preview e-icons">
                            </span>
                        </button>
                    </span>

                </div>
                `,
                data: function () { return { data: {} }; },
                methods: {
                    command(commandType:any, data:any, event:any){
                        this.$eventHub.$emit("form-grid-command", commandType, data, event);
                    }
                }
            })
        }
    }
    async openCloneDialog(id: number) {
        this.selectedFormCloneId = id;
        this.isFormCloneDialogActive = true;
        await this.$nextTick();
        (<FormCloneDialog>this.$refs.formCloneDialog).showDialog();
    }
    async openFormDialog(data: any) {
        this.isUpdate = data && data.Id && data.Id > 0;
        this.slcForm = {};
        this.slcForm = data;
        this.showFormModal = true;
        await this.$nextTick();
        (<FormCloneDialog>this.$refs.formDialog).showDialog();
    }
    async childGridCommand(commandType: string, data: any, event: any){
        // Id: 506
        // ParentId: null
        console.log("bb",commandType, data, event);

        if(commandType == "clone"){
            this.openCloneDialog(data.Id);
        }
        else if(commandType == "version"){
            const e = await swal.check(this, data.Name, this.translate('create_new_version'));
            if (!e.value) {
                return;
            }
            blockui.blockElementPromise(this, this.$el);
            const response = await this.apiClient.form.formUpgradeVersion({ refFormId: data.Id })
            if (response.data.IsSuccess) {
                toastr.success(this.translate('version_upgraded'));
                // TODO reload only new versioned.
                this.refreshGrid();
            } else {
                const errorMessage = response.data.Message ? response.data.Message : "Error";
                toastr.error(errorMessage);
            }
            blockui.unBlockElement(this.$el);
        }
        else{
            this.openFormDialog(data);
        }
    }

    async onDetailDataBound (args: any) {
        const predicate = new Predicate('parentId', 'equal', args.data.Id, false).or('id', 'equal', args.data.Id, false);
        let data = await this.getData({where:[predicate]}, false, this.entityType, this.childEntityState );

        (<DataResult>data).result = (<CFMModelFormDetailView[]>(<DataResult>data).result).map((x) =>({...x, RevisionDate: x.RevisionDate ? new Date(x.RevisionDate) : null}))

        console.log("erodata", data);
        args.childGrid.dataSource = data;
    }

    statusTemplate () {
        return {
            template: Vue.component('statusTemplate', {
                template: `<span class="badge" style="color: #fff;" :style="{backgroundColor: data.FormStatusColorCode}">{{data.FormStatusName}}</span>`,
                data: function () { return { data: {} }; }
            })
        }
    }

    async mounted(){
        this.dataStateChange(this.state);
        this.$eventHub.$on('form-grid-command', this.childGridCommand);
    }

    refreshGrid(){
        this.dataStateChange(this.state);
    }
    
    async add(){
      const slcForm = {
        Version: '1.0.0',
        StatusId: 1,
        StatusName: 'Hazırlanıyor',
        DeviceIds: [],
        CreateById: 1, // 2DO
        CreateBy: localStorage.getItem('userName'),
      };
      this.openFormDialog(slcForm);
    }
    
    async toolbarClick(args: ClickEventArgs){
        var selectedRecords = (<any>this.$refs.grid).getSelectedRecords();
        if(args.item.id === 'Add'){
               this.add();
        }
        this.toolbarClickBase(args);
    }
}
