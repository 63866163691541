import Vue from 'vue';
import { mapState } from 'vuex';
import FirstStep from '../../../components/partials/add-capacity-equipment-steps/FirstStep';
import SecondStep from '../../../components/partials/add-capacity-equipment-steps/SecondStep';
import LocationStep from '../../../components/partials/add-capacity-equipment-steps/LocationStep';
import ReferenceLibraryStep from '../../../components/partials/add-capacity-equipment-steps/ReferenceLibraryStep';
import DescriptionStep from '../../../components/partials/add-capacity-equipment-steps/DescriptionStep';
import ServiceProviderStep from '../../../components/partials/add-capacity-equipment-steps/ServiceProviderStep';
import FinancialStep from '../../../components/partials/add-capacity-equipment-steps/FinancialStep';
import LifetimeStep from '../../../components/partials/add-capacity-equipment-steps/LifetimeStep';
import PMPStep from '../../../components/partials/add-capacity-equipment-steps/PMPStep';
import ContractStep from '../../../components/partials/add-capacity-equipment-steps/ContractStep';
import * as api from '../../services/api';
import * as dateFormat from '../../../helpers/dateFormat';
import * as blockui from '../../../helpers/block-ui';

export default {
  data() {
    return {
      capacityEquipment: {},
      formData: {},
      showReferenceLibraryItemModal: false,
      showServiceProviderModal: false,
      showFinancialModal: false,
      showDescriptionModal: false,
      showPMPModal: false,
      showContract: false,

      modalId: 'capacity_equipment_overlay',
    };
  },
  props: [
    'selectedCapacityEquipment',
    'isUpdate',
    'selectedTable',
  ],
  components: {
    'first-step': FirstStep,
    'second-step': SecondStep,
    'location-step': LocationStep,
    'reference-library-step': ReferenceLibraryStep,
    'description-step': DescriptionStep,
    'service-provider-step': ServiceProviderStep,
    'financial-step': FinancialStep,
    'lifetime-step': LifetimeStep,
    'pmp-step': PMPStep,
    'contract-step': ContractStep,
  },
  computed: {
    ...mapState({
      capacityEquipmentOverlayWorkList: (state) => state.work.capacityEquipmentOverlayWorkList,
    }),
  },
  mounted() {
    const self = this;
    const form = $("form[name='stepForm']");
    form.validate();
    self.capacityEquipment = self.selectedCapacityEquipment;
    self.showReferenceLibraryItemModal = true;
    self.showServiceProviderModal = true;
    self.showFinancialModal = true;
    self.showDescriptionModal = true;
    self.showPMPModal = true;
    self.showContract = true;
    self.$nextTick(() => {
      Vue.justNumber();
    });

    self.$store.commit('setSelectedCapacityEquipmentId', self.selectedCapacityEquipment.Id);
    self.$store.dispatch('getCapacityEquipmentOverlayWorkList');

    const modal = $(`#${self.modalId}`);
    modal.on('shown.bs.collapse', (e) => {
      $($.fn.dataTable.tables(true)).DataTable()
        .columns.adjust()
        .responsive.recalc();
    });

    modal.on('hidden.bs.modal', (e) => {
      self.$emit('close');
    });
    // this.$parent.loadCapacityEquipmentWorks();
  },
  methods: {
    redirectFromModal(e) {
      this.$emit('redirectFromModal', { redirectParams: e, modalId: this.modalId });
    },
    addCapacityEquipmentWizard(e, status) {
      const self = this;
      const form = $("form[name='stepForm']");

      // if (status === 'next') {
      self.$refs['first-step-child'].$v.$touch();
      let isValid = !self.$refs['first-step-child'].$v.$invalid;

      $(form).each(function () {
        // $(this).validate()
        if (!$(this).valid()) {
          isValid = false;
        }
      });
      if (!isValid) {
        toastr.error(self.translate('please_fill_all_required_fields'));
      } else {
        $.map(form.serializeArray(), (item) => {
          if (item.name === 'DateOfInstallation' && item.value) {
            Object.assign(self.formData, { [item.name]: dateFormat.postDateFormat(item.value) });
          } else if (item.name === 'DateOfWarrantyStart' && item.value) {
            Object.assign(self.formData, { [item.name]: dateFormat.postDateFormat(item.value) });
          } else if (item.name === 'ExpectedLifeEndDate' && item.value) {
            Object.assign(self.formData, { [item.name]: dateFormat.postDateFormat(item.value) });
          } else {
            Object.assign(self.formData, { [item.name]: item.value });
          }
        });

        if (this.capacityEquipment === undefined) {
          this.capacityEquipment = {};
        }
        Object.assign(this.capacityEquipment, self.formData);
        // }
        // } else if (status === 'submit') {
        // if (form.valid()) {
        const stepData = {
          InputDevices: [],
          OutputDevices: [],
        };
        blockui.blockModal(self);
        $.map(form.serializeArray(), (item) => {
          if (item.name === 'source') {
            if (item.value) {
              stepData.InputDevices.push({ FromDeviceId: item.value });
            }
          } else if (item.name === 'output') {
            if (item.value) {
              stepData.OutputDevices.push({ DeviceId: item.value });
            }
          }
        });
        Object.assign(this.formData, stepData);
        const formDataWithFormattedDates = this.formData;
        /*   if (formDataWithFormattedDates.DateOfInstallation) {
                     formDataWithFormattedDates.DateOfInstallation = dateFormat.postDateFormat(dateFormat.viewDateFormat(formDataWithFormattedDates.DateOfInstallation));
                   }
                   if (formDataWithFormattedDates.DateOfWarrantyStart) {
                     formDataWithFormattedDates.DateOfWarrantyStart = dateFormat.postDateFormat(dateFormat.viewDateFormat(formDataWithFormattedDates.DateOfWarrantyStart));
                   }
                   if (formDataWithFormattedDates.ExpectedLifeEndDate) {
                     formDataWithFormattedDates.ExpectedLifeEndDate = dateFormat.postDateFormat(dateFormat.viewDateFormat(formDataWithFormattedDates.ExpectedLifeEndDate));
                   } */

        blockui.unBlockModal();
        api.saveCapacityEquipment(formDataWithFormattedDates).then((response) => {
          if (response.body.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
            //
            Vue.getDatatables('.capacity_equipment_list');
            //
            $(`#${self.modalId}`).modal('toggle');
          } else {
            toastr.error(response.body.Message);
          }
          blockui.unBlockModal();
        }, (error) => {
          blockui.unBlockModal();
        });
      }
      // }
    },
  },
  /* watch: {
          step: function (e) {
              const form = $("form[name='stepForm']");
              form.validate();
          },
      } */
};
