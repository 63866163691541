import grid from '../../../scripts/global/gridOld';
import * as api from '../../../scripts/services/api';
import * as blockui from '../../../helpers/block-ui';
import PreventiveMaintenanceModal from './PreventiveMaintenanceModal/PreventiveMaintenanceModal.vue';
import * as swal from '../../../helpers/swal';
import WorkModal from '../../partials/modals/WorkModal';
import ProcedureModal from '../../partials/modals/ProcedureModal';
import * as dateFormat from '../../../helpers/dateFormat';

export default {
  props: ['contractId', 'sectionId', 'IsEditable'],
  data() {
    // Rows to display in grid
    return {
      Id: null,
      Name: null,
      isUpdate: false,
      IsGridReady: false,
      IsDisplayTree: true,
      showWorkModal: false,
      showProcedureModal: false,
      showPreventiveMaintenanceModal: false,
      selectedContractId: null,
      selectedSectionId: null,
      gridEngine: undefined,
      contractDevices: {},
      capacityEquipmentList: [],
      selectedWork: {},
      procedure: {},
      deviceId: null,
      preventiveMaintenance: {},
      widgetHelper: bryntum.scheduler.WidgetHelper,
      MaintenancePlan: [],
      isReady: false,
      rows: [],
      columns: [
        {
          text: '#',
          width: 30,
          type: 'rownumber',
          locked: this.IsEditable,
        },
        {
          groupable: false,
          field: 'Id',
          text: 'Id',
          flex: 1,
          editor: false,
          locked: this.IsEditable,
          renderer: ({ value }) => (Number.isInteger(value) ? `${value}` : ''),
        },
        {
          groupable: false,
          field: 'Name',
          text: 'Plan Name',
          flex: 1,
          editor: false,
          locked: this.IsEditable,
        },

        {
          groupable: false,
          text: 'Capacity Equipment',
          field: 'DeviceName',
          flex: 1,
          hidden: true,
          locked: this.IsEditable,
          tooltipRenderer: false,
        },
        {
          groupable: false,
          text: 'Work',
          field: 'WorkName',
          locked: this.IsEditable,
          flex: 1,
        },
        {
          groupable: false,
          text: 'Period',
          field: 'PeriodName',
          locked: this.IsEditable,
          flex: 1,

        },
        {
          groupable: false,
          text: 'Reference Type',
          field: 'ReferenceTypeId',
          flex: 1,
          editor: false,
          locked: this.IsEditable,
          tooltipRenderer: false,
          renderer: ({ value }) => (value === 1 ? this.translate('last_maintenance') : value === 2 ? this.translate('next_maintenance') : ''),
        },
        {
          groupable: false,
          field: 'ReferenceDate',
          text: 'Reference Date',
          type: 'date',
          format: 'LL',
          flex: 1,
          locked: this.IsEditable,
          tooltipRenderer: false,
        },
        {
          groupable: false,
          field: 'Duration',
          text: 'Duration (min)',
          flex: 1,
          locked: this.IsEditable,
          tooltipRenderer: false,
        },
        {
          groupable: false,
          field: 'TotalCount',
          text: 'Total Count',
          type: 'number',
          min: 0,
          flex: 1,
          locked: this.IsEditable,
          tooltipRenderer: false,
        },
        {
          groupable: false,
          field: 'RemainedCount',
          text: 'Remained Count',
          type: 'number',
          min: 0,
          flex: 1,
          locked: this.IsEditable,
        },
        {
          groupable: false,
          field: 'Tolerance',
          text: 'Tolerance(Days)',
          type: 'number',
          min: 0,
          flex: 1,
          locked: this.IsEditable,
        },
        {
          groupable: false,
          field: 'PlanEdit',
          type: 'widget',
          text: 'Edit',
          flex: 1,
          locked: false,
          editor: false,
          hidden: !this.IsEditable,
          widgets: [{
            type: 'button',
            icon: 'b-fa b-fa-edit',
            cls: 'b-blue',
            style: 'width:100%',
            onAction: ({ source }) => {
              const self = this;
              blockui.blockElementPromise(self, self.$el);
              const selected = source.cellInfo.record;
              self.preventiveMaintenance = {};

              api.getContractMaintenancePlan(selected.Id).then((res) => {
                self.preventiveMaintenance = res.body;
                self.preventiveMaintenance.ReferenceDate = dateFormat.postDateTimeFormatFromDateObject(self.preventiveMaintenance.ReferenceDate);
                self.showPreventiveMaintenanceModal = true;
                self.isUpdate = true;
                self.$nextTick(() => {
                  const modalContainer = window.$('#preventive_maintenance_modal');
                  modalContainer.modal({
                    backdrop: 'static',
                    show: true,
                  });
                  modalContainer.find('form').data('type', 'update');
                  blockui.unBlockElement(self.$el);
                });
              });
            },
          }],
        },
        {
          type: 'widget',
          groupable: false,
          field: 'delete',
          text: 'Delete',
          flex: 1,
          locked: false,
          hidden: !this.IsEditable,
          widgets: [{
            type: 'button',
            icon: 'b-fa b-fa-trash',
            cls: 'b-red',
            style: 'width:100%',
            onAction: ({ source }) => {
              const selected = source.cellInfo.record;
              const self = this;
              blockui.pageBlock(self);
              swal.check(self, source.cellInfo.record.data.Name).then((e) => {
                if (e.value) {
                  api.deleteContractMaintenancePlan(source.cellInfo.record.Id).then((response) => {
                    if (response.body.IsSuccess) {
                      self.$refs.grid.gridEngine.store.remove(source.cellInfo.record.data.id);
                      toastr.success(self.translate('item_deleted_successfully').formatString(source.cellInfo.record.Name));
                    } else {
                      toastr.error(response.body.Message);
                    }
                    blockui.unBlockPage();
                  });
                } else {
                  blockui.unBlockPage();
                }
              });
            },
          }],
        },
      ],
      group: 'DeviceName',

    };
  },
  components: {
    grid,
    'preventive-maintenance-modal': PreventiveMaintenanceModal,
    'work-modal': WorkModal,
    'procedure-modal': ProcedureModal,
  },
  async created() {
    await this.getCapacityEquipmentsByContractId(this.contractId);
  },
  mounted() {
    this.selectedContractId = this.contractId;
    this.selectedSectionId = this.sectionId;
    this.getResources();
    this.$eventHub.$on('updatedContract', async (data) => {
      await this.getCapacityEquipmentsByContractId(data);
    });
  },
  methods: {
    initGrid() {
      const self = this;
      self.gridEngine = self.$refs.grid.gridEngine;
      const addNewCapEquipButton = [{
        type: 'button',
        cls: 'b-raised b-blue',
        margin: 10,
        // text: this.translate('add_preventive_maintenance'),
        icon: 'b-fa b-fa-plus',
        tooltip: this.translate('add_preventive_maintenance'),
        onAction: () => {
          const self = this;
          $('#collapsePreventiveMaintenance').collapse('show');
          self.preventiveMaintenance = {
            SectionId: self.selectedSectionId,
            ContractId: self.selectedContractId,
            Tolerance: 0,
          };
          self.isUpdate = false;
          self.showPreventiveMaintenanceModal = true;
          blockui.blockElementPromise(self, self.$el);
          self.$nextTick(() => {
            const modalContainer = window.$('#preventive_maintenance_modal');

            modalContainer.find('form').data('type', '');
            modalContainer.modal({
              backdrop: 'static',
              show: true,
            });
            blockui.unBlockElement(self.$el);
          });
        },
      }];

      self.widgetHelper.append({
        type: 'container',
        widgets: addNewCapEquipButton,
        cls: `pull-left${self.IsEditable ? '' : ' d-none'}`,
      }, { insertFirst: document.querySelector('.pm-grid ') });
    },
    async getResources() {
      const self = this;
      self.rows = [];
      // blockui.blockElementPromise(self, ".pm-grid");
      blockui.pageBlock(self);
      await api.getContractMaintenancePlanList(
        {
          ContractId: self.selectedContractId,
          SectionId: self.selectedSectionId,
        },
      ).then((response) => {
        response.body.forEach((Item) => {
          const row = {
            Id: Item.Id,
            Name: Item.Name,
            DeviceName: Item.DeviceName,
            WorkName: Item.WorkName,
            PeriodName: Item.PeriodName,
            ReferenceTypeId: Item.ReferenceTypeId,
            ReferenceDate: Item.ReferenceDate,
            Duration: Item.Duration,
            TotalCount: Item.TotalCount,
            RemainedCount: Item.RemainedCount,
            Tolerance: Item.Tolerance,
          };
          self.rows.push(row);
        });
        self.isReady = true;
        // blockui.unBlockElement(".pm-grid");
        blockui.unBlockPage();
        self.$nextTick(() => {
          const { store } = self.$refs.grid.gridEngine;
          store.sort({ field: 'Id', ascending: true });
          store.load && store.load();
        });
      }, (error) => {
        blockui.unBlockPage();
      });
    },
    openProcedureModal() {
      const self = this;
      self.showProcedureModal = true;
      self.$nextTick(() => {
        self.procedure = {};
        const modalContainer = window.$('#procedure_modal');
        modalContainer.find('form').data('type', '');
        self.isUpdate = false;
        modalContainer.modal({
          backdrop: 'static',
          show: true,
        });
      });
    },
    async getCapacityEquipmentsByContractId(contractId) {
      blockui.pageBlock(this);
      await api.getDeviceListByContractId(contractId).then((response) => {
        this.capacityEquipmentList = response.body;
      });
      blockui.unBlockPage();
    },
    openWorkModal(deviceId) {
      const self = this;
      self.deviceId = deviceId;
      self.selectedWork = {
        DeviceId: deviceId,
      };
      self.isUpdate = false;
      self.showWorkModal = true;
      self.$nextTick(() => {
        const modalContainer = window.$('#work_modal');
        modalContainer.find('form').data('type', '');
        modalContainer.modal({
          backdrop: 'static',
          show: true,
        });
      });
    },
    reloadDatatable() {
      this.getResources();
    },
  },
  watch: {
    contractId(e) {
      this.selectedContractId = e;
    },
    sectionId(e) {
      this.selectedSectionId = e;
    },
  },
  beforeDestroy() {
    this.$eventHub.$off('updateContract');
  },
};
