<template>
    <div>
        <!--TODO activate for chart close and open-->
        <!--<chart-selector :currentList="list" :defaultChartList="defaultCharts"></chart-selector>-->
        <div :id="gridId" class="grid-stack">
            <grid-item v-for="(item, i) in list" v-if="item.isActive" :item="item" :i="i" :key="item.id"
                       :height="options.cellHeight"
                       @filterParametersUpdated="filterParametersUpdated(i,$event)"></grid-item>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import GridItem from './GridItem';
import ChartSelector from './ChartSelector';

export default {
  name: 'Grid',
  props: ['gridOptions'],
  components: {
    'grid-item': GridItem,
    'chart-selector': ChartSelector,
  },
  data() {
    return {
      list: [],
      options: {
        cellHeight: 120,
        verticalMargin: 10,
        width: 12,
        draggable: {
          handle: '.grid-stack-handle',
        },
        resizable: {
          handles: 'e, se, s, sw, w',
        },
      },
      grid: null,
      dashboardListOrderVersion: 27,
      defaultCharts: [{
        id: 1,
        x: 0,
        y: 0,
        w: 12,
        h: 3,
        minH: 3,
        maxH: 3,
        minW: 3,
        comp: 'work-order-count-calendar-chart',
        chartName: 'work-order-count-calendar-chart',
        isActive: true,
      }, {
        id: 2,
        x: 0,
        y: 3,
        w: 4,
        h: 3,
        minH: 2,
        minW: 3,
        comp: 'work-order-count-by-status-chart',
        chartName: 'work-order-count-by-status-chart',
        filterType: 1,
        filterParameters: {
          startDate: moment().startOf('week'),
          endDate: moment().endOf('week'),
          presetId: null,
        },
        isActive: true,
      }, {
        id: 3,
        x: 4,
        y: 3,
        w: 4,
        h: 3,
        minH: 2,
        minW: 2,
        comp: 'shift-staff-count-by-day-chart',
        chartName: 'shift-staff-count-by-day-chart',
        filterType: 3,
        filterParameters: {
          startDate: moment().startOf('week'),
          endDate: moment().endOf('week'),
          presetId: null,
        },
        isActive: true,
      }, {
        id: 4,
        x: 8,
        y: 3,
        w: 2,
        h: 3,
        minH: 2,
        comp: 'users-need-to-be-get-training-chart',
        chartName: 'users-need-to-be-get-training-chart',
        filterType: 2,
        filterParameters: { value: 5 },
        isActive: true,
      }, {
        id: 5,
        x: 10,
        y: 3,
        w: 2,
        h: 3,
        minH: 2,
        comp: 'number-of-trainings-to-be-taken',
        chartName: 'number-of-trainings-to-be-taken',
        filterType: 2,
        filterParameters: { value: 5 },
        isActive: true,
      }, {
        id: 17,
        x: 0,
        y: 6,
        w: 4,
        h: 3,
        minH: 3,
        minW: 4,
        maxH: 3,
        comp: 'users-who-need-the-most-training-chart',
        chartName: 'users-who-need-the-most-training-chart',
        isActive: true,
      }, {
        id: 18,
        x: 4,
        y: 6,
        w: 4,
        h: 3,
        minH: 3,
        minW: 4,
        maxH: 3,
        comp: 'most-needed-trainings',
        chartName: 'most-needed-trainings',
        isActive: true,
      }, {
        id: 23,
        x: 8,
        y: 6,
        w: 4,
        h: 3,
        minH: 2,
        minW: 3,
        maxH: 3,
        comp: 'number-of-total-capacity-equipment-chart',
        chartName: 'number-of-total-capacity-equipment-chart',
        isActive: true,
      }, {
        id: 20,
        x: 0,
        y: 9,
        w: 4,
        h: 3,
        minH: 3,
        minW: 4,
        maxH: 3,
        comp: 'most-invalid-capacity-equipments',
        chartName: 'most-invalid-capacity-equipments',
        isActive: true,
      }, {
        id: 21,
        x: 4,
        y: 9,
        w: 4,
        h: 3,
        minH: 3,
        minW: 4,
        maxH: 3,
        comp: 'most-repetitive-problem-inventory-and-point',
        chartName: 'most-repetitive-problem-inventory-and-point',
        isActive: true,
      }, {
        id: 19,
        x: 8,
        y: 9,
        w: 4,
        h: 3,
        minH: 3,
        minW: 4,
        maxH: 3,
        comp: 'most-invalid-rounds',
        chartName: 'most-invalid-rounds',
        isActive: true,
      }, {
        id: 24,
        x: 0,
        y: 12,
        w: 4,
        h: 3,
        minH: 2,
        minW: 3,
        maxH: 3,
        comp: 'staff-who-made-most-round',
        chartName: 'staff-who-made-most-round',
        isActive: true,
      }],
    };
  },
  computed: {
    gridId() {
      return `grid-stack-${this._uid}`;
    },
  },
  methods: {
    escKeyFullScreen(e) {
      if (e.keyCode === 27) {
        this.$eventHub.$emit('close-full-screen', {});
      }
    },
    filterParametersUpdated(index, parameters) {
      const self = this;
      let list = self.list.slice();
      list = list.map((item, i) => {
        if (i === index) {
          return Object.assign(item, { filterParameters: parameters });
        }
        return item;
      });
      localStorage.setItem('dashboard-list', JSON.stringify(list));
    },
    calculateIndexes(newItems) {
      const self = this;
      const items = newItems.map((newItem) => ({
        id: newItem.id,
        x: newItem.x,
        y: newItem.y,
        w: newItem.width,
        h: newItem.height,
      }));

      for (let i = 0; self.list[i]; ++i) {
        const listItem = self.list[i];
        const temp = items.find((item) => listItem.id === item.id);
        if (temp) {
          // update
          /* Vue.set(listItem, 'x', temp.x);
                        Vue.set(listItem, 'y', temp.y);
                        Vue.set(listItem, 'w', temp.w);
                        Vue.set(listItem, 'h', temp.h); */
        }
      }

      const sortItems = self.list.filter((x) => x.isActive).sort((a, b) => {
        if (a.y - b.y > 0) {
          return 1;
        } if ((a.y - b.y <= 0) && a.x - b.x > 0) {
          return 1;
        }
        return 0;
      });
      const data = {};
      sortItems.forEach((item, index) => {
        data[item.id] = index;
      });
      this.$eventHub.$emit('re-indexed-items', data);
      self.setLocalStorage();
    },
    setLocalStorage() {
      const self = this;
      localStorage.removeItem('dashboard-list');
      localStorage.setItem('dashboard-list', JSON.stringify(self.list));
    },
    emitItemsMoved(e, items) {
      const self = this;
      if (items) {
        items.forEach((item) => {
          self.$eventHub.$emit('item-moved', { id: item.id, item });
        });
        this.calculateIndexes(items);
      }
    },
    itemRemoved(e, items) {
      const self = this;
      items.forEach((item) => {
        const index = self.list.findIndex((listItem) => listItem.id == item.id);
        if (index >= 0) {
          // self.list.splice(index, 1);
        }
      });
    },
    closeChart(id) {
      const self = this;
      const item = self.list.find((x) => x.id === id);
      if (item) {
        $(`#${self.gridId}`).data('gridstack').removeWidget($(`#grid-stack-item-${item.chartName}`), true);
        Vue.set(self.list[self.list.findIndex((x) => x.id === id)], 'isActive', false);
        self.setLocalStorage();
        /*
                    self.list.splice(itemIndex, 1);
                    console.log(self.list);
                    self.$nextTick(() => {
                        localStorage.setItem('dashboard-list', JSON.stringify(self.list));
                    }); */
      }

      /* let index = self.list.findIndex(x => x.id == id);
                if (index) {
                    self.list.splice(index, 1);
                    $('#' + self.gridId).gridstack(self.options);
                } */
    },
    openChart(id) {
      const self = this;
      const item = self.list.find((x) => x.id === id);
      if (item) {
        // $('#' + self.gridId).data('gridstack').removeWidget($('#grid-stack-item-' + item.chartName), true);
        Vue.set(self.list[self.list.findIndex((x) => x.id === id)], 'isActive', true);
        self.setLocalStorage();
        self.$nextTick(() => {
          $(`#${self.gridId}`).data('gridstack').makeWidget($(`#grid-stack-item-${item.chartName}`));
        });
        /*
                    self.list.splice(itemIndex, 1);
                    self.$nextTick(() => {
                        localStorage.setItem('dashboard-list', JSON.stringify(self.list));
                    }); */
      }
    },
  },
  mounted() {
    const self = this;
    const dl = localStorage.getItem('dashboard-list');
    const dv = localStorage.getItem('dashboard-version');
    if (parseInt(dv) >= self.dashboardListOrderVersion && dl) {
      self.list = JSON.parse(dl);
    } else {
      self.list = self.defaultCharts.slice();
      localStorage.setItem('dashboard-version', self.dashboardListOrderVersion);
      localStorage.setItem('dashboard-list', JSON.stringify(self.list));
    }
    self.$nextTick(() => {
      self.options = { ...self.options, ...self.gridOptions };
      self.grid = $(`#${self.gridId}`).gridstack(self.options);
      self.grid.on('change', self.emitItemsMoved);
      self.grid.on('removed', self.itemRemoved);
    });
    document.addEventListener('keyup', self.escKeyFullScreen);
    self.$eventHub.$on('close-chart', self.closeChart);
    self.$eventHub.$on('open-chart', self.openChart);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.escKeyFullScreen);
    this.$eventHub.$off('close-chart');
    this.$eventHub.$off('open-chart');
  },
};
</script>

<style lang="scss">
    /*.chart-inner{
      width: inherit !important;
      height: inherit !important;
    }*/

    .grid-stack-item[data-gs-width="1"] {
        width: 8.333333%;
    }

    .grid-stack-item[data-gs-width="2"] {
        width: 16.666667%;
    }

    .grid-stack-item[data-gs-width="3"] {
        width: 25%;
    }

    .grid-stack-item[data-gs-width="4"] {
        width: 33.333333%;
    }

    .grid-stack-item[data-gs-width="5"] {
        width: 41.666667%;
    }

    .grid-stack-item[data-gs-width="6"] {
        width: 50%;
    }

    .grid-stack-item[data-gs-width="7"] {
        width: 58.333333%;
    }

    .grid-stack-item[data-gs-width="8"] {
        width: 66.666667%;
    }

    .grid-stack-item[data-gs-width="9"] {
        width: 75%;
    }

    .grid-stack-item[data-gs-width="10"] {
        width: 83.333333%;
    }

    .grid-stack-item[data-gs-width="11"] {
        width: 91.666667%;
    }

    .grid-stack-item[data-gs-width="12"] {
        width: 100%;
    }

    .grid-stack-item[data-gs-x=""] {
        left: 0;
    }

    .grid-stack-item[data-gs-x="1"] {
        left: 8.333333%;
    }

    .grid-stack-item[data-gs-x="2"] {
        left: 16.666667%;
    }

    .grid-stack-item[data-gs-x="3"] {
        left: 25%;
    }

    .grid-stack-item[data-gs-x="4"] {
        left: 33.333333%;
    }

    .grid-stack-item[data-gs-x="5"] {
        left: 41.666667%;
    }

    .grid-stack-item[data-gs-x="6"] {
        left: 50%;
    }

    .grid-stack-item[data-gs-x="7"] {
        left: 58.333333%;
    }

    .grid-stack-item[data-gs-x="8"] {
        left: 66.666667%;
    }

    .grid-stack-item[data-gs-x="9"] {
        left: 75%;
    }

    .grid-stack-item[data-gs-x="10"] {
        left: 83.333333%;
    }

    .grid-stack-item[data-gs-x="11"] {
        left: 91.666667%;
    }

    @media only screen and (max-width: 768px) {
        .grid-stack-item[data-gs-width="1"] {
            width: 100%;
        }

        .grid-stack-item[data-gs-width="2"] {
            width: 100%;
        }

        .grid-stack-item[data-gs-width="3"] {
            width: 100%;
        }

        .grid-stack-item[data-gs-width="4"] {
            width: 100%;
        }

        .grid-stack-item[data-gs-width="5"] {
            width: 100%;
        }

        .grid-stack-item[data-gs-width="6"] {
            width: 100%;
        }

        .grid-stack-item[data-gs-width="7"] {
            width: 100%;
        }

        .grid-stack-item[data-gs-width="8"] {
            width: 100%;
        }

        .grid-stack-item[data-gs-width="9"] {
            width: 100%;
        }

        .grid-stack-item[data-gs-width="10"] {
            width: 100%;
        }

        .grid-stack-item[data-gs-width="11"] {
            width: 100%;
        }

        .grid-stack-item[data-gs-width="12"] {
            width: 100%;
        }

        .grid-stack-item[data-gs-x="0"] {
            left: 0;
        }

        .grid-stack-item[data-gs-x="1"] {
            left: 0;
        }

        .grid-stack-item[data-gs-x="2"] {
            left: 0;
        }

        .grid-stack-item[data-gs-x="3"] {
            left: 0;
        }

        .grid-stack-item[data-gs-x="4"] {
            left: 0;
        }

        .grid-stack-item[data-gs-x="5"] {
            left: 0;
        }

        .grid-stack-item[data-gs-x="6"] {
            left: 0;
        }

        .grid-stack-item[data-gs-x="7"] {
            left: 0;
        }

        .grid-stack-item[data-gs-x="8"] {
            left: 0;
        }

        .grid-stack-item[data-gs-x="9"] {
            left: 0;
        }

        .grid-stack-item[data-gs-x="10"] {
            left: 0;
        }

        .grid-stack-item[data-gs-x="11"] {
            left: 0;
        }
    }

    .helper-bar {
        position: relative;
        top: 0;
        width: 100%;
        display: flex;
        height: 40px;
        justify-content: space-between;
        align-items: center;
    }

    .helper-bar .items {
        // margin-right: 20px;
        // float: right;
        margin-left: auto;
        display: inline-flex;
        justify-content: space-around;
        height: 40px;
        max-height: 1000px;
        transition: all 0.5s;
        overflow: visible;
        background: none;
        // position: absolute;
        // right: 0;
        // top: 0;
        opacity: 0;
    }

    .helper-bar .helper-header {
        // height: 40px;
        // float: left;
        padding: 0 15px;
        font-size: 1.1rem;
        font-weight: 500;
    }

    .grid-stack-item:hover .helper-bar .items {
        max-height: 1000px;
        color: #949292;
        opacity: 1;
    }

    .grid-stack-item.ui-resizable-resizing {
        opacity: 0;
    }

    .grid-stack-item {
        //overflow: hidden;
        background: #ffffff;
        //margin: 0 10px 0 10px;
        border-width: 0 5px 0 5px;
        /*border-top: 0;
        border-bottom: 0;
        border-right: 5px;
        border-left: 5px;*/
        border-style: solid;
        border-color: #d9dfe2;

        > .relative {
            overflow: visible;
            //background: #ffffff;
            border-width: 0;
            //box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
            height: 100%;
        }
    }

    .grid-stack-item .relative {
    }

    .grid-stack > .grid-stack-item > .ui-resizable-se {
        right: 1px;
        opacity: 0.75;
    }

    .grid-stack > .grid-stack-item > .ui-resizable-sw {
        left: 1px;
        opacity: 0.75;
    }

    .grid-stack > .grid-stack-item > .ui-resizable-e {
        right: 0;
    }

    .grid-stack > .grid-stack-item > .ui-resizable-w {
        left: 0;
    }

    .sq {
        // margin: 2px;
        background: none;
        padding: 20px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sq:hover {
        cursor: pointer;
    }

    .grid-stack .full-screen {
        background-color: #000;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
    }

    .grid-stack .full-screen .chart-outer {
        background-color: #fff;
        margin: 50px;
    }

    .chart-counter {
        text-align: center;
        //height: 100%;

        .grid-stack-item-content {
            //height: 100%;
        }

        .helper-bar {
            //position: absolute;

            .items {
                width: 100%;
                justify-content: space-between;
            }
        }

        .chart-outer {
            //height: 100%;
        }

        .chart-inner {
            //height: 100% !important;
        }

        .chart-inner-content {
            cursor: pointer;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .chart-counter-count {
            padding: 0;
            font-size: 2em;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            height: 50%;
            width: 100%;
            // border-bottom: 1px solid #cfd8dc;
            line-height: 1;

            > div {
                padding: 20px 20px 17px 20px;
                margin: 20px 20px 17px 20px;
                display: inline-block;
            }
        }

        .chart-counter-text {
            padding: 1rem;
            font-size: 1.1rem;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            height: 50%;
            width: 100%;
        }
    }
</style>
