









































































































































































































































































































import CFMDialog from '@/scripts/CFMDialog'
import {Component, Vue} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'
import {required, requiredIf} from "vuelidate/lib/validators";

import { CFMDTOWork, CFMDTOWorkGroup, CFMDTOWorkCategory, CFMModelAvailableFormView, CFMDTOWorkView, CFMModelWorkFormView, CFMDTOWorkTraining, CFMDTOForm, CFMDTOWorkForm, CFMDTOWorkFormView } from '@/scripts/services/ClientApiAuto'
import BootstrapSwitchComponent from "@/components/shared/BootstrapSwitchComponent";
import minutepicker from '../shared/minutepicker.vue';
import { mixinz } from '@/scripts/global/mixinz';

import {
  State,
  Getter,
  Action,
  Mutation,
  namespace
} from 'vuex-class'

const enumsModule = namespace('enums')

@Component<WorkDialog>({
    mixins: [mixinz],
    components: {
        'cfm-multiselect': CFMMultiselect,
        'bootstrap-switch-component': BootstrapSwitchComponent,
        'minutepicker': minutepicker
    },
    validations() {
        return {
          work:{
            Name: {required},
            CategoryId: {required},
            WorkGroupId: {required},
            Duration: {required},
            PeriodId: {
              // @ts-ignore
              required: requiredIf(() => this.isShowPeriods),
            },
            DeviceId: {
              // @ts-ignore
              required: requiredIf(() => !this.isRoundSelected && this.isDeviceBased),
            },
            RoomId: {
              // @ts-ignore
              required: requiredIf(() => !this.isRoundSelected && !this.isDeviceBased),
            },
          },
        }
    },
})
export default class WorkDialog extends CFMDialog {

    @enumsModule.State('workCategory')
    workCategory!: { [key: string]: number; };
    @enumsModule.State('userPosition') 
    userPosition! : {[key: string]: number};

    drag: boolean = false;
    isDeviceBased: boolean = true;

    work: CFMDTOWorkView = {Name: '', IsShowOnCalendar: true, WorkTrainings:[], Duration: 0,  WorkForms: []};
    workCategoryList : {Name:string, Id:number}[] = [];
    workGroupList : {Name:string, Id:number}[] = [];
    capacityEquipmentList : {Name:string, Id:number}[] = [];
    formList : CFMModelAvailableFormView[] = [];
    trainingList: {Name:string, Id:number}[] = [];
    procedureList: {Name:string, Id:number}[] = [];
    periodList: {Name:string, Id:number}[] = [];
    roomList: {Name:string, Id:number}[] = [];
    
    selectedDefermentCondition= false;
    
    multiSelectProps = {
        "close-on-select": false,
        "clear-on-select": false,
        "preserve-search": true,
    }

    get userPositions(){
      return Object.keys(this.userPosition).map(x=>({Id: this.userPosition[x], Name: x}));
    }

    get selectOptions() {
        return {
            searchable: true,
            placeholder: this.translate('please_select'),
            "select-label": this.translate('select_label'),
            "deselect-label": this.translate('deselect_label'),
            "selected-label": this.translate('selected_label'),
            "show-no-results": false,
        }
    }

    async created(){
      blockui.blockElement(this, this.$el);
      if(this.isUpdate){
        const res = await this.apiClient.work.workGetWorkForDialog({id: <number>this.id});
        this.work = res.data;
        this.selectedDefermentCondition = !!this.work.DefermentCondition;
        this.isDeviceBased = !!this.work.DeviceId;
      }
      const promises = [
      this.getCategoryList(),
      this.getWorkGroupList(),
      this.getPeriods(),
      this.getProcedures(),
      this.getTrainingList(),
      this.getCapacityEquipmentList(),
      this.getAvailableFormList(this.work.Id),
      ];
      await Promise.all(promises);
      blockui.unBlockElement(this.$el);
    }

    get isShowPeriods() : boolean {
        return this.isDeviceBased && (this.work.CategoryId == this.workCategory.Maintenance);
    }

    get isRoundSelected() : boolean {
        return this.work.CategoryId == this.workCategory.Round;
    }

    async getCapacityEquipmentList(){
      const response = await this.apiClient.capacityEquipment.capacityEquipmentGetCapacityEquipmentsBreadCrumb();
      this.capacityEquipmentList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getCategoryList(){
      const response = await this.apiClient.workCategory.workCategoryGetWorkCategories();
      this.workCategoryList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getWorkGroupList(){
      const response = await this.apiClient.workGroup.workGroupGetWorkGroups();
      this.workGroupList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getPeriods(){
      const response = await this.apiClient.workPeriod.workPeriodGetPeriods();
      this.periodList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getProcedures(){
      const response = await this.apiClient.procedure.procedureGetProcedures();
      this.procedureList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getTrainingList(){
      const response = await this.apiClient.training.trainingGetTrainings();
      this.trainingList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getAvailableFormList(id: number|undefined){
      const response = await this.apiClient.form.formGetAvailableFormList({workId: id});
      this.formList = response.data;
    }
     
    getWorkTrainingsByUserPosition(userPositionId: number){
      return this.work.WorkTrainings ? this.work.WorkTrainings.filter(x=>x.UserPositionId == userPositionId) : [];
    }
    setWorkTrainingsByUserPosition(newVal: CFMDTOWorkTraining[], userPositionId: number){
       if (newVal) {
         let workTrainings:CFMDTOWorkTraining[]= [];
         if(this.work.WorkTrainings){
            workTrainings = this.work.WorkTrainings.filter(x=>x.UserPositionId != userPositionId);
         }
         workTrainings.push(
           ...newVal.map(x => ({
              ...x,
              UserPositionId: userPositionId,
            }))
          );
          this.work.WorkTrainings = workTrainings;
        } else {
            this.work.WorkTrainings = [];
        }
    }

    get getWorkForms(){
      return this.work.WorkForms;
    }

    addProcedure(){
      this.$emit('addProcedure');
    }

    get selectedWorkForm() {
      if (!this.work.WorkForms) {
          return null;
      } else {
          var a = this.work.WorkForms.map(x => {
              let selectedObj = this.formList.find(y => x.BaseFormId === y.BaseFormId);
              if (selectedObj) {
                  return selectedObj;
              } else {
                  return {};
              }
          });
          return a;
      }
    }

    removeForm(form: CFMDTOWorkFormView) {
      if(!this.work.WorkForms) {return;}
        let formIndex = this.work.WorkForms.findIndex(workForm => workForm.BaseFormId == form.BaseFormId);
        this.work.WorkForms.splice(formIndex, 1);
        let index = 0;
        for (index = formIndex; index < this.work.WorkForms.length; index++) {
            this.work.WorkForms[index].Order = this.work.WorkForms[index].Order ? <number>this.work.WorkForms[index].Order - 1 : 0;
        }
    }
    selectForm(form: CFMDTOWorkFormView) {
        if (!this.work.WorkForms) {
            Vue.set(this.work, 'WorkForms', []);
        }
        let maxOrder = (<CFMDTOWorkFormView[]>this.work.WorkForms).reduce((max, current) => {
            return ((current.Order && max <= current.Order) ? current.Order : max);
        }, 0);
        (<CFMDTOWorkFormView[]>this.work.WorkForms).push({
            ...form,
            Order: maxOrder + 1,
        });
    }

    updateOrder(){
      if (this.work.WorkForms) {
        this.work.WorkForms.forEach(function(workForm, index){
          workForm.Order = index + 1
        });
      }
    }

    selectWorkTraining(workTraining:any, userPositionId:number){
      if (this.work.WorkTrainings) {
          this.work.WorkTrainings.push({
          UserPositionId: userPositionId,
          TrainingId: workTraining.Value
        });
      }
    }
    removeWorkTraining(removedWorkTraining:{Name:string, Id:number}, userPositionId:number){
      if (this.work.WorkTrainings) {
        let formIndex = this.work.WorkTrainings.findIndex(x => x.UserPositionId == userPositionId && x.TrainingId == removedWorkTraining.Id);
        if (formIndex) {
          this.work.WorkTrainings.splice(formIndex, 1);
        }
      }
    }

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log(this.$v);
          return;
      }
      let formData: CFMDTOWork = {
        Id: this.work.Id,
        Name: this.work.Name ? this.work.Name : "",
        CategoryId: this.work.CategoryId,
        WorkGroupId: this.work.WorkGroupId,
        Duration: this.work.Duration ? this.work.Duration : 0,
        IsShowOnCalendar: this.work.IsShowOnCalendar,
        Description: this.work.Description,
        WorkProcedures: this.work.WorkProcedures ? this.work.WorkProcedures.map(x=>({Id:x.Id, ProcedureId:x.ProcedureId})) : [],
        WorkTrainings: this.work.WorkTrainings ? this.work.WorkTrainings.map(x=>({Id:x.Id, UserPositionId:x.UserPositionId, TrainingId:x.TrainingId})) : []
      };

      formData.WorkForms = this.work.WorkForms ? this.work.WorkForms.map(x=>({Id:0, Order:x.Order, FormId: x.BaseFormId})) : [];

      if(this.isShowPeriods){
        formData.PeriodId = this.work.PeriodId;
      }
      
      if(!this.isRoundSelected){
        if(this.isDeviceBased){
          formData.DeviceId = this.work.DeviceId;
        }
        else{
          formData.RoomId = this.work.RoomId;
        }
      }

      if (!!this.selectedDefermentCondition) {
        formData.DefermentCondition = this.work.DefermentCondition;
      }
      
      blockui.blockElement(this, this.$el);
      try{
        const response  = await this.apiClient.work.workSave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        toastr.error(e);
      }
      finally{
        blockui.unBlockElement(this.$el);
      }
    }
}
