




























import { Component } from 'vue-property-decorator';
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations';
import CFMGridPage from '@/scripts/CFMGridPage'

import * as swal from '@/helpers/swal'
import * as blockui from '@/helpers/block-ui'

import { CFMModelWorkOrderView, CFMDTOMaintenancePlan } from '@/scripts/services/ClientApiAuto'
import CFMMultiselect from '../partials/CFMMultiselect.vue';

import { mixinz } from '@/scripts/global/mixinz';
import moment from 'moment';


@Component<PendingMaintenancePlansForAssign>({
    components: {
        'cfm-multiselect': CFMMultiselect,
    },
    mixins: [mixinz]
})
export default class PendingMaintenancePlansForAssign extends CFMGridPage<CFMModelWorkOrderView> {
    entityType = "WorkOrder";
    sorts:{field:string,direction:string}[] = [{ field:"Id", direction:"Descending" }];
    headerText="";
    commands = [
      {
        buttonOption: { iconCss:'e-icons e-preview-icon'}, title:'Göster'
      }
    ];
    // @ts-ignore
    filterSettings = { 
        type: 'Excel',
        columns:  [
            { field: 'ScheduledTime', matchCase: false, operator: 'greaterthanorequal', predicate: 'and', value: moment().toISOString() },
            { field: 'ScheduledTime', matchCase: false, operator: 'lessthanorequal', predicate: 'and', value: moment().endOf('month').toISOString() },
            { field: 'CategoryId', matchCase: false, operator: 'equal', predicate: 'and', value: 1 },
            { field: 'OrderStatusId', matchCase: false, operator: 'equal', predicate: 'and', value: 1}
        ]
    };

    async mounted(){
        this.dataStateChange(this.state);
    }

    refreshGrid(){
        this.dataStateChange(this.state);
    }

    commandClick(args:any)
    {
        if (args.target.children[0].classList.contains("e-preview-icon")) {
            this.$router.push(
            {
                name:'MyWorkOrder', 
                query:{
                    workOrderId: String(args.rowData.Id)
                }
            });
        }
    }

    recordDoubleClick(args:any){
        this.edit(args.rowData.Id);
    }
    async delete(data: CFMDTOMaintenancePlan){
        const e = await swal.check(this, data.Id);
        if (e.value) {
            blockui.pageBlock(this);
            const response = await this.apiClient.maintenancePlan.maintenancePlanDelete({id: data.Id});
            if (response.data.IsSuccess) {
                toastr.success(this.translate('delete_successfully'));
                this.refreshGrid();
            } else {
                const errorMessage = response.data.Message ? response.data.Message : "Error";
                toastr.error(errorMessage);
            }
            blockui.unBlockPage();
        }
    }
    async edit(workOrderId: number){
        this.$router.push(
            {
                name:'AddWorkOrder', 
                query:{
                    workOrderId: String(workOrderId)
                }
        });
    }
    async toolbarClick(args: ClickEventArgs){
        var selectedRecords = (<any>this.$refs.grid).getSelectedRecords();
        if (args.item.id === 'Edit') {
            if(selectedRecords.length > 0){
                this.edit(selectedRecords[0].Id);
            }
        }
        else if(args.item.id === 'Add'){
        }
        else if(args.item.id === 'Delete'){
            if(selectedRecords.length > 0){
                this.delete(selectedRecords[0]);
            }
        }
        this.toolbarClickBase(args);
    }
}
