




































import {Component, Vue} from 'vue-property-decorator';
import { DataStateChangeEventArgs } from "@syncfusion/ej2-vue-grids";
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations';
import CFMGridPage from '@/scripts/CFMGridPage'

import * as swal from '@/helpers/swal'
import * as blockui from '@/helpers/block-ui'

import {CFMDTOUser, CFMDTOUserAssessment, CFMDTOUserTraining, CFMModelUser} from '@/scripts/services/ClientApiAuto'

import { mixinz } from '@/scripts/global/mixinz';
import UserDialog from './UserDialog.vue'
import UserTrainingModal from '@/components/partials/modals/UserTrainingModal2.vue'
import UserAssessmentModal from '@/components/partials/modals/UserAssessmentModal2.vue'

@Component<User>({
    components:{
        'user-dialog':UserDialog,
        'user-training-modal': UserTrainingModal,
        'user-assessment-modal': UserAssessmentModal,
    },
    mixins: [mixinz]
})
export default class User extends CFMGridPage<CFMModelUser> {
    entityType = "User";
    sorts:{field:string,direction:string}[] = [{ field:"Id", direction:"Descending" }];
    resetPass = false;
    headerText="";
    expandProperties: string[] = ["UserType/Name","Job/Name","Vendor/Name","UserExperience/Name","Role/Name"];

    userTrainingModalIsUpdate = false;
    userAssessmentModalIsUpdate = false;
    selectedUserTraining:CFMDTOUserTraining | { } = {};
    selectedUserAssessment:CFMDTOUserAssessment | { } = {};
    showUserTrainingModal = false;
    showUserAssessmentModal = false;

    async mounted(){
        this.dataStateChange(this.state);
    }
    refreshGrid(){
        this.dataStateChange(this.state);
    }
    async delete(data: CFMDTOUser){
        const e = await swal.check(this, data.Name + ' ' + data.Surname) ;
        if (e.value) {
            blockui.pageBlock(this);
            const response = await this.apiClient.user.userDelete({id: data.Id});
            if (response.data.IsSuccess) {
                toastr.success(this.translate('delete_successfully'));
                this.refreshGrid();
            } else {
                const errorMessage = response.data.Message ? response.data.Message : "Error";
                toastr.error(errorMessage);
            }
            blockui.unBlockPage();
        }
    }
    valueAccessor(data:any) {
        return data['Name'] + ' ' + data['Surname'];
    }

    recordDoubleClick(args:any){
        this.edit(args.rowData.Id,false);
    }
    async edit(id: number,resetPas:boolean){
        this.selectedId = id;
        this.isDialogActive = true;
        this.resetPass = resetPas;
        await this.$nextTick();
        (<UserDialog>this.$refs.userDialog).showDialog();
    }
    async add(){
        this.selectedId = null;
        this.isDialogActive = true;
        this.resetPass = false;
        await this.$nextTick();
        (<UserDialog>this.$refs.userDialog).showDialog();
    }
    async toolbarClick(args: ClickEventArgs){
        var selectedRecords = (<any>this.$refs.grid).getSelectedRecords();
        if (args.item.id === 'Edit') {
            if(selectedRecords.length > 0){
                this.edit(selectedRecords[0].Id,false);
            }
        }
        else if(args.item.id === 'Add'){
               this.add();
        }
        else if(args.item.id === 'Delete'){
            if(selectedRecords.length > 0){
                this.delete(selectedRecords[0]);
            }
        }
      this.toolbarClickBase(args);
    }
    commandTemplate() {
        const parentComponent = this;
        return {
            template: Vue.component("columnTemplate", {
                template: `
                    <div>
                        <button v-if="!isDomainUser" @click="openResetPasswordModal" :title="translate('reset_password')" class="e-lib e-btn e-control e-icon-btn">
                            <span class="e-btn-icon e-icons e-repeat-icon"></span>
                        </button>
                    </div>
                    `,
                methods: {
                    openResetPasswordModal() {
                        const self = <any>this;
                        parentComponent.edit(self.data.Id,true);
                    },
                },
                computed: {
                    isDomainUser() {
                        const self = <any>this;
                        return !!self.data.Username;
                    },
                }
            }),
        };
    }

    async addItem(type: string, parentId: number) {
        switch (type) {
            case 'userTraining':
                this.userTrainingModalIsUpdate = false;
                this.selectedUserTraining = {};
                if (parentId) {
                    this.selectedUserTraining = {
                        UserId: parentId,
                    };
                }
                this.showUserTrainingModal = true;
                await this.$nextTick();
                this.showDialog((<any>this.$refs.userTrainingDialog).$el);
                break;
            case 'userAssessment':
                this.userAssessmentModalIsUpdate = false;
                this.selectedUserAssessment = {};
                if (parentId) {
                    this.selectedUserAssessment = {
                        UserId: parentId,
                    };
                }
                this.showUserAssessmentModal = true;
                await this.$nextTick();
                this.showDialog((<any>this.$refs.userAssessmentDialog).$el);
                break;
        }
    }
    async editItem(type:string, itemId:number) {
        switch (type) {
            case 'userTraining':
                this.userTrainingModalIsUpdate = true;
                this.selectedUserTraining = {};
                this.selectedUserTraining = (await this.apiClient.userTraining.userTrainingGetById({id: itemId})).data;
                this.showUserTrainingModal = true;
                await this.$nextTick();
                this.showDialog((<any>this.$refs.userTrainingDialog).$el);
                break;
            case 'userAssessment':
                this.userAssessmentModalIsUpdate = true;
                this.selectedUserAssessment = {};
                this.selectedUserAssessment = (await this.apiClient.userAssessment.userAssessmentGetById({id: itemId})).data;
                this.showUserAssessmentModal = true;
                await this.$nextTick();
                this.showDialog((<any>this.$refs.userAssessmentDialog).$el);
                break;
        }
    }
    public showDialog(el: Element) {
        (<any>$(el)).modal({
            backdrop: 'static',
            show: true,
        });
    }

}
