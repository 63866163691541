














































































































































































































import CFMDialog from '@/scripts/CFMDialog'
import {Vue, Component} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'
import BootstrapSwitchComponent from '@/components/shared/BootstrapSwitchComponent';
import MinutePicker from '@/components/shared/minutepicker.vue';

import * as dateFormat from '@/helpers/dateFormat';

import {required, requiredIf, minValue} from "vuelidate/lib/validators";

import { CFMDTOWorkOrder, CFMModelWorkWithBreadCrumbView, CFMCoreEntityComboSource, CFMDTOMaintenancePlan, CFMDTOMaintenancePlanUpdate, CFMDTOWorkForMaintenancePlan } from '@/scripts/services/ClientApiAuto'
import { mixinz } from '@/scripts/global/mixinz';

@Component<MaintenancePlanDialog>({

    components: {
        'cfm-multiselect': CFMMultiselect,
        'bootstrap-switch-component': BootstrapSwitchComponent,
        'minutepicker': MinutePicker,
    },

    validations() {
      return {
        startDate:{required},
        maintenancePlan:{
          OrderTypeId: {
            required
          },
          Duration: {
            required,
            minValue: minValue(1),
          },
          VendorId: {
            // @ts-ignore
            requiredIf: requiredIf(() => this.sendMail)
          },
          VendorNotificationRemainingDay: {
            // @ts-ignore
            requiredIf: requiredIf(() => this.sendMail),
            minValue: (value:any) => !this.sendMail || value > 0,
          },
          DeviceId: {
            // @ts-ignore
            requiredIf: requiredIf(() => !this.isUpdate && this.capacityEquipmentList!==null && this.capacityEquipmentList!==null),
            minValue: (value:any) => this.isUpdate || this.capacityEquipmentList===null || this.capacityEquipmentList!==null || value > 0,
          }
        },
        workId: {
          // @ts-ignore
          requiredIf: requiredIf(() => !this.isUpdate)
        },
        maintenancePeriodId: {
          // @ts-ignore
          requiredIf: requiredIf(() => !this.isUpdate)
        },
        maintenanceCount: {
          // @ts-ignore
          requiredIf: requiredIf(() => !this.isUpdate),
          minValue: (value:any) => this.isUpdate || value > 0,
        },
      }
    },
    mixins: [mixinz]
})
export default class MaintenancePlanDialog extends CFMDialog {
    maintenancePlan: CFMDTOWorkOrder = {Name: '', Duration: 0, DeviceId: null};

    fullWorkList : CFMDTOWorkForMaintenancePlan[]= [];

    workList : CFMCoreEntityComboSource[] = [];
    vendorList : {Name:string, Id:number}[] = [];
    maintenancePeriodList : CFMCoreEntityComboSource[] = [];
    maintenanceTypeList : CFMCoreEntityComboSource[] = [];
    capacityEquipmentList : null | CFMCoreEntityComboSource[] = null;

    startDate: null | Date = null ;
    maintenanceCount: number = 1;
    maintenancePeriodId: null | number = null;
    workId: null | number = null;
   
   sendMail=false;

    async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.maintenancePlan.maintenancePlanGetById({id: <number>this.id});
        this.maintenancePlan = res.data;
        this.sendMail = this.maintenancePlan.VendorNotificationRemainingDay != undefined && this.maintenancePlan.VendorNotificationRemainingDay != null ? true : false;
      }
      const promises = [
        this.getWorks(),
        this.getVendors(),
        this.getMaintenancePeriods(),
        this.getMaintenanceTypes(),
      ];
      if(this.isUpdate){
        if (this.maintenancePlan.ScheduledTime) {
          // @ts-ignore
          this.startDate = moment(this.maintenancePlan.ScheduledTime).toDate();
        }
      }
      await Promise.all(promises);
      blockui.unBlockModal();
      
    }
    async onWorkChanged(newValue: null | {Value: number, Text: string}){
      if(newValue) {
        const selectedWork = this.fullWorkList.find(x=>x.Id == newValue.Value);
        this.maintenancePlan.Duration = selectedWork && selectedWork.Duration ? selectedWork.Duration : this.maintenancePlan.Duration
        this.maintenancePlan.DeviceId = null;
        this.capacityEquipmentList = [];
        if(selectedWork && !!selectedWork.ModelId){
          blockui.blockElement(this, this.$el);
          await this.getCapacityEquipments(selectedWork.ModelId);
          blockui.unBlockElement(this.$el);
        }else{
          this.capacityEquipmentList = null;
        }
      } else {
        this.maintenancePlan.DeviceId = null;
        this.capacityEquipmentList = null;
      }
    }

    async getWorks(){
      const response = await this.apiClient.work.workGetListForMaintenancePlan();
      this.fullWorkList = response.data;
      this.workList = response.data.map(x=>({Text:x.BreadCrumb?`${x.BreadCrumb} | ${x.Name}` : x.Name, Value:x.Id}));
    }
    async getVendors(){
      const response = await this.apiClient.vendor.vendorGetVendors();
      this.vendorList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getMaintenancePeriods(){
      const response = await this.apiClient.workPeriod.workPeriodGetPeriods();
      this.maintenancePeriodList = response.data;
    }
    async getMaintenanceTypes(){
      const response = await this.apiClient.workOrderType.workOrderTypeGetWorkOrderTypes();
      this.maintenanceTypeList = response.data;
    }
    async getCapacityEquipments(modelId: number){
      const response = await this.apiClient.capacityEquipment.capacityEquipmentGetCapacityEquipmentsBreadCrumb({ modelId: modelId });
      this.capacityEquipmentList = response.data;
    }

    get StartDate(){
      if (this.startDate) {
        // @ts-ignore
        return moment(this.startDate).format(this.pickerDateTimeFormat);
      }
      return null;
    }
    set StartDate(val){
      if (val) {
        // @ts-ignore
        Vue.set(this, 'startDate', moment(val, this.pickerDateTimeFormat).toDate());
      } else {
        Vue.set(this, 'startDate', val);
      }
    }
      
    get selectedMaintenancePeriod() {
      // @ts-ignore
      return this.selectGet('maintenancePeriodList', 'maintenancePeriodId', 'Value', 'Text');
    }
    set selectedMaintenancePeriod(newValue) {
      // @ts-ignore
      return this.selectSet('maintenancePeriodId', 'Value', newValue);
    }
    get selectedDevice() {
      // @ts-ignore
      return this.selectGet('capacityEquipmentList', 'maintenancePlan.DeviceId', 'Value', 'Text');
    }
    set selectedDevice(newValue) {
      // @ts-ignore
      return this.selectSet('maintenancePlan.DeviceId', 'Value', newValue);
    }
    get selectedWork() {
      // @ts-ignore
      return this.selectGet('workList', 'workId', 'Value', 'Text');
    }
    set selectedWork(newValue) {
      // @ts-ignore
      return this.selectSet('workId', 'Value', newValue);
    }

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      blockui.blockModal(this);
      if (this.isUpdate) {
         try{
          let updateFormData : CFMDTOMaintenancePlanUpdate = {
            Id : this.maintenancePlan.Id,
            Note : this.maintenancePlan.Note,
            StartDate : dateFormat.postDateTimeFormat(<Date>this.startDate),
            Duration : this.maintenancePlan.Duration,
            MaintenanceTypeId : this.maintenancePlan.OrderTypeId,
            VendorNotificationRemainingDay : this.maintenancePlan.VendorNotificationRemainingDay,
            VendorId : this.maintenancePlan.VendorId
          };
          if (!this.sendMail) {
            updateFormData.VendorId = null;
            updateFormData.VendorNotificationRemainingDay = null;
          }
          
          const response  =  await this.apiClient.maintenancePlan.maintenancePlanUpdateMaintenance(updateFormData);
          if (response.data.IsSuccess) {
            toastr.success(this.translate('save_successfully'));

            // TODO : Eren - fix xyz
            // @ts-ignore
            if (this.$route.meta.xyz) {
              // @ts-ignore
              Vue.getDatatables('.maintenance_plan');
            }
            this.saveSucceeded();
            this.close();
          } else {
            const errorMessage = response.data.Message ? response.data.Message : "Error";
            toastr.error(errorMessage);
          }
        }
        catch(e){
          console.log("err",e)
          toastr.error(e);
        }
        finally{
          blockui.unBlockModal();
        }
      }
      else {
        try{
          let formData : CFMDTOMaintenancePlan = {
            Id : this.maintenancePlan.Id,
            Note : this.maintenancePlan.Note,
            StartDate : dateFormat.postDateTimeFormat(<Date>this.startDate),
            Duration : this.maintenancePlan.Duration,
            MaintenanceTypeId : this.maintenancePlan.OrderTypeId,
            VendorNotificationRemainingDay : this.maintenancePlan.VendorNotificationRemainingDay,
            VendorId : this.maintenancePlan.VendorId,
            PeriodId : this.maintenancePeriodId,
            WorkId : <number>this.workId,
            MaintenanceCount : this.maintenanceCount
          };
          if (!this.sendMail) {
            formData.VendorId = null;
            formData.VendorNotificationRemainingDay = null;
          }
          
          const response  =  await this.apiClient.maintenancePlan.maintenancePlanSave(formData);
          if (response.data.IsSuccess) {
            toastr.success(this.translate('save_successfully'));
            // TODO : Eren - fix xyz
            // @ts-ignore
            if (this.$route.meta.xyz) {
              // @ts-ignore
              Vue.getDatatables('.maintenance_plan');
              // @ts-ignore
              this.$parent.slc.works.MaintenancePlanCount = this.$parent.slc.works.MaintenancePlanCount ? this.$parent.slc.works.MaintenancePlanCount + this.maintenanceCount: this.maintenanceCount;
            }
            this.saveSucceeded();
            this.close();
          } else {
            const errorMessage = response.data.Message ? response.data.Message : "Error";
            toastr.error(errorMessage);
          }
        }
        catch(e){
          console.log("err",e)
          toastr.error(e);
        }
        finally{
          blockui.unBlockModal();
        }
      }
    }
}
