




































import CFMDialog from '@/scripts/CFMDialog'
import {Component, Prop} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import {required} from "vuelidate/lib/validators";

import {CFMDTOContract, CFMDTOContractFile} from '@/scripts/services/ClientApiAuto'

@Component<ContractDialog>({
    validations() {
        return {
          contract:{
            Name:{required},
            ContractWorkDays:[]
          }
        }
    },
})

export default class ContractDialog extends CFMDialog {
    @Prop()
	  isShowFiles?:boolean;

    contract: CFMDTOContract = {Name: '', ContractNumber: ''};
    files: CFMDTOContractFile[] = [];

    async created(){
      blockui.blockModal(this);
      if(this.isShowFiles){
        const res = await this.apiClient.contract.contractGetById({id: <number>this.id});
        this.contract = res.data;
        if (res.data.ContractFiles) {
          this.files = res.data.ContractFiles;
        }
        }
      blockui.unBlockModal();
    }
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
    }
}
