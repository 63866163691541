var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal modal-side fade",attrs:{"tabindex":"-1","id":"spare_part_kind_modal","role":"dialog","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.isUpdate ? this.translate("edit_spare_part_kind") : this.translate("add_spare_part_kind"))+" ")]),_vm._m(0)]),_c('form',{attrs:{"action":"","method":"post","novalidate":"novalidate"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"modal-body"},[(_vm.isUpdate)?_c('div',{staticClass:"form-group"},[_c('h5',{staticClass:"m--font-info"},[_vm._v(_vm._s(_vm.sparePartKind.Id))])]):_vm._e(),_c('div',{staticClass:"form-group m-form__group",class:{
              'form-group--error': _vm.$v.sparePartKind.Name.$error,
              'has-danger': _vm.$v.sparePartKind.Name.$error
            }},[_c('label',[_c('span',{directives:[{name:"lang",rawName:"v-lang.spare_part_kind",modifiers:{"spare_part_kind":true}}]}),_c('span',{staticClass:"red"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sparePartKind.Name),expression:"sparePartKind.Name"}],staticClass:"form-control m-input",attrs:{"type":"text","name":"Name","autocomplete":"off"},domProps:{"value":(_vm.sparePartKind.Name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sparePartKind, "Name", $event.target.value)}}}),(_vm.$v.sparePartKind.Name.$error)?[(_vm.$v.sparePartKind.Name.$error)?_c('div',{directives:[{name:"lang",rawName:"v-lang.this_field_is_required",modifiers:{"this_field_is_required":true}}],staticClass:"form-control-feedback error"}):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"form-group m-form__group",class:{
              'form-group--error':
                _vm.$v.selectedSparePartCategory.CategoryId.$error,
              'has-danger':
                _vm.$v.selectedSparePartCategory.CategoryId.$error
            }},[_c('label',[_c('span',{directives:[{name:"lang",rawName:"v-lang.spare_part_category",modifiers:{"spare_part_category":true}}]}),_c('span',{staticClass:"red"},[_vm._v("*")])]),_c('cfm-multiselect',{attrs:{"value-track-by":"CategoryId","value-label":"Name","value-track-by-object-name":"Category","multiple":false,"list":_vm.sparePartCategoryList,"label":"Name","track-by":"Id"},on:{"input":_vm.onCategoryChanged},model:{value:(_vm.selectedSparePartCategory),callback:function ($$v) {_vm.selectedSparePartCategory=$$v},expression:"selectedSparePartCategory"}}),(_vm.$v.selectedSparePartCategory.CategoryId.$error)?[(_vm.$v.selectedSparePartCategory.CategoryId.$error)?_c('div',{directives:[{name:"lang",rawName:"v-lang.this_field_is_required",modifiers:{"this_field_is_required":true}}],staticClass:"form-control-feedback error"}):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"form-group m-form__group",class:{
              'form-group--error':
                _vm.$v.selectedSparePartSubCategory.SubCategoryId.$error,
              'has-danger':
                _vm.$v.selectedSparePartSubCategory.SubCategoryId.$error
            }},[_c('label',[_c('span',{directives:[{name:"lang",rawName:"v-lang.spare_part_sub_category",modifiers:{"spare_part_sub_category":true}}]}),_c('span',{staticClass:"red"},[_vm._v("*")])]),_c('cfm-multiselect',{attrs:{"value-track-by":"SubCategoryId","value-label":"Name","value-track-by-object-name":"SubCategory","multiple":false,"list":_vm.sparePartSubCategoryList,"label":"Name","track-by":"Id"},model:{value:(_vm.selectedSparePartSubCategory),callback:function ($$v) {_vm.selectedSparePartSubCategory=$$v},expression:"selectedSparePartSubCategory"}}),(_vm.$v.selectedSparePartSubCategory.SubCategoryId.$error)?[(_vm.$v.selectedSparePartSubCategory.SubCategoryId.$error)?_c('div',{directives:[{name:"lang",rawName:"v-lang.this_field_is_required",modifiers:{"this_field_is_required":true}}],staticClass:"form-control-feedback error"}):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"modal-footer"},[_c('button',{directives:[{name:"lang",rawName:"v-lang.cancel",modifiers:{"cancel":true}}],staticClass:"btn btn-secondary",attrs:{"type":"button","data-dismiss":"modal"}}),_c('button',{directives:[{name:"lang",rawName:"v-lang.save",modifiers:{"save":true}}],staticClass:"btn btn-primary",attrs:{"type":"submit"}})]),(_vm.isUpdate)?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sparePartKind.Id),expression:"sparePartKind.Id"}],attrs:{"type":"hidden","name":"Id"},domProps:{"value":(_vm.sparePartKind.Id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sparePartKind, "Id", $event.target.value)}}})]):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])}]

export { render, staticRenderFns }