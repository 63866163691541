<template>
    <div>
        <h5 class="m--padding-top-15 mb-0" v-lang.forms></h5>

        <div v-if="isFormAddable && isEditable && isFormsAnswerable" class="column-input">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group m-form__group">
                        <label><span v-lang.add_form></span></label>
                        <multiselect v-model="selectedSuitableWorkOrderForm"
                            track-by="Value"
                            label="Text"
                            :options="notExistSuitableWorkOrderForms"
                            v-bind="selectOptions">
                        </multiselect>
                        <div class="btn-procedure-plus">
                            <button class="btn btn-success" :disabled="!selectedSuitableWorkOrderForm" type="button" @click="addForm()">
                                <i class="fa fa-plus"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group column-input m-form__group d-flex align-items-center">
            <label v-lang.show_only_has_invalid_answer_ones></label>
            <span class="m-switch m-switch--outline m-switch--primary m-loader--left">
                <label>
                    <input type="checkbox"
                           v-model="showOnlyInvalidForms"/>
                    <span></span>
                </label>
            </span>
        </div>
        <div class="row m--padding-top-15 m--padding-bottom-15 border-bottom">
            <div class="col-md-12 fa-border" v-for="(form) in forms" v-show="!showOnlyInvalidForms||form.InvalidAnswerCount>0">
                <div>
                    <div class="row form-row-item">
                        <div class="col-md-4">
                            <!--TODO procedureId-->
                            <a href="javascript:;"
                               @click="openForm(form.Id, form.ProcedureTypeId, form.FormProcessStatusId)">
                                <div>
                                    <span class="m--font-bolder">{{form.Name}}</span>
                                    <i style="color:#006c95; margin-left:10px;"
                                       class="b-fa b-fa-flag custom-feedback-button">
                                        {{form.FlagCount}}
                                    </i>
                                    <!--<small>V.{{form.Version}}</small>-->
                                </div>
                                <div>
                                    <span v-lang.invalid_answer_count></span>
                                    : {{form.InvalidAnswerCount}}
                                </div>
                                <div>
                                    <span v-lang.procedure_type></span>
                                    : {{ form.ProcedureType}}
                                </div>
                                <span v-if="!form.FormProcessStatusId"
                                      class="badge badge-danger">
                                    {{translate('not_started')}}
                                </span>
                                <span v-else
                                      class="badge"
                                      style="color: #fff"
                                      :style="{backgroundColor:form.ColorCode}">
                                    {{form.FormProcess}}
                                </span>
                            </a>
                        </div>
                        <div class="col-md-4">
                            <a @click="printEmptyForm(form.Id,3)"
                               class="a-link"
                               v-lang.print_empty_form>
                            </a>
                        </div>
                        <div class="col-md-4">
                            <!--TODO procedureId-->
                            <a @click="uploadFormCopy(form.Id,3)"
                               class="a-link">
                                <span v-lang.upload_form></span>
                                ({{form.FormProcessFileCount}})
                            </a>
                        </div>
                    </div>
                </div>
                <i v-if="!form.FormProcessStatusId && isFormAddable && isEditable && isFormsAnswerable"
                    @click="removeForm(form)"
                    style="position: absolute; right: 5px; top: 5px; cursor:pointer;"
                    class="fa fa-times btn-outline-danger"></i>
            </div>
        </div>
        <work-order-form-modal v-bind="params"
                               v-if="showFormModal"
                               @changeForm="changeForm"
                               @close="fetchWorkOrderForm(currentFormId); showFormModal = false; params = {};"/>
        <form-upload-copy-modal v-bind="params"
                                v-if="showFormUploadCopyModal"
                                @close="fetchWorkOrderForm(currentFormId); showFormUploadCopyModal = false; params = {};"/>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import * as api from '../../../../../scripts/services/api';
import * as blockui from '../../../../../helpers/block-ui';
import * as swal from '../../../../../helpers/swal';
import { mixinz } from '../../../../../scripts/global/mixinz';

import WorkOrderFormModal from '../../../../partials/modals/WorkOrderFormModal';
import FormUploadCopyModal from '../../../../partials/modals/FormUploadCopyModal';

export default {
  name: 'WorkOrderForms',
  mixins: [mixinz],
  components: {
    'work-order-form-modal': WorkOrderFormModal,
    'form-upload-copy-modal': FormUploadCopyModal,
  },
  props: {
    workOrderId: {
      type: [Number, String],
      required: true,
    },
    isEditable: {
      required: true,
    },
    isFormsAnswerable: {
      required: true,
    },
    isFormAddable: {
      required: true,
    },
  },
  data() {
    return {
      // forms: [],
      showOnlyInvalidForms: false,
      params: {},
      showFormModal: false,
      showFormUploadCopyModal: false,
      currentFormId: null,
      suitableWorkOrderForms: [],
      selectedSuitableWorkOrderForm: null,
    };
  },
  async mounted() {
    const self = this;
    self.fetchWorkOrderForms();

    blockui.blockElementPromise(self, self.$el);
    try {
      const response = await this.$globalApiClient.form.formGetAvailableSopFormListForWorkOrder({workOrderId:self.workOrderId});
      self.suitableWorkOrderForms = response.data;
    } catch (e) {
      toastr.error(e.message);
    } finally {
      blockui.unBlockElement(self.$el);
    }
  },
  computed: {
    ...mapState({
      formProcessStatus: (state) => state.enums.formProcessStatus,
    }),
    ...mapGetters({
      getWorkOrderForms: 'getWorkOrderForms',
    }),
    forms() {
      const workOrderForms = this.getWorkOrderForms.find((x) => x.workOrderId == this.workOrderId);
      return workOrderForms ? workOrderForms.forms : [];
    },
    notExistSuitableWorkOrderForms() {
      return this.suitableWorkOrderForms.filter((x) => !this.forms.some((form) => form.BaseFormId == x.Value));
    },
  },
  created() {
    const self = this;
    self.$eventHub.$on('decrementFlagCount', this.decrementFlagCount);
  },
  beforeDestroy() {
    this.$eventHub.$off('decrementFlagCount');
  },
  methods: {
    removeForm(form) {
      const self = this;
      swal.check(self, form.Name).then(async (e) => {
        if (e.value) {
          blockui.blockElementPromise(self, self.$el);
          try {
            const result = await api.deleteWorkOrderForm(self.workOrderId, form.BaseFormId);
            if (!result.body.IsSuccess) {
              throw result.body.Message;
            }
            self.fetchWorkOrderForms();
            toastr.success(self.translate('save_successfully'));
          } catch (e) {
            toastr.error(e.message);
          } finally {
            blockui.unBlockElement(self.$el);
          }
        }
      });
    },
    async addForm() {
      if (!this.selectedSuitableWorkOrderForm) {
        return;
      }
      blockui.blockElementPromise(this, this.$el);
      try {
        const result = await api.addWorkOrderForm(this.workOrderId, this.selectedSuitableWorkOrderForm.Value);
        if (!result.body.IsSuccess) {
          throw result.body.Message;
        }
        this.fetchWorkOrderForms();
        this.selectedSuitableWorkOrderForm = null;
        toastr.success(this.translate('save_successfully'));
      } catch (e) {
        toastr.error(e.message);
      } finally {
        blockui.unBlockElement(this.$el);
      }
    },
    decrementFlagCount({ workOrderId, formId }) {
      if (!formId) {
        return;
      }
      const relatedForm = this.forms.find((form) => form.Id == formId);
      if (!relatedForm) {
        return;
      }
      const formFlagCount = relatedForm.FlagCount - 1;
      Vue.set(relatedForm, 'FlagCount', formFlagCount);
    },
    async fetchWorkOrderForms() {
      blockui.blockElementPromise(this, this.$el);
      try {
        await this.$store.dispatch('getWorkOrderFormsByWorkOrderId', { workOrderId: this.workOrderId });
        // Forms doldurulacak
        // this.forms = this.getWorkOrderForms.find(x => x.workOrderId == this.workOrderId).workOrderForms;
        /* const response = await api.getFormStatusSummaryList(this.workOrderId);
                    if (!response.body || !response.body) {
                        throw new Error("Error");
                    }
                     = response.body; */
      } catch (e) {
        toastr.error(e.message);
      } finally {
        blockui.unBlockElement(this.$el);
      }
    },
    async fetchWorkOrderForm(formId) {
      blockui.blockElementPromise(this, this.$el);
      try {
        await this.$store.dispatch('getWorkOrderFormsByWorkOrderIdAndFormId', {
          workOrderId: this.workOrderId,
          formId,
        });
        // set edilecek yeni status değeri

        /*   const response = await api.getFormStatusSummary(this.workOrderId, formId);
                       if (!response.body || !response.body.Data) {
                           throw new Error("Error");
                       }
                       const formIndex = this.forms.findIndex(form => form.Id == formId);
                       if (formIndex >= 0) {
                           Vue.set(this.forms, formIndex, response.body.Data);
                       } */
      } catch (e) {
        toastr.error(e.message);
      } finally {
        blockui.unBlockElement(this.$el);
      }
    },
    printEmptyForm(formId, procedureTypeId) {
      this.currentFormId = formId;
      this.showModal(
        'params',
        {
          formId,
          procedureTypeId,
          isEditable: false,
        },
        'showFormModal',
        'form_modal',
      );
    },
    uploadFormCopy(formId) {
      this.currentFormId = formId;
      this.showModal(
        'params',
        {
          formId,
          workOrderId: this.workOrderId,
        },
        'showFormUploadCopyModal',
        'form_upload_copy_modal',
      );
    },
    async openForm(formId, procedureTypeId, formProcessStatusId) {
      const self = this;
      const isEditable = this.isFormsAnswerable && this.isEditable && (!formProcessStatusId || formProcessStatusId == self.formProcessStatus.started || formProcessStatusId == self.formProcessStatus.continues || formProcessStatusId == self.formProcessStatus.notStarted);
      self.currentFormId = formId;
      this.showModal(
        'params',
        {
          selectedWorkOrderFormsCount: self.forms && self.forms.length ? self.forms.length : 0,
          formId,
          procedureTypeId,
          workOrderId: this.workOrderId,
          isEditable,
        },
        'showFormModal',
        'form_modal',
      );
    },
    changeForm(e) {
      const self = this;
      if (self.currentFormId != e.formId) {
        self.$nextTick(() => {
          self.currentFormId = e.formId;
          Vue.set(self, 'params', {
            selectedWorkOrderFormsCount: self.forms && self.forms.length ? self.forms.length : 0,
            formId: e.formId,
            procedureTypeId: e.procedureTypeId,
            workOrderId: self.workOrderId,
            isEditable: e.isEditable,
            continueButton: e.false,
          });
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
