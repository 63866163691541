import Vue from 'vue';
import { mapState } from 'vuex';
import * as api from '../../../scripts/services/api';
import * as blockui from '../../../helpers/block-ui';
import * as dateFormat from '../../../helpers/dateFormat';
import Dropdown from '../../shared/MyWorkOrderDropdown';
import * as swal from '../../../helpers/swal';

export default {
  components: {
    dropdown: Dropdown,
  },
  data() {
    return {
      myWorkOrders: {},
      myDailyWorkOrders: {},
      overdueDate: '',
      upcomingDate: '',
      statuses: [],
    };
  },
  mounted() {
    const self = this;
    self.init();
    self.getWorkOrderStatusList();
    self.$eventHub.$on('changeWOStatus', self.init);
    document.querySelector('.m-content').style.padding = '0px 0px';
  },
  computed: {
    ...mapState({
      workOrderStatuses: (state) => state.enums.workOrderStatus,
      workOrderSourceEnums: (state) => state.enums.workOrderSource,
    }),
    yesterday() {
      return moment().add(-1, 'days').toDate();
    },
    today() {
      return moment().toDate();
    },
    tomorrow() {
      return moment().add(1, 'days').toDate();
    },
  },
  methods: {
    getWorkOrderStatusList() {
      const self = this;
      api.getWorkOrderStatusList(null).then((res) => {
        self.statuses = res.body.Data;
      });
    },
    init() {
      const self = this;
      blockui.pageBlock();
      api.getMyWorkOrders().then((response) => {
        if (response.body.IsSuccess) {
          self.overdueDate = response.body.Data.OverdueDate;
          self.upcomingDate = response.body.Data.UpcomingDate;
          Object.keys({
            OverdueWorkOrders: response.body.Data.OverdueWorkOrders,
            TodaysWorkOrders: response.body.Data.TodaysWorkOrders,
            UpcomingWorkOrders: response.body.Data.UpcomingWorkOrders,
          }).map((key, index) => {
            const dailyWorkOrders = response.body.Data[key].filter((item) => item.IsDailyWorkOrderCategory);
            const workOrders = response.body.Data[key].filter((item) => !item.IsDailyWorkOrderCategory);
            if (index === 0) {
              Vue.set(self.myWorkOrders, index, workOrders.sort(self.compareDatesDESC));
              Vue.set(self.myDailyWorkOrders, index, dailyWorkOrders.sort(self.compareDatesDESC));
            } else {
              Vue.set(self.myWorkOrders, index, workOrders.sort(self.compareDatesASC));
              Vue.set(self.myDailyWorkOrders, index, dailyWorkOrders.sort(self.compareDatesASC));
            }
          });
        }
        blockui.unBlockPage();
      }, (error) => {
        blockui.unBlockPage();
      });
    },
    compareDatesDESC(a, b) {
      if (new Date(a.ScheduledTime) < new Date(b.ScheduledTime)) {
        return 1;
      } if (new Date(a.ScheduledTime) > new Date(b.ScheduledTime)) {
        return -1;
      }
      return 0;
    },
    compareDatesASC(a, b) {
      if (new Date(a.ScheduledTime) < new Date(b.ScheduledTime)) {
        return -1;
      } if (new Date(a.ScheduledTime) > new Date(b.ScheduledTime)) {
        return 1;
      }
      return 0;
    },
    viewDateTimeFormat(date) {
      return dateFormat.viewDateTimeFormat(date);
    },
    viewDateFormat(date) {
      return dateFormat.viewDateFormat(date);
    },
    viewRelativeDateTimeFormat(date) {
      return dateFormat.viewRelativeDateTimeFormat(date);
    },
    routeToMyWorkOrder(workOrderId) {
      this.$router.push({ name: 'MyWorkOrder', query: { from: 'MyWorkOrders', workOrderId } });
    },
    changeStatusWorkOrderControl(myWorkOrder, newStatusId) {
      if (myWorkOrder.OrderSourceId != newStatusId) {
        const self = this;

        const previousOrderStatus = self.statuses.find((status) => status.Id == myWorkOrder.OrderStatusId);
        const currentOrderStatus = self.statuses.find((status) => status.Id == newStatusId);
        if (currentOrderStatus.Id != self.workOrderStatuses.onHold) {
          swal.check(self, self.translate('are_you_sure'), self.translate('ask_status_update_from_to').formatString(myWorkOrder.WorkName, previousOrderStatus.Name, currentOrderStatus.Name)).then((e) => {
            if (e.value) {
              self.changeStatusWorkOrder(myWorkOrder, currentOrderStatus);
            }
          });
        } else {
          self.changeStatusWorkOrder(myWorkOrder, currentOrderStatus);
        }
      }
    },
    changeStatusWorkOrder(selectedWorkOrder, currentOrderStatus) {
      const self = this;
      blockui.pageBlock();
      self.$globalApiClient.workOrder.workOrderUpdateStatus({
        Id: selectedWorkOrder.WorkOrderId,
        OrderStatusId: currentOrderStatus.Id,
      }).then((response) => {
        if (response.data.IsSuccess) {
          toastr.success(self.translate('status_update_work_order_successfully').formatString(selectedWorkOrder.WorkName, selectedWorkOrder.OrderStatusName, currentOrderStatus.Name));
          self.init();
        } else {
          toastr.error(response.data.Message);
        }
        blockui.unBlockPage();
      }, (error) => {
        blockui.unBlockPage();
      });
    },
  },
  beforeDestroy() {
    this.$eventHub.$off('changeWOStatus');
  },
};
