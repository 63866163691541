






































import CFMDialog from '@/scripts/CFMDialog'
import {Component,Prop} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import {required} from "vuelidate/lib/validators";
import {CFMDTOApproveWorkOrderDTO} from "@/scripts/services/ClientApiAuto";

@Component<PendingApprovalWorkOrderDialog>({
    validations() {
        return {
          description:{
              Name: {required}
          }
        }
    },
})
export default class PendingApprovalWorkOrderDialog extends CFMDialog {
    @Prop()
    orderStatusId?: number;
    @Prop()
    isAction?: boolean;

    description: {Name:string,Id:number} = {Name:'',Id:0};
    descriptionList: {Name:string,Id:number}[] = [];

    async created(){
      blockui.blockModal(this);
      const response = await this.apiClient.workOrderStatusDescription.workOrderStatusDescriptionGetWorkOrderStatusDescriptions({orderStatusId: this.orderStatusId});
      this.descriptionList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
      blockui.unBlockModal();
    }
    addDescription(){
      this.$emit('addProcedure');
    }

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      blockui.blockModal(this);
      try{
        const approveWorkOrderDTO: CFMDTOApproveWorkOrderDTO = {Id: <number>this.id, WorkOrderStatusDescriptionId: <number>this.description.Id, StatusId: this.orderStatusId};
        const response  = await this.apiClient.workOrder.workOrderApproveWorkOrder(approveWorkOrderDTO);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }
}
