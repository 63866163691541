<template>
    <div class="timeline_wrapper">
        <div class="timeline_item" v-for="(item,i) in workOrderHistory" :key="i">
            <div v-bind:style="{ backgroundColor: item.orderStatusColor}"
                 class="timeline_line"></div>
            <div class="timeline_content">
                <span v-bind:style="{ borderColor: item.orderStatusColor, backgroundColor:item.orderStatusColor}"
                      class="timeline_icon">
                    <i class="fas fa-bell"></i>
                </span>
                <div class="timeline_status">
                    <span v-bind:style="{ color: item.orderStatusColor }">{{item.orderSubStatusName ? item.orderStatusName + " - " + item.orderSubStatusName : item.orderStatusName}}</span>
                </div>
                <div class="detail_row">

                    <div class="timeline_date">
                        <span><i class="fas fa-clock"></i> {{item.date}}</span>
                    </div>
                    <div class="timeline_date">
                        <span><i class="fas fa-user"></i> {{item.userName}}</span>
                    </div>
                    <div class="timeline_title">
                        <span>{{item.title}}.</span>
                    </div>

                </div>
                <div class="timeline_date">
                    <div class="timeline_message">
                        {{item.message}}
                    </div>
                </div>
                <div style="display: table; clear: both;"></div>
            </div>
            <div style="display: table; clear: both;"></div>
        </div>
    </div>
</template>

<script>
import * as api from '../../../../../scripts/services/api';
import * as blockui from '../../../../../helpers/block-ui';

import * as dateFormat from '../../../../../helpers/dateFormat';
import { compareDates } from '../../../../../scripts/global/util';

export default {
  name: 'WorkOrderHistory',
  props: {
    workOrderId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      workOrderHistory: [],
    };
  },
  async mounted() {
    blockui.blockElementPromise(this, this.$el);
    try {
      const response = await this.$globalApiClient.workOrder.workOrderGetWorkOrderHistory({workOrderId: this.workOrderId});
      if (!response.data) {
        throw new Error('Error');
      }
      this.workOrderHistory = await this.formatData(response.data);
    } catch (e) {
      toastr.error(e.message);
    } finally {
      blockui.unBlockElement(this.$el);
    }
  },
  methods: {
    async formatData(history) {
      const self = this;
      const resultData = [];
      history = history.sort((a, b) => compareDates(a.ModifiedDate ? a.ModifiedDate : a.CreatedDate, b.ModifiedDate ? b.ModifiedDate : b.CreatedDate));
      for (let i = 0; i < history.length; i++) {
        const currentItem = history[i];
        if (i === 0) {
          resultData.push({
            title: this.translate('created'),
            date: currentItem.ModifiedDate ? dateFormat.viewDateTimeFormat(currentItem.ModifiedDate) : dateFormat.viewDateTimeFormat(currentItem.CreatedDate),
            orderStatusName: currentItem.WorkOrderStatus,
            orderSubStatusName: currentItem.WorkOrderSubStatus,
            orderStatusColor: currentItem.ColorCode,
            message: this.translate('work_order_history_created').formatString(dateFormat.viewDateTimeFormat(currentItem.ScheduledTime), currentItem.Duration, currentItem.WorkOrderStatus),
            userId: currentItem.ModifiedUserId || currentItem.CreatedUserId,
            userName: currentItem.ModifiedUser || currentItem.CreatedUser,
          });
        } else {
          const previousItem = history[i - 1];
          let message = '';
          if (previousItem.ScheduledTime != currentItem.ScheduledTime) {
            message += this.translate('scheduled_time_history_updated').formatString(dateFormat.viewDateTimeFormat(previousItem.ScheduledTime), dateFormat.viewDateTimeFormat(currentItem.ScheduledTime));
          }
          if (previousItem.Duration != currentItem.Duration) {
            message += this.translate('duration_history_updated').formatString(previousItem.Duration, currentItem.Duration);
          }
          if (previousItem.WorkOrderStatus != currentItem.WorkOrderStatus) {
            message += this.translate('status_history_updated').formatString(previousItem.WorkOrderStatus, currentItem.WorkOrderStatus);
          }
          if (message == '') {
            message += this.translate('user_assigned');
            // continue;
          }
          resultData.push({
            title: this.translate('updated'),
            date: currentItem.ModifiedDate ? dateFormat.viewDateTimeFormat(currentItem.ModifiedDate) : dateFormat.viewDateTimeFormat(currentItem.CreatedDate),
            orderStatusName: currentItem.WorkOrderStatus,
            orderSubStatusName: currentItem.WorkOrderSubStatus,
            orderStatusColor: currentItem.ColorCode,
            message,
            userId: currentItem.ModifiedUserId || currentItem.CreatedUserId,
            userName: currentItem.ModifiedUser || currentItem.CreatedUser,
          });
        }
      }
      return resultData.reverse();
    },
  },
};
</script>

<style scoped>

</style>
