<template>
    <form>
        <div class="row">
            <div class=" col-sm-12 col-lg-3 col-md-3 col-xl-2">
                <div class="form-group"
                     :class="{ 'form-group--error': $v.selectedContract.Name.$error&&validation,
                     'has-danger': $v.selectedContract.Name.$error&&validation}">
                    <label><span v-lang.contract_name></span><span class="red">*</span></label>
                    <input type="text" class="form-control" name="ContractName" autocomplete="off"
                           v-model="selectedContract.Name"
                           :disabled="!IsEditable"/>
                    <div v-if="$v.selectedContract.Name.$error&&validation"  style="margin-top:20%">
                        <div v-if="$v.selectedContract.Name.$error&&validation"
                             class="form-control-feedback error"
                             v-lang.this_field_is_required></div>
                    </div>
                </div>
            </div>
            <div class=" col-sm-12 col-lg-3 col-md-3 col-xl-2">
                <div class="form-group"
                     :class="{ 'form-group--error': $v.selectedContract.ContractNumber.$error&&validation,
                     'has-danger': $v.selectedContract.ContractNumber.$error&&validation}">
                    <label><span v-lang.contract_number></span><span class="red">*</span></label>
                    <input type="text" class="form-control" name="ContractNumber" autocomplete="off"
                           v-model="selectedContract.ContractNumber"
                           :disabled="!IsEditable"/>
                    <div v-if="$v.selectedContract.ContractNumber.$error&&validation">
                        <div v-if="$v.selectedContract.ContractNumber.$error&&validation"
                             class="form-control-feedback error"
                             v-lang.this_field_is_required></div><!-- v-lang.at_least_one_character_must_be_entered-->
                    </div>
                </div>
            </div>
            <div class=" col-sm-12 col-lg-3 col-md-3 col-xl-2">
                <div class="form-group"
                     :class="{ 'form-group--error': $v.selectedContract.StartDate.$error&&validation,
                     'has-danger': $v.selectedContract.StartDate.$error&&validation}">
                    <label><span v-lang.start_date></span><span class="red">*</span></label>
                    <datepicker :value="selectedContract.StartDate"
                                @update:value="setContractProperty('StartDate',$event)"
                                type="text" name="StartDate" autocomplete="off"
                                class="form-control date-picker"
                                :disabled="!IsEditable"/>
                    <div v-if="$v.selectedContract.StartDate.$error&&validation">
                        <div v-if="$v.selectedContract.StartDate.$error&&validation"
                             class="form-control-feedback error"
                             v-lang.this_field_is_required></div>
                    </div>
                </div>
            </div>
            <div class=" col-sm-12 col-lg-3 col-md-3 col-xl-2">
                <div class="form-group"
                     :class="{ 'form-group--error': $v.selectedContract.EndDate.$error&&validation,
                     'has-danger': $v.selectedContract.EndDate.$error&&validation}">
                    <label><span v-lang.end_date></span><span class="red">*</span></label>
                    <datepicker :value="selectedContract.EndDate" @update:value="setContractProperty('EndDate',$event)"
                                type="text" name="EndDate" autocomplete="off"
                                class="form-control date-picker"
                                :disabled="!IsEditable"/>
                    <div v-if="$v.selectedContract.EndDate.$error&&validation">
                        <div v-if="$v.selectedContract.EndDate.$error&&validation"
                             class="form-control-feedback error"
                             v-lang.this_field_is_required></div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-lg-12 col-xl-8">
                <div class="form-group"
                     :class="{ 'form-group--error': $v.selectedContract.ContractDevices.$error&&validation,
                     'has-danger': $v.selectedContract.ContractDevices.$error&&validation}">
                    <label><span v-lang.capacity_equipments></span><span class="red">*</span></label>
                    <multiselect v-model="selectedCapacityEquipment"
                                 :options="capacityEquipmentList"
                                 :multiple="true"
                                 label="Text"
                                 v-bind="selectOptions"
                                 track-by="Value"
                                 :close-on-select="false"
                                 :clear-on-select="false"
                                 :preserve-search="true"
                                 :disabled="!IsEditable">
                    </multiselect>
                    <div v-if="$v.selectedContract.ContractDevices.$error&&validation">
                        <div v-if="$v.selectedContract.ContractDevices.$error&&validation"
                             class="form-control-feedback error"
                             v-lang.this_field_is_required></div>
                    </div>

                </div>
            </div>

        </div>
    </form>
</template>

<script>
import Vue from 'vue';
import { required, minLength } from 'vuelidate/lib/validators';
import * as api from '../../../scripts/services/api';
import { mixinz } from '../../../scripts/global/mixinz';

export default {
  mixins: [mixinz],
  props: ['value', 'validation', 'IsEditable'],
  data() {
    return {
      selectedContract: {},
      capacityEquipmentList: [],
    };
  },
  validations() {
    return {
      selectedContract: {
        Name: {
          required,
          minLength: minLength(1),
        },
        ContractNumber: {
          required,
          minLength: minLength(1),
        },
        StartDate: {
          required,
        },
        EndDate: {
          required,
        },
        ContractDevices: {
          required,
        },
      },
    };
  },
  computed: {

    selectedCapacityEquipment: {
      get() {
        const self = this;
        return self.selectMultipleGet('capacityEquipmentList', 'selectedContract.ContractDevices', 'Value', 'DeviceId');
      },
      set(newValue) {
        const self = this;
        return self.selectMultipleSet('selectedContract.ContractDevices', 'Value', newValue, 'DeviceId');
      },
    },
  },
  mounted() {
    this.$v.$touch();
    this.selectedContract = this.value;
    api.getCapacityEquipments().then((response) => {
      this.capacityEquipmentList = response.body;
    });
  },
  methods: {
    setContractProperty(propertyKey, value) {
      Vue.set(this.selectedContract, propertyKey, value);
    },

  },
  watch: {
    selectedContract(val) {
      this.$emit('input', val);
    },
    value(newValue) {
      this.selectedContract = newValue;
    },
  },
};
</script>

<style scoped>

</style>
