


































import { Component } from 'vue-property-decorator';
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations';
import CFMGridPage from '@/scripts/CFMGridPage'

import * as swal from '@/helpers/swal'
import * as blockui from '@/helpers/block-ui'

import { CFMModelWorkOrderView, CFMDTOMaintenancePlan } from '@/scripts/services/ClientApiAuto'
import MaintenancePlanDialog from './MaintenancePlanDialog.vue'

import { mixinz } from '@/scripts/global/mixinz';
import moment from 'moment';


@Component<MaintenancePlan>({

    components: {
        'maintenance-plan-dialog' : MaintenancePlanDialog
    },
    mixins: [mixinz]
})
export default class MaintenancePlan extends CFMGridPage<CFMModelWorkOrderView> {
    entityType = "WorkOrder";
    sorts:{field:string,direction:string}[] = [{ field:"Id", direction:"Descending" }];
    // @ts-ignore
    filterSettings = { 
        type: 'Excel',
        columns:  [
            { field: 'StartDate', matchCase: false, operator: 'greaterthanorequal', predicate: 'and', value: moment().toISOString() },
            { field: 'EndDate', matchCase: false, operator: 'lessthanorequal', predicate: 'and', value: moment().add(7, 'days').endOf('week').toISOString() },
            { field: 'OrderSourceId', matchCase: false, operator: 'equal', predicate: 'and', value: 1}
        ]
    };
    
    created(){
      const newToolbarItems = [{
        text: this.translate('transform_to_work_order'), tooltipText: this.translate('transform_to_work_order'), prefixIcon: 'e-open-hyperlink', id: 'TransformToWorkOrder',
      }, {
        text: this.translate('show'), tooltipText: this.translate('show'), prefixIcon: 'e-preview', id: 'Show',
      }];
      this.toolbar.push(...newToolbarItems);
    }

    async mounted(){
        this.dataStateChange(this.state);
    }

    refreshGrid(){
        this.dataStateChange(this.state);
    }

    customiseCell(args: any) { // Added status column
        if (args.column.field === 'OrderStatusName') { 
            args.cell.classList.add('bg-color'); 
        }
    }

    querCellInfo(args: any) { // Added status column
        if(args.column){
            if (args.column.field === "OrderStatusName") { 
                let value = args.data["ColorCode"]; 
                args.cell.style.backgroundColor = value; 
                return; 
        }
      } 
    }

    recordDoubleClick(args:any){
        this.edit(args.rowData.Id);
    }

    async delete(data: CFMDTOMaintenancePlan){
        const e = await swal.check(this, data.Id);
        if (e.value) {
            blockui.pageBlock(this);
            const response = await this.apiClient.maintenancePlan.maintenancePlanDelete({id: data.Id});
            if (response.data.IsSuccess) {
                toastr.success(this.translate('delete_successfully'));
                this.refreshGrid();
            } else {
                const errorMessage = response.data.Message ? response.data.Message : "Error";
                toastr.error(errorMessage);
            }
            blockui.unBlockPage();
        }
    }

    async edit(id: number){
        this.selectedId = id;
        this.isDialogActive = true;
        await this.$nextTick();
        (<MaintenancePlanDialog>this.$refs.maintenancePlanDialog).showDialog();
    }

    async add(){
        this.selectedId = null;
        this.isDialogActive = true;
        await this.$nextTick();
        (<MaintenancePlanDialog>this.$refs.maintenancePlanDialog).showDialog();
    }


    async transformToWorkOrder(data: CFMDTOMaintenancePlan){
        const e = await swal.check(this, this.translate('transform_to_work_order'), data.Id);
        if (e.value) {
            blockui.pageBlock(this);
            const response = await this.apiClient.maintenancePlan.maintenancePlanTransFormToWorkOrder(<number>data.Id);
            if (response.data.IsSuccess) {
                blockui.unBlockPage();
                this.$router.push({ name: 'AddWorkOrder', query: { 'workOrderId': data.Id ? data.Id.toString() : '' } });
            } else {
                const errorMessage = response.data.Message ? response.data.Message : "Error";
                toastr.error(errorMessage);
                blockui.unBlockPage();
            }
            blockui.unBlockPage();
        }
    }

    async show(data: CFMDTOMaintenancePlan){
        this.$router.push({ name: 'MyWorkOrder', query: { from: 'WorkOrderList', 'workOrderId': data.Id ? data.Id.toString() : '' } });
    }

    async toolbarClick(args: ClickEventArgs){
        var selectedRecords = (<any>this.$refs.grid).getSelectedRecords();
        if (args.item.id === 'Edit') {
            if(selectedRecords.length > 0){
                this.edit(selectedRecords[0].Id);
            }
        }
        else if(args.item.id === 'Add'){
               this.add();
        }
        else if(args.item.id === 'Delete'){
            if(selectedRecords.length > 0){
                this.delete(selectedRecords[0]);
            }
        }
        else if(args.item.id === 'TransformToWorkOrder'){
            if(selectedRecords.length > 0){
                this.transformToWorkOrder(selectedRecords[0]);
            }
        }
        else if(args.item.id === 'Show'){
            if(selectedRecords.length > 0){
                this.show(selectedRecords[0]);
            }
        }
        this.toolbarClickBase(args);
    }
}
