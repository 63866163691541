







































import { Component } from 'vue-property-decorator';
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations';
import CFMGridPage from '@/scripts/CFMGridPage'

import * as swal from '@/helpers/swal'
import * as blockui from '@/helpers/block-ui'

import { CFMDTOWorkOrder, CFMModelPendingWorkOrderApprovalsView } from '@/scripts/services/ClientApiAuto'
import PendingApprovalWorkOrderDialog from './PendingApprovalWorkOrderDialog.vue';
import * as dateFormat from '@/helpers/dateFormat';


@Component<PendingApprovalWorkOrder>({
    components:{
        'pending-approval-work-order-dialog':PendingApprovalWorkOrderDialog
    }
})
export default class PendingApprovalWorkOrder extends CFMGridPage<CFMModelPendingWorkOrderApprovalsView> {
    entityType = "PendingWorkOrderApproval";
    sorts:{field:string,direction:string}[] = [{ field:"Id", direction:"Descending" }];
    headerText = "";
    // filterSettings: any =  { 
    //     type: 'Excel',
    //     columns:  [
    //         { field: 'OrderSourceId', matchCase: false, operator: 'equal', predicate: 'and', value: 9, uid:'Id' },
    //     ]
    // };
    orderStatusId?:number;
    isAction?:boolean;
    commandPreview =[{buttonOption: {iconCss:"e-icons e-preview-icon" }, title:'Ön İzleme'}]
    commandSendBack =[{buttonOption: {iconCss:"e-icons e-send-back-icon" },title:'Geri Gönder'}]
    commandApprove =[{buttonOption: {iconCss:"e-icons e-approve-icon" },title:'Onayla'}]
    commandReject =[{buttonOption: {iconCss:"e-icons e-reject-icon" },title:'Reddet'}]

    created(){
        this.toolbar = [{
          text: 'Excel Export', tooltipText: 'Excel Export', prefixIcon: 'e-excelexport', id: 'ExcelExport'
        }, {
          text: 'CSV Export', tooltipText: 'CSV Export', prefixIcon: 'e-csvexport', id: 'CSVExport'
        }, {
          text: 'PDF Export', tooltipText: 'PDF Export', prefixIcon: 'e-pdfexport', id: 'PDFExport'
        }];
    }
    async mounted(){
        this.dataStateChange(this.state);
    }
    /*async getPendingData(): Promise<CFMModelPendingWorkOrderApprovalsView[]> {
        const response = await this.apiClient.workOrder.workOrderGetPendingApprovals();
        return response.data;
    }*/
    refreshGrid(){
        this.dataStateChange(this.state);
    }
    async delete(data: CFMDTOWorkOrder){
        const e = await swal.check(this, data.Id);
        if (e.value) {
            blockui.pageBlock(this);
            const response = await this.apiClient.workOrder.workOrderDelete({id: data.Id});
            if (response.data.IsSuccess) {
                toastr.success(this.translate('delete_successfully'));
                this.refreshGrid();
            } else {
                const errorMessage = response.data.Message ? response.data.Message : "Error";
                toastr.error(errorMessage);
            }
            blockui.unBlockPage();
        }
    }

    recordDoubleClick(args:any){
        this.$router.push(
            {
                name:'MyWorkOrder', 
                query:{
                    from:'PendingApprovalWorkOrder',
                    workOrderId: String(args.rowData.Id)
                }
            });
    }

    valueAccess(field:any,data:any,column:any) {
        return data['SpentTimeMinutes'] + ' ' + this.translate('minute');
    }

    convertToDay(field:any,data:any,column:any) {
        let res = dateFormat.formatMinute(data['Duration'],this);
        return res;
    }

    async commandClick(args:any)
    {
        if (args.target.children[0].classList.contains("e-send-back-icon")) {
            blockui.pageBlock(this);
            const res = await this.apiClient.workOrder.workOrderSendBackFromPendingApproval({workOrderId:args.rowData.Id})
            if (res.data.IsSuccess) {
                toastr.success(this.translate('sent_back'));
                this.refreshGrid();
            } else {
                const errorMessage = res.data.Message ? res.data.Message : "Error";
                toastr.error(errorMessage);
            }
            blockui.unBlockPage();
        }
        else if (args.target.children[0].classList.contains("e-approve-icon")) {
            this.selectedId = args.rowData.Id;
            this.isAction = true;
            this.orderStatusId = 5; // gelen grid response'da bu bilgi yok args.rowData.SourceId
            this.isDialogActive = true;
            await this.$nextTick();
            (<PendingApprovalWorkOrderDialog>this.$refs.pendingApprovalWorkOrderDialog).showDialog();
        }
         else if (args.target.children[0].classList.contains("e-reject-icon")) {
            this.selectedId = args.rowData.Id;
            this.orderStatusId = 8; // gelen grid response'da bu bilgi yok args.rowData.SourceId
            this.isAction = false;
            this.isDialogActive = true;
            await this.$nextTick();
            (<PendingApprovalWorkOrderDialog>this.$refs.pendingApprovalWorkOrderDialog).showDialog();
        }
        else if (args.target.children[0].classList.contains("e-preview-icon")) {
            this.$router.push(
            {
                name:'MyWorkOrder', 
                query:{
                    from:'PendingApprovalWorkOrder',
                    workOrderId: String(args.rowData.Id)
                }
            });
        }
    }
    async toolbarClick(args: ClickEventArgs){
      this.toolbarClickBase(args);
    }
}
