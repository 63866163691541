<template>
    <div class="workorder_info_wrapper row">
        <div class="col-md-4">
            <div class="workorder_info_item">
                <div class="row">
                    <div class="col-md-6">
                        <label>{{translate('id_no')}}</label>
                        <p>{{workOrder.Id}}</p>
                    </div>
                    <div class="col-md-6">
                        <label>{{translate('priority')}}</label>
                        <p>{{workOrder.PriorityType}}</p>
                    </div>
                </div>
            </div>
            <div class="workorder_info_item">
                <label v-lang.task_category></label>
                <p v-if="workOrder.OrderSourceId == workOrderSourceEnums.planned">{{workOrder.WorkCategory}}</p>
                <p v-else>{{workOrder.OrderCategoryName}}</p>
            </div>
            <div class="workorder_info_item">
                <label>
                    {{translate('status')}}
                    <button v-if="workOrder.IsEditable&&workOrderStatusChangeable"
                            type="button"
                            class="pull-right edit-btn"
                            @click="updateStatus(workOrder.Id)">
                        <i class="fa fa-edit"></i>
                    </button>
                </label>
                <div>
                    <div class="btn-group pull-right m-section__content " role="group" v-if="workOrder.IsEditable">
                        <button @click="changeStatusWorkOrderControl(workOrder, statusEnums.pendingApproval)"
                                data-container="body" data-toggle="m-tooltip" data-placement="top"
                                data-original-title="Onaya Gönderildi"
                                type="button"
                                v-if="workOrder.IsConvertibleToApprove"
                                class="btn btn-secondary" value="4"><i class="fa fa-share-square"></i></button>
                        <button @click="changeStatusWorkOrderControl(workOrder, statusEnums.cancelled)" type="button"
                                data-container="body" data-toggle="m-tooltip" data-placement="top" data-html="true"
                                data-original-title="İptal Edildi"
                                v-if="workOrder.IsConvertibleToCancelled"
                                class="btn btn-secondary" value="6"><i class="fa fa-times"></i></button>
                        <button @click="changeStatusWorkOrderControl(workOrder, statusEnums.onHold)" type="button"
                                data-toggle="m-tooltip" data-placement="top" data-original-title="Beklemede"
                                v-if="workOrder.IsConvertibleToPending"
                                class="btn btn-secondary" value="7"><i class="fa fa-pause"></i></button>
                        <dropdown :nextStatusId="statusEnums.onProgress"
                                  :workOrderId="workOrder.Id"
                                  v-if="workOrder.IsConvertibleToStarted"
                                  @closeFormAndChangeFormStatus="changeStatusButton"></dropdown>
                    </div>
                    <p class="mt-3">
                        <span class="mt-5"
                              style="color: white;"
                              :style="{backgroundColor:workOrder.ColorCode}">
                            {{workOrder.OrderStatusName}}
                            <template v-if="workOrder.OrderSubStatusName">
                                - {{workOrder.OrderSubStatusName}}
                            </template>
                        </span>
                    </p>
                </div>
                <p v-if="workOrder.OrderStatusDescription">
                    <i class="fas fa-info-circle"></i>
                    {{workOrder.OrderStatusDescription}}
                </p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="workorder_info_item">
                <label>
                    <i class="fa fa-calendar"></i>
                    {{translate('scheduled_date')}}
                </label>
                <p>{{viewDateFormat(workOrder.ScheduledTime)}}</p>
            </div>

            <div class="workorder_info_item">
                <label>
                    <i class="fa fa-clock"></i>
                    {{translate('scheduled_hour')}}
                </label>
                <p>{{viewTimeFormat(workOrder.ScheduledTime)}}</p>
            </div>
            <div class="workorder_info_item">
                <label>
                    <i class="fa fa-hourglass-half"></i>
                    {{translate('estimated_duration')}}
                </label>
                <p>{{workOrder.Duration}} {{translate('abbreviation_minute')}}</p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="workorder_info_item">
                <label>
                    <i class="fa fa-calendar"></i>
                    {{translate('actual_date')}}
                </label>
                <p>{{workOrder.StartedDate ? viewDateFormat(workOrder.StartedDate) : '-'}}</p>
            </div>
            <div class="workorder_info_item">
                <label>
                    <i class="fa fa-clock"></i>
                    {{translate('actual_time')}}
                </label>
                <p>{{workOrder.StartedDate ? viewTimeFormat(workOrder.StartedDate) : '-' }}</p>
            </div>
            <div class="workorder_info_item">
                <label>
                    <i class="fa fa-hourglass-half"></i>
                    {{translate('actual_duration')}}

                    <button v-if="workOrder.IsHistoricalRecord"
                            type="button"
                            class="pull-right edit-btn"
                            @click="toggleSpentMinutesEdit(workOrder.Id)">
                        <i class="fa fa-edit"></i>
                    </button>
                </label>

                <div v-if="workOrder.isSpentTimeEdit" class="form-group m-form__group">
                    <!-- <label><span v-lang.notify_before_days></span></label> -->
                    <div class="input-group">
                        <input type="number" class="form-control just-number" name="spentTimeMinutes" v-model="workOrder.tempSpentTimeMinutes" autocomplete="off" min="1" step="1" required/>
                        <span class="input-group-text" v-lang.minute></span>
                        <button type="submit" class="btn btn-primary" :disabled="workOrder.tempSpentTimeMinutes == workOrder.SpentTimeMinutes" @click="saveWorkOrderSpentMinutes" v-lang.save></button>
                    </div>
                </div>
                <p v-else>{{workOrder.SpentTimeMinutes}} {{(workOrder.SpentTimeMinutes||workOrder.SpentTimeMinutes==0)? translate('abbreviation_minute') : '-'}}</p>
            </div>
        </div>
        <div v-if="workOrder.OrderStatusNote" class="col-md-4">
            <div class="workorder_info_item">
                <label>
                    <i class="fas fa-file-alt"></i>
                    {{translate('status_note')}}
                </label>
                <p>{{workOrder.OrderStatusNote}}</p>
            </div>
        </div>
        <div v-if="workOrder.OrderSourceId == workOrderSourceEnums.planned" class="col-md-4">
            <div class="workorder_info_item">
                <label>
                    <i class="fas fa-file-alt"></i>
                    {{translate('work_description')}}
                </label>
                <p>{{workOrder.WorkDescription}}</p>
            </div>
        </div>
        <div v-if="workOrder.OrderSourceId != workOrderSourceEnums.planned" class="col-md-4">
            <div class="workorder_info_item">
                <label>
                    <i class="fas fa-file-alt"></i>
                    {{translate('description')}}
                </label>
                <p>{{workOrder.Description}}</p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="workorder_info_item">
                 <label>
                    <i class="fas fa-file-alt"></i>
                    {{translate('note')}}
                </label>
                <p>{{workOrder.Note}}</p>
            </div>
        </div>
        <work-order-status-modal v-if="showWorkOrderStatusModal"
                                 v-bind="params"
                                 @close="showWorkOrderStatusModal = false; params = {}"/>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import * as dateFormat from '../../../../../helpers/dateFormat';
import * as api from '../../../../../scripts/services/api';
import * as blockui from '../../../../../helpers/block-ui';
import { mixinz } from '../../../../../scripts/global/mixinz';
import WorkOrderStatusModal from '../../../../partials/modals/WorkOrderStatusModal';
import * as swal from '../../../../../helpers/swal';

import MyWorkOrderDropdown from '../../../../shared/MyWorkOrderDropdown';

export default {
  name: 'WorkOrderCard',
  mixins: [mixinz],
  props: ['workOrder', 'workOrderStatusChangeable'],
  components: {
    'work-order-status-modal': WorkOrderStatusModal,
    'dropdown': MyWorkOrderDropdown
  },
  data() {
    return {
      showWorkOrderStatusModal: false,
      params: {},
      statuses: [],
    };
  },
  mounted() {
    const self = this;
    api.getWorkOrderStatusList(null).then((res) => {
      self.statuses = res.body.Data;
    });
    this.$eventHub.$on('changeWOStatusButtons', ({ workOrderId }) => {
      if (self.workOrder.Id == workOrderId) {
        self.changeStatusButton(self.workOrder.Id);
        // todo: schupy
      }
    });
    mApp.initTooltips();
  },
  computed: {
    ...mapState({
      statusEnums: (state) => state.enums.workOrderStatus,
      workOrderSourceEnums: (state) => state.enums.workOrderSource,
    }),
  },
  methods: {
    async saveWorkOrderSpentMinutes() {
      if (!(this.workOrder.tempSpentTimeMinutes > 0)) {
        return;
      }
      const result = await this.setWorkOrderSpentMinutes(this.workOrder.Id, this.workOrder.tempSpentTimeMinutes);
      if (result) {
        Vue.set(this.workOrder, 'isSpentTimeEdit', false);
        Vue.set(this.workOrder, 'SpentTimeMinutes', this.workOrder.tempSpentTimeMinutes);
      }
    },
    async setWorkOrderSpentMinutes(workOrderId, spentMinutes) {
      const self = this;
      blockui.pageBlock(self);
      try {
        const response = await api.setWorkOrderSpentMinutes(workOrderId, spentMinutes);
        blockui.unBlockPage();
        if (response.body.IsSuccess) {
          toastr.success(self.translate('save_successfully'));
          return true;
        }
        toastr.error(response.body.Message);
        return true;
      } catch (e) {
        blockui.unBlockPage();
        toastr.error(e.message);
        return false;
      }
    },
    toggleSpentMinutesEdit() {
      Vue.set(this.workOrder, 'isSpentTimeEdit', !this.workOrder.isSpentTimeEdit);
      Vue.set(this.workOrder, 'tempSpentTimeMinutes', this.workOrder.SpentTimeMinutes);
    },
    viewDateTimeFormat(date) {
      return dateFormat.viewDateTimeFormat(date);
    },
    viewDateFormat(date) {
      return dateFormat.viewDateFormat(date);
    },
    viewRelativeDateTimeFormat(date) {
      return dateFormat.viewRelativeDateTimeFormat(date, this.now);
    },
    viewTimeFormat(date) {
      return dateFormat.viewTimeFormat(date);
    },
    updateStatus() {
      const self = this;
      blockui.blockElementPromise(self, self.$el);
      self.showModal(
        'params',
        {
          workOrderId: self.workOrder.Id,
          from: 'MyWorkOrder',
        },
        'showWorkOrderStatusModal',
        'update_work_order_status_modal',
      );
      blockui.unBlockElement(self.$el);
    },
    changeStatusWorkOrderControl(myWorkOrder, newStatusId) {
      if (myWorkOrder.OrderStatusId != newStatusId) {
        const self = this;
        const previousOrderStatus = self.statuses.find((status) => status.Id == myWorkOrder.OrderStatusId);
        const currentOrderStatus = self.statuses.find((status) => status.Id == newStatusId);
        if (currentOrderStatus.Id != self.statusEnums.onHold) {
          swal.check(self, self.translate('are_you_sure'), self.translate('ask_status_update_from_to').formatString(myWorkOrder.WorkName, previousOrderStatus.Name, currentOrderStatus.Name)).then((e) => {
            if (e.value) {
              self.changeStatusWorkOrder(myWorkOrder, currentOrderStatus);
            }
          });
        } else {
          self.changeStatusWorkOrder(myWorkOrder, currentOrderStatus);
        }
      }
    },
    changeStatusWorkOrder(selectedWorkOrder, currentOrderStatus) {
      const self = this;
      blockui.pageBlock(self);
      this.$globalApiClient.workOrder.workOrderUpdateStatus({
        Id: selectedWorkOrder.Id,
        OrderStatusId: currentOrderStatus.Id,
      }).then((response) => {
        if (response.data.IsSuccess) {
          toastr.success(self.translate('status_update_work_order_successfully').formatString(selectedWorkOrder.WorkName, selectedWorkOrder.OrderStatusName, currentOrderStatus.Name));
          Vue.getMyWorkOrder();
        } else {
          toastr.error(response.data.Message);
        }
        blockui.unBlockPage();
      }, (error) => {
        blockui.unBlockPage();
      });
    },
    changeStatusButton(workOrderId) {
      api.getWorkOrderConvertibleStatusesByWorkOrderId(workOrderId).then((response) => {
        if (response.ok) {
          this.workOrder.IsConvertibleToApprove = response.body.IsConvertibleToApprove;
          this.workOrder.IsConvertibleToPending = response.body.IsConvertibleToPending;
          this.workOrder.IsConvertibleToCancelled = response.body.IsConvertibleToCancelled;
          this.workOrder.IsConvertibleToStarted = response.body.IsConvertibleToStarted;
        }
      });
    },
  },
  beforeDestroy() {
    this.$eventHub.$off('changeWOStatusButtons');
  },
};
</script>

<style scoped>

</style>
