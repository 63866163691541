<template>
    <form>
        <br>
        <h6 v-lang.vendor_information></h6>
        <br>
        <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-5">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group"
                             :class="{ 'form-group--error': $v.selectedContract.VendorId.$error&&validation,'has-danger': $v.selectedContract.VendorId.$error&&validation}">
                            <label><span v-lang.vendor_name></span><span class="red">*</span></label>
                            <multiselect v-model="selectedVendor"
                                         :options="vendorList"
                                         @select="onSelectedVendorChanged"
                                         @remove="removeVendor"
                                         v-bind="selectOptions"
                                         label="Name"
                                         track-by="Id"
                                         :disabled="!IsEditable">
                            </multiselect>
                            <template v-if="$v.selectedContract.VendorId.$error&&validation">
                                <div v-if="$v.selectedContract.VendorId.$error&&validation"
                                     class="form-control-feedback error"
                                     v-lang.this_field_is_required></div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" id="vendor_users_select">
                        <div class="form-group">
                            <label><span v-lang.authorized_staff></span></label>
                            <cfm-multiselect v-model="selectedContract.ContractPersonels"
                                        value-track-by="UserId"
                                        value-label="NameSurname"
                                        value-track-by-object-name="User"
                                        :multiple="true"
                                        :list="vendorUserList"
                                        :disabled="!IsEditable"
                                        label="Text"
                                        track-by="Value">
                                <template v-slot:postfix>
                                    <div class="btn-procedure-plus">
                                        <button class="btn btn-success" type="button" @click="addUser()"
                                                v-if="IsEditable">
                                            <i class="fa fa-plus"></i></button>
                                    </div>
                                </template>
                            </cfm-multiselect>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-12 col-xl-7">
                <div class="row">
                    <div class="col-md-2">
                        <label><span v-lang.working_hours></span></label>
                        <div class="row">
                            <div class="col-md-5">
                                <div class="m-radio-list form-group">
                                    <label class="m-radio">
                                        <input v-model="selectedWorkDayPreset" type="radio" id="radio_1" value="1"
                                               :disabled="!IsEditable">
                                        <label for="radio_1"><span v-lang.customize_working_hours></span></label>
                                        <span></span>
                                    </label>
                                    <label class="m-radio">
                                        <input v-model="selectedWorkDayPreset" type="radio" id="radio_2" value="2"
                                               :disabled="!IsEditable">
                                        <label for="radio_2"><span v-lang.weekdays_only></span></label>
                                        <span></span>
                                    </label>
                                    <label class="m-radio">
                                        <input v-model="selectedWorkDayPreset" type="radio" id="radio_3" value="3"
                                               :disabled="!IsEditable">
                                        <label for="radio_3"><span v-lang.seven_twenty_four></span></label>
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group m-form__group">
                                    <label class="m-checkbox">
                                        <input type="checkbox" v-model="selectedContract.IncludePublicHolidays"
                                               :disabled="!IsEditable"
                                               id="includePublicHoliday">
                                        <label for="includePublicHoliday"> <span>{{ this.translate('including_public_holidays')}}</span></label>
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-10" v-if="selectedWorkDayPreset != enumWorkDayPreset.fullTime">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group m-form__group"
                                     :class="{ 'form-group--error': $v.selectedContract.ContractWorkDays.$error&&validation,'has-danger': $v.selectedContract.ContractWorkDays.$error&&validation}">
                                    <div v-for='(day, i) in  $v.selectedContract.ContractWorkDays.$each.$iter'>
                                        <div class="row" v-if="day.$model">
                                            <div class="col-2 col-md-2 col-xs-2" style="margin: auto">
                                                <label style="margin: auto" class="m-checkbox" :key="i">
                                                    <input type="checkbox" :name="'checkbox_'+day.$model.DayOfWeekId"
                                                           :id="day.$model.DayOfWeekId"
                                                           :value="day.$model.DayOfWeekId"
                                                           v-model="day.$model.IsSelected"
                                                           :disabled="selectedWorkDayPreset == enumWorkDayPreset.fullTime || selectedWorkDayPreset==enumWorkDayPreset.workDays || !IsEditable">
                                                    <label :for="day.$model.DayOfWeekId"> <span>{{day.$model.DayOfWeekName}}</span></label>
                                                    <span></span>
                                                </label>

                                            </div>
                                            <div class="col-8 col-md-8 col-xs-8">
                                                <div class="row">
                                                    <div class="col-5 col-md-5 col-xs-5 col-sm-5">
                                                        <input style="padding: 0" type="time" class="input-type-time"
                                                               :key="i"
                                                               :value="day.$model.StartTime"
                                                               @input="setTimeProperty(day.$model,'StartTime',$event)"
                                                               :disabled="selectedWorkDayPreset == enumWorkDayPreset.fullTime ||!IsEditable"
                                                               :class="{'error-class':!day.time&&validation||!day.checkDay&&validation}">
                                                    </div>
                                                    <div class="col-1 col-md-1 col-sm-1">
                                                <span style="position: absolute; left: 25%; top: 25%"><i
                                                        class="b-fa b-fa-minus"></i></span>
                                                    </div>
                                                    <div class="col-5 col-md-5 col-xs-5 col-sm-5">
                                                        <input style="padding: 0" type="time" class="input-type-time"
                                                               :key="i"
                                                               :value="day.$model.EndTime"
                                                               @input="setTimeProperty(day.$model,'EndTime',$event)"
                                                               :disabled="selectedWorkDayPreset == enumWorkDayPreset.fullTime||!IsEditable"
                                                               :class="{'error-class':!day.time&&validation||!day.checkDay&&validation}">
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                    v-if="!day.time&&validation">
                                                <div v-if="!day.time&&validation"
                                                     class="form-control-feedback error mt-3"
                                                     v-lang.start_time_cannot_be_after_end_time
                                                ></div>
                                            </div>
                                            <div
                                                    v-if="!day.checkDay&&validation">
                                                <div v-if="!day.checkDay&&validation"
                                                     class="form-control-feedback error"
                                                     v-lang.this_field_is_required></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <user-overlay :selectedUser="selectedUser" :isUpdate="isUpdate" v-if="showUserOverlayModal"
                              @close="showUserOverlayModal = false; selectedUser = {};"/>
            </div>
        </div>
    </form>
</template>

<script>
import Vue from 'vue';
import {
  required, minValue, requiredIf, helpers,
} from 'vuelidate/lib/validators';
import * as api from '../../../scripts/services/api';
import { mixinz } from '../../../scripts/global/mixinz';
import UserOverlay from '../user/UserOverlay';
import CFMMultiselect from '../CFMMultiselect';
import * as blockui from '../../../helpers/block-ui';

import { checkDay, time } from '../../../scripts/pages/Contract';

export default {
  props: ['value', 'isChild', 'validation', 'IsEditable'],
  mixins: [mixinz],
  components: {
    'user-overlay': UserOverlay,
    'cfm-multiselect': CFMMultiselect,
  },
  data() {
    return {
      selectedContract: {},
      unitList: [],
      vendorList: [],
      vendorUserList: [],
      selectedCheckbox: [],
      selectedWorkDayPreset: 1,
      checkboxDayList: [],
      checkedCheckbox: [],
      selectedUser: {},
      userType: [],
      isUpdate: false,
      showUserOverlayModal: false,
      enumWorkDayPreset: {
        custom: 1,
        workDays: 2,
        fullTime: 3,
      },
      isEditUser: false,
    };
  },
  validations() {
    return {
      selectedContract: {
        VendorId: {
          required,
        },
        ContractWorkDays: {
          $each: {
            checkDay,
            time,
          },
        },
      },
    };
  },
  methods: {
    setTimeProperty(target, property, e) {
      Vue.set(target, property, e.target.value);
    },
    async addUser() {
      const self = this;
      if (self.selectedContract.VendorId) {
        await api.getUserTypes().then((res) => {
          self.userType = res.body;
        });
        self.selectedUser = {
          VendorId: self.selectedContract.VendorId,
          UserTypeId: self.userType.find((x) => x.Text == 'Vendor').Value,
        };
      }

      self.showUserOverlayModal = true;
      self.$nextTick(() => {
        const modalContainer = window.$('#user_overlay_modal');
        modalContainer.find('form').data('type', 'update');
        self.isUpdate = false;
        modalContainer.modal({
          backdrop: 'static',
          show: true,
        });
      });
    },
    async fetchVendorUsers(selectedVendorId) {
      // blockui.blockElementPromise(this, '#vendor_users_select');
      blockui.pageBlock(this);
      await api.getVendorUser(selectedVendorId).then((response) => {
        this.vendorUserList = response.body;
        // blockui.unBlockElement('#vendor_users_select');
        blockui.unBlockPage();
      });
    },
    onSelectedVendorChanged(selectedVendor) {
      this.selectedContract.ContractPersonels = [];
      this.fetchVendorUsers(selectedVendor.Id);
    },
    removeVendor() {
      this.selectedContract.ContractPersonels = [];
    },
    arrangeContractWorkDays() {
      // custom days
      if (this.selectedWorkDayPreset == this.enumWorkDayPreset.custom) {
        Vue.set(this.selectedContract, 'ContractWorkDays', this.checkboxDayList.map((x) => ({
          ...x,
          IsSelected: false,
        })));

        if (this.selectedContract.ContractWorkDays) {
          this.selectedContract.ContractWorkDays.forEach((item) => {
            Vue.set(item, 'StartTime', '08:00:00');
            Vue.set(item, 'EndTime', '18:00:00');
          });
        }
      }
      // H.içi
      if (this.selectedWorkDayPreset == this.enumWorkDayPreset.workDays) {
        Vue.set(this.selectedContract, 'ContractWorkDays', this.checkboxDayList.map((x) => ({
          ...x,
          IsSelected: true,
        })).filter((x) => x.WorkDay));

        if (this.selectedContract.ContractWorkDays) {
          this.selectedContract.ContractWorkDays.forEach((item) => {
            Vue.set(item, 'StartTime', '09:00:00');
            Vue.set(item, 'EndTime', '18:00:00');
          });
        }
      }
      // 7/24
      if (this.selectedWorkDayPreset == this.enumWorkDayPreset.fullTime) {
        Vue.set(this.selectedContract, 'ContractWorkDays', this.checkboxDayList.map((x) => ({
          ...x,
          IsSelected: true,
        })));

        if (this.selectedContract.ContractWorkDays) {
          this.selectedContract.ContractWorkDays.forEach((item) => {
            Vue.set(item, 'StartTime', '00:00:00');
            Vue.set(item, 'EndTime', '12:00:00');
          });
        }
      }
    },
  },
  computed: {
    selectedVendor: {
      get() {
        const self = this;
        return self.selectGet('vendorList', 'selectedContract.VendorId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedContract.VendorId', 'Id', newValue);
      },
    },
  },
  mounted() {
    const self = this;
    this.selectedContract = this.value;
    this.$v.$touch();
    api.getVendorList().then((response) => {
      this.vendorList = response.body.Data;
    });
    if (this.selectedContract && this.selectedContract.VendorId > 0) {
      this.fetchVendorUsers(this.selectedContract.VendorId);
    }

    api.getDayOfWeeks().then((res) => {
      blockui.pageBlock(self);
      self.checkboxDayList = res.body.map((x) => ({
        DayOfWeekId: x.Value,
        DayOfWeekName: x.Text,
        WorkDay: x.Value >= 2 && x.Value <= 6,
      }));

      Vue.set(self.selectedContract, 'ContractWorkDays',
        self.checkboxDayList.map((checkboxDay) => {
          const contractWorkDay = self.selectedContract
                            && self.selectedContract.ContractWorkDays
                            && self.selectedContract.ContractWorkDays.find((contractWorkDay) => contractWorkDay.DayOfWeekId == checkboxDay.DayOfWeekId);
          if (contractWorkDay) {
            return {
              ...checkboxDay,
              ...contractWorkDay,
              IsSelected: true,
            };
          }
          return {
            StartTime: '08:00:00',
            EndTime: '18:00:00',
            ...checkboxDay,
            IsSelected: false,
          };
        }));
      blockui.unBlockPage(self);
    });
    self.$eventHub.$on('contractAddedNewUser', async (data) => {
      if (data.VendorId != undefined && data.UserId != undefined) {
        const vendorId = data.VendorId;
        await self.fetchVendorUsers(vendorId);
        const selectedUser = this.vendorUserList.find((x) => x.Value == data.UserId);
        self.selectedContract.ContractPersonels.push({ UserId: selectedUser.Value });
      }
    });
  },
  watch: {
    selectedContract(val) {
      this.$emit('input', val);
    },
    value(newValue) {
      this.selectedContract = newValue;
    },
    selectedWorkDayPreset(newValue) {
      this.arrangeContractWorkDays(newValue);
    },
  },
  beforeDestroy() {
    this.$eventHub.$off('contractAddedNewUser');
  },
};
</script>

<style>
    .btn-procedure-plus {
        position: absolute;
        right: 0 !important;
        top: 25px !important;
    }
</style>

<style scoped>
    .input-type-time {
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        outline: none;
        display: block;
        width: 100%;
        padding: 7px;
        border: none;
        border-bottom: 1px solid #ddd;
        background: transparent;
        margin-bottom: 3px;
        font: 13px Arial, Helvetica, sans-serif;
        color: #576472;
        height: 35px;
    }

    span {
        color: #576472;
    }

    form .error-class {
        border-color: #f4516c;
        color: #f4516c;
    }
</style>
