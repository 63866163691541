export default {
  home_page: 'Home Page',
  title: 'Title',
  inventory: 'Inventory',
  inventory_list: 'Inventory List',
  add_inventory: 'Add Inventory',
  login: 'Login',
  logout: 'Logout',
  quick_actions: 'Quick Actions',
  shortcuts: 'Shortcuts',
  generate_report: 'Generate Report',
  add_new_event: 'Add New Event',
  create_new_task: 'Create New Task',
  completed_tasks: 'Completed Task',
  save: 'Save',
  clear: 'Clear',
  save_continue: 'Save & Continue',
  location: 'Location',
  building_floor: 'Building & Floor',
  room_capacity_equipment: 'Room & Capacity Equipment',
  room_capacity_eq: 'Room & Capacity Eq.',
  select_capacity_eq: 'Select Capacity Eq.',
  select: 'Select',
  back: 'Back',
  submit: 'Submit',
  not_found_page: 'Not found page',
  sign_in: 'Sign In',
  cancel: 'Cancel',
  apply: 'Apply',
  forgotten_password: 'Forgotten Password',
  email: 'E-Mail',
  parameters: 'Parameters',
  continent: 'Continent',
  country: 'Country',
  city: 'City',
  district: 'District',
  continents: 'Continents',
  countries: 'Countries',
  cities: 'Cities',
  districts: 'Districts',
  add_continent: 'Add Continent',
  edit_continent: 'Edit Continent',
  add_country: 'Add Country',
  edit_country: 'Edit Country',
  add_cities: 'Add City',
  edit_cities: 'Edit City',
  add_district: 'Add District',
  edit_district: 'Edit District',
  please_select: 'Please Select',
  deleted: 'Deleted',
  are_you_sure: 'Are you sure?',
  are_you_sure_delete: 'Are you sure want to delete',
  yes: 'Yes',
  no: 'No',
  sort: 'Sort',
  please_select_continent: 'Please Select Continent',
  please_select_country: 'Please Select Country',
  please_select_city: 'Please Select City',
  please_select_district: 'Please Select District',
  please_select_building: 'Please Select Building',
  please_select_floor: 'Please Select Floor',
  please_select_room: 'Please Select Room',
  please_select_training_type: 'Please Select Training Type',
  please_select_capacity_equipment: 'Please Select Capacity Equipment',
  please_select_work_category: 'Please Select Work Category',
  please_select_capacity_type: 'Please Select Capacity Type',
  code: 'Code',
  devices: 'Devices',
  selected_location: 'Selected Location',
  language: 'Language',
  locations: 'Locations',
  building: 'Building',
  floor: 'Floor',
  add_building: 'Add Building',
  edit_building: 'Edit Building',
  building_name: 'Building Name',
  floor_count: 'Floor Count',
  floor_name: 'Floor Name',
  reset: 'Reset',
  add_room: 'Add Room',
  edit_room: 'Edit Room',
  room_name: 'Room Name',
  device: 'Device',
  add_device: 'Add Device',
  edit_device: 'Edit Device',
  device_name: 'Device Name',
  device_count: 'Device Count',
  device_types: 'Device Types',
  device_type: 'Device Type',
  add_device_type: 'Add Device Type',
  edit_device_type: 'Edit Device Type',
  filter: 'Filter',
  show_more: 'Show More',
  show_less: 'Show Less',
  more: 'More',
  area_size: 'Area Size (m²)',
  room: 'Room',
  rooms: 'Rooms',
  room_type: 'Room Type',
  room_sub_type: 'Room Sub Type',
  create_new: 'Create New',
  user_roles: 'Staff Roles',
  user_role: 'Staff Role',
  add_user_role: 'Add Staff Role',
  edit_user_role: 'Edit Staff Role',
  role_groups: 'Role Groups',
  brands: 'Brands',
  brand: 'Brand',
  add_brand: 'Add Brand',
  edit_brand: 'Edit Brand',
  training_types: 'Training Types',
  training_type: 'Training Type',
  add_training_type: 'Add Training Type',
  edit_training_type: 'Edit Training Type',
  trainings: 'Trainings',
  training: 'Training',
  add_training: 'Add Training',
  edit_training: 'Edit Training',
  issue_date: 'Issue Date',
  expire_date: 'Expire Date',
  capacity_equipments: 'Capacity Equipments',
  capacity: 'Capacity',
  equipments: 'Equipments',
  capacity_equipment: 'Capacity Equipment',
  c_equipment: 'C.Equipment',
  add_capacity_equipment: 'Add Capacity Equipment',
  edit_capacity_equipment: 'Edit Capacity Equipment',
  capacity_equipment_name: 'Capacity Equipment Name',
  capacity_equipment_count: 'Capacity Equipment Count',
  users: 'Staff',
  user: 'Staff',
  add_user: 'Add Staff',
  edit_user: 'Edit Staff',
  roles: 'Roles',
  month_0: 'January',
  month_1: 'February',
  month_2: 'March',
  month_3: 'April',
  month_4: 'May',
  month_5: 'June',
  month_6: 'July',
  month_7: 'August',
  month_8: 'September',
  month_9: 'October',
  month_10: 'November',
  month_11: 'December',
  models: 'Models',
  model: 'Model',
  add_model: 'Add Model',
  edit_model: 'Edit Model',
  please_select_brand: 'Please Select Brand',
  work_order_types: 'Work Order Types',
  work_order_type: 'Work Order Type',
  add_work_order_type: 'Add Work Order Type',
  edit_work_order_type: 'Edit Work Order Type',
  work_categories: 'Work Categories',
  work_category: 'Work Category',
  add_work_category: 'Add Work Category',
  edit_work_category: 'Edit Work Category',
  jobs: 'Jobs',
  job: 'Job',
  add_job: 'Add Job',
  edit_job: 'Edit Job',
  qualifications: 'Qualifications',
  qualification: 'Qualification',
  add_qualification: 'Add Qualification',
  edit_qualification: 'Edit Qualification',
  qualification_types: 'Qualification Types',
  qualification_type: 'Qualification Type',
  add_qualification_type: 'Add Qualification Type',
  edit_qualification_type: 'Edit Qualification Type',
  please_select_qualification_type: 'Please Select Qualification Type',
  infrastructures: 'Infrastructures',
  infrastructure: 'Infrastructure',
  add_infrastructure: 'Add Infrastructure',
  edit_infrastructure: 'Edit Infrastructure',
  infrastructure_types: 'Infrastructure Types',
  infrastructure_type: 'Infrastructure Type',
  add_infrastructure_type: 'Add Infrastructure Type',
  edit_infrastructure_type: 'Edit Infrastructure Type',
  please_select_infrastructure: 'Please Select Infrastructure',
  capacity_types: 'Capacity Types',
  capacity_type: 'Capacity Type',
  add_capacity_type: 'Add Capacity Type',
  edit_capacity_type: 'Edit Capacity Type',
  please_select_infrastructure_type: 'Please Select Infrastructure Type',
  product_families: 'Product Families',
  product_family: 'Product Family',
  add_product_family: 'Add Product Family',
  edit_product_family: 'Edit Product Family',
  shift: 'Shift',
  shift_calendar: 'Shift Calendar',
  shift_type: 'Shift Type',
  shift_types: 'Shift Types',
  add_shift_type: 'Add Shift Type',
  edit_shift_type: 'Edit Shift Type',
  start_time: 'Start Time',
  end_time: 'End Time',
  color: 'Color',
  name: 'Name',
  surname: 'Surname',
  password: 'Password',
  phone_number: 'Phone Number',
  source_count: 'Source Count',
  output_count: 'Output Count',
  source: 'Source',
  output: 'Output',
  serial_number: 'Serial Number',
  production_year: 'Production Year',
  date_of_installation: 'Date of Installation',
  end_of_guarantee_date: 'End of Guarantee Date',
  next: 'Next',
  update_calendar: 'Update Calendar',
  vendors: 'Vendors',
  vendor: 'Vendor',
  add_vendor: 'Add Vendor',
  edit_vendor: 'Edit Vendor',
  user_types: 'Staff Types',
  user_type: 'Staff Type',
  add_user_type: 'Add Staff Type',
  edit_user_type: 'Edit Staff Type',
  staff: 'Staff',
  please_select_room_type: 'Please Select Room Type',
  please_select_room_sub_type: 'Please Select Room Sub Type',
  shift_calendar_updated: 'Shift calendar updated',
  organizations: 'Organizations',
  organization: 'Organization',
  add_organization: 'Add Organization',
  edit_organization: 'Edit Organization',
  loading: 'Loading',
  first_step: 'First Step',
  second_step: 'Second Step',
  third_step: 'Third Step',
  shift_calendar_error: 'Users cannot be assigned to started or past shifts.',
  maintenance_plan: 'Maintenance Plan',
  maintenance_plans: 'Maintenance Plans',
  add_maintenance_plan: 'Add Maintenance Plan',
  edit_maintenance_plan: 'Edit Maintenance Plan',
  maintenance_count: 'Maintenance Count',
  maintenance_duration: 'Maintenance Duration',
  start_date: 'Start Date',
  end_date: 'End Date',
  notes: 'Notes',
  timeline: 'Timeline',
  maintenance_type: 'Maintenance Type',
  shift_exist_error_message: 'This shift is defined',
  shift_not_selected_error_message: 'Please select a shift',
  maintenance: 'Maintenance',
  plans: 'Plans',
  maintenance_periods: 'Maintenance Periods',
  maintenance_period: 'Maintenance Period',
  add_maintenance_period: 'Add Maintenance Period',
  edit_maintenance_period: 'Edit Maintenance Period',
  time: 'Time',
  unit: 'Unit',
  units: 'Units',
  unit_definition: 'Unit Definition',
  add_unit: 'Add Unit',
  edit_unit: 'Edit Unit',
  unit_type: 'Unit Type',
  unit_sign: 'Symbol',
  room_types: 'Room Types',
  add_room_type: 'Add Room Type',
  edit_room_type: 'Edit Room Type',
  room_sub_types: 'Room Sub Types',
  add_room_sub_type: 'Add Room Sub Type',
  edit_room_sub_type: 'Edit Room Sub Type',
  works: 'Works',
  work: 'Work',
  add_work: 'Add Work',
  edit_work: 'Edit Work',
  work_count: 'Work Count',
  please_select_date_range: 'Please Select Date Range',
  today: 'Today',
  tomorrow: 'Tomorrow',
  this_week: 'This Week',
  two_week: 'Two Week',
  this_month: 'This Month',
  this_year: 'This Year',
  custom_range: 'Custom Range',
  search: 'Search',
  please_select_campus: 'Please Select Campus',
  campuses: 'Campuses',
  campus: 'Campus',
  add_campus: 'Add Campus',
  edit_campus: 'Edit Campus',
  maintenance_plan_calendar: 'Maintenance Plan Calendar',
  please_select_job: 'Please Select Job',
  please_select_role: 'Please Select Role',
  please_select_user_type: 'Please Select Staff Type',
  please_select_vendor: 'Please Select Vendor',
  please_select_organization: 'Please Select Organization',
  please_select_qualification: 'Please Select Qualification',
  please_select_training: 'Please Select Training',
  please_select_order_status: 'Please Select Status',
  edit: 'Edit',
  delete: 'Delete',
  dashboard: 'Dashboard',
  executive_dashboards: 'Executive Dashboards',
  calendar: 'Calendar',
  topologic_view: 'Topologic View',
  work_order: 'Work Order',
  work_orders: 'Work Orders',
  orders: 'Orders',
  cases: 'Cases',
  alarms: 'Alarms',
  maintenance_and_operations: 'Maintenance & Operations',
  preventive_maintenance_schedule: 'Preventive Maintenance List',
  daily_checks: 'Daily Checks',
  organizational_chart: 'Organizational Chart',
  staff_list: 'Staff List',
  roles_and_responsibilities: 'Roles and Responsibilities',
  assets: 'Assets',
  buildings: 'Buildings',
  library: 'Library',
  procedures: 'Procedures',
  mop: 'MoP',
  sop: 'SoP',
  eop: 'EoP',
  t_and_c_reports: 'T&C Reports',
  l1: 'L1',
  l2: 'L2',
  l3: 'L3',
  l4: 'L4',
  electrical: 'Electrical',
  mechanical: 'Mechanical',
  air_to_air: 'Air to Air',
  chiller: 'Chiller',
  l5: 'L5',
  settings: 'Settings',
  staffing: 'Staffing',
  constrains: 'Constrains',
  asset: 'Asset',
  integrations: 'Integrations',
  maximo: 'Maximo',
  maintenance_plan_calendar_updated: 'Maintenance plan updated.',
  organization_scheme: 'Organization Scheme',
  inventory_added: 'Inventory Added',
  description: 'Description',
  position: 'Position',
  new: 'New',
  nine_new: '7 New',
  user_notification: 'User Notification',
  alerts: 'Alerts',
  events: 'Events',
  logs: 'Logs',
  notifications_1: '3 preventive maintenance not scheduled.',
  notifications_1_time: 'Just now',
  notifications_2: 'Daily checks completed.',
  notifications_2_time: '13 mins',
  notifications_3: '2 maintenance changed status to delayed.',
  notifications_3_time: '20 mins',
  notifications_4: '1 preventive maintenance not scheduled.',
  notifications_4_time: '1 hr',
  notifications_4_status: 'Urgent',
  notifications_5: 'Approaching 2 unassigned work.',
  notifications_5_time: 'Just now',
  notifications_6: '2 work delayed.',
  notifications_6_time: '20 mins',
  notifications_7: 'Not enough staff for Monday',
  notifications_7_time: '5 hrs',
  all_caught_up: 'All caught up!',
  no_new_logs: 'No new logs.',
  transform_to_work_order: 'Transform To Work Order',
  add_work_order: 'Add Work Order',
  edit_work_order: 'Edit Work Order',
  order_status: 'Status',
  save_successfully: 'Save successfully',
  update_successfully: 'Successfully updated',
  delete_successfully: 'Successfully deleted',
  role_qualifications: 'Add Role Qualifications',
  schedules: 'Schedules',
  schedule: 'Schedule',
  schedule_name: 'Schedule Name',
  add_schedule: 'Add Schedule',
  edit_schedule: 'Edit Schedule',
  export_excel: 'Export excel',
  one_time: 'One Time',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  monthly_days: 'Monthly Days',
  monthly_week_days: 'Monthly Week Days',
  duration: 'Duration',
  expected_duration: 'Expected Duration',
  convenience: 'Convenience',
  schedule_duration: 'Expected Duration (Minute)',
  repeat_every_n_day: 'Recurrence (Days)',
  repeat_every_n_week: 'Recurrence (Weeks)',
  day_name_1: 'Monday',
  day_name_2: 'Tuesday',
  day_name_3: 'Wednesday',
  day_name_4: 'Thursday',
  day_name_5: 'Friday',
  day_name_6: 'Saturday',
  day_name_7: 'Sunday',
  month_name_1: 'January',
  month_name_2: 'February',
  month_name_3: 'March',
  month_name_4: 'April',
  month_name_5: 'May',
  month_name_6: 'June',
  month_name_7: 'July',
  month_name_8: 'August',
  month_name_9: 'September',
  month_name_10: 'October',
  month_name_11: 'November',
  month_name_12: 'December',
  last: 'Last',
  days: 'Days',
  weeks: 'Weeks',
  week: 'Week',
  months: 'Months',
  ordinal_numbers_1: 'First',
  ordinal_numbers_2: 'Second',
  ordinal_numbers_3: 'Third',
  ordinal_numbers_4: 'Fourth',
  type: 'Type',
  procedure: 'Procedure',
  add_procedure: 'Add Procedure',
  edit_procedure: 'Edit Procedure',
  file: 'File',
  please_select_product_family: 'Please Select Product Family',
  file_is_not_suitable: 'The file you selected is not suitable',
  max_file_size: 'Max File Size',
  no_result: 'No result',
  session_timeout_notification: 'Session Timeout Notification',
  your_session_is_about_to_expire: 'Your Session Is About To Expire.',
  redirecting_in_seconds: 'Redirecting in {timer} seconds.',
  stay_connected: 'Stay Connected',
  current_file: 'Current File',
  personal_information: 'Personal Information',
  organization_qualification: 'Organization & Qualification',
  constraints: 'Constraints',
  constraint: 'Constraint',
  add_constraint: 'Add Constraint',
  edit_constraint: 'Edit Constraint',
  please_select_constraint_type: 'Please Select Constraint Type',
  select_all: 'Select All',
  begin_date: 'Begin Date',
  compulsory: 'Compulsory',
  all: 'All',
  training_material: 'Training Material',
  training_materials: 'Training Materials',
  add_training_material: 'Add Training Material',
  edit_training_material: 'Edit Training Material',
  training_assessments: 'Training Assessments',
  training_assessment: 'Training Assessment',
  add_training_assessment: 'Add Training Assessment',
  edit_training_assessment: 'Edit Training Assessment',
  certificated_training: 'Certificated Training',
  training_duration: 'Duration (Hour)',
  raci_matrix: 'RACI Matrix',
  responsible: 'Responsible',
  accountable: 'Accountable',
  consulted: 'Consulted',
  informed: 'Informed',
  procedure_type: 'Procedure Type',
  remember_me: 'Remember Me',
  forgot_password: 'Forgot Password',
  enter_email_reset_password: 'Enter your email to reset your password',
  main_work: 'Main Work',
  report_role: 'Report Role',
  predecessor_inheritances: 'Predecessor Inheritances',
  successor_inheritances: 'Successor Inheritances',
  please_fill_all_required_fields: 'Please Fill All Required Fields',
  add_new_work_to_work_order: 'Add New Work To Work Order',
  accepted_file_extensions: 'Accepted File Extensions',
  min_staff_calculation: 'Min. Staff Calculation',
  minimum_staff_calculation: 'Minimum Staff Calculation',
  min_staff_section_one_text: 'Determine Net Available Work Hours',
  min_staff_section_two_text: 'Calculate Average Turnover Rate',
  min_staff_section_three_text: 'Estimate Staffing Needed for Coverage Positions',
  min_staff_section_four_text: 'Calculate Hourly Processing Capability',
  min_staff_section_five_text: 'Estimate Staffing Needed for Volume-influenced Positions',
  first_section_first_element: 'Total hours for one full time employee (yearly)',
  first_section_second_element: 'Average vacation and holiday leave (total hours)',
  first_section_third_element: 'Average sick leave (total hours)',
  first_section_fourth_element: 'Average personal leave (total hours)',
  first_section_fifth_element: 'Average training leave (total hours)',
  first_section_sixth_element: 'Average military, FMLA leave, etc. (total hours)',
  first_section_seventh_element: 'Average lunch and break (total hours)',
  first_section_eighth_element: 'Average other (meetings,special assignments, etc.)',
  second_section_first_element: 'Number of employees at the highest staffing level for that year',
  second_section_second_element: 'Number of new hires that failed to complete the probationary period',
  second_section_third_element: 'Number of experienced employees who left for any reason',
  third_section_first_element: 'Total number of consoles that need to be covered for this position',
  third_section_second_element: 'Number of hours per day that need to be covered',
  third_section_third_element: 'Number of days per week that need to be covered',
  third_section_fourth_element: 'Number of weeks per year that need to be covered',
  fourth_section_first_element: 'Average maintenance duration minutes',
  fourth_section_second_element:
        'Average maintenance completion time (in minutes,'
        + ' this includes time for data entry, address verification, etc.)',
  total_hours_needing_coverage: 'Total hours needing coverage',
  average_processing_time: 'Average Processing Time',
  average_hourly_processing_capability: 'Average Hourly Processing Capability',
  is_qualified: 'Qualified',
  is_trained: 'Trained',
  is_suitable_role: 'Role',
  is_suitable_shift: 'Shift',
  total_unavailable_time: 'Total unavailable time',
  net_available_work_hours: 'Net available work hours',
  please_select_year: 'Please select year',
  turnover_rate: 'Turnover Rate',
  average_turnover: 'Average Turnover',
  full_time_equivalent_base_estimate: 'Full Time Equivalent',
  full_time_equivalent_required_to_accommodate_turnover: 'Full Time Equivalent required to accommodate turnover',
  retention_rate: 'Retention Rate',
  fifth_section_first_element: 'Total Maintenance Count',
  fifth_section_second_element: 'Average Processing Time',
  hourly_processing_capability: 'Hourly Processing Capability',
  workload_in_hours: 'Workload in hours',
  agent_occupancy_rate: 'Agent Occupancy rate',
  availability_per_person: 'True Availability per person',
  change_password: 'Change Password',
  current_password: 'Current Password',
  new_password: 'New Password',
  new_password_repeat: 'New Password Repeat',
  password_changed: 'Password Changed',
  there_is_no_work_order: 'There is no work order at {date}.',
  there_is_n_work_order: 'There is {count} work order at {date}.',
  you_will_be_redirected: 'You will be redirected.',
  lower_than_n_work_order: 'Work order count lower than {min}.',
  upper_than_n_work_order: 'Work order count upper than {max}.',
  inner_than_n_work_order: 'Work order count between {down} and {up}.',
  amount: 'Amount',
  work_order_density: 'Work Order Density',
  shift_staff_amount: 'Shift Staff Amount',
  work_order_statuses: 'Work Order Statuses',
  work_order_status: 'Work Order Status',
  password_reset_email_sent: 'Password reset email sent',
  training_assessment_types: 'Training Assessment Types',
  training_assessment_type: 'Training Assessment Type',
  add_training_assessment_type: 'Add Training Assessment Type',
  edit_training_assessment_type: 'Edit Training Assessment Type',
  evaluation_score: 'Evaluation Score',
  passing_score: 'Passing Score',
  content: 'Content',
  purpose: 'Purpose',
  material_name: 'Material Name',
  please_wait_for_uploads: 'Please Wait For File Uploads.',
  please_select_url_or_file: 'Please Select a File or URL.',
  please_select_current_url: 'Please Select a Current URL',
  training_instructor_role: 'Training Instructor Role',
  training_method: 'Training Method',
  user_training_operations: 'User Training Operations',
  user_assessment_operations: 'User Assessment Operations',
  training_and_assessment_operations: 'Training And Assessment Operations',
  user_trainings: 'User Trainings',
  user_training: 'User Training',
  user_not_found: 'Not Found Any Users',
  edit_user_training: 'Edit User Training',
  add_user_training: 'Add User Training',
  user_assessment: 'User Assessment',
  user_assessments: 'User Assessments',
  edit_user_assessment: 'Edit User Assessment',
  add_user_assessment: 'Add User Assessment',
  no_qualifications: 'There Is No Qualifications In This Role',
  qualifications_from_role: 'Qualifications From Role',
  deleted_successfully: 'Deleted Successfully',
  please_select_operation: 'Please Select an Operation',
  add_assessment: 'Add Assessment',
  assessment_score: 'Assessment Score',
  assessment_date: 'Assessment Date',
  assessment: 'Assessment',
  work_groups: 'Work Groups',
  work_group: 'Work Group',
  add_work_group: 'Add Work Group',
  edit_work_group: 'Edit Work Group',
  infrastructure_groups: 'Infrastructure Groups',
  infrastructure_group: 'Infrastructure Group',
  edit_infrastructure_group: 'Edit Infrastructure Group',
  add_infrastructure_group: 'Add Infrastructure Group',
  please_select_infrastructure_group: 'Please Select Infrastructure Group',
  explanation: 'Explanation',
  please_select_work_group: 'Please Select Work Group',
  date_of_employment: 'Date Of Employment',
  overdue: 'Overdue',
  upcoming: 'Upcoming',
  my_work_orders: 'My Work Orders',
  my_daily_work_orders: 'My Daily Work Orders',
  my_work_order: 'My Work Order',
  minutes: 'minute(s)',
  start: 'Start',
  end: 'End',
  role_trainings: 'Role Trainings',
  you_have_unsaved_changes_do_you_want_to_leave: 'Do you really want to leave? You have unsaved changes!',
  year: 'Year',
  training_tracking: 'Training Tracking',
  training_tracking_by_training: 'Training Tracking Based Training',
  user_training_tracking: 'User Training Tracking',
  user_training_trackings: 'User Training Trackings',
  no_expire_date: 'No Expire Date',
  remaining_days: 'Remaining Days',
  month: 'Month',
  repetition_frequency: 'Repetition Frequency',
  job_description: 'Job Description',
  change_status: 'Change Status',
  order_sub_status: 'Status Detail',
  current_order_status: 'Current Status',
  current_order_sub_status: 'Current Status Detail',
  sub_work_orders: 'Sub Work Orders',
  name_surname: 'Name Surname',
  master: 'Master',
  observer: 'Observer',
  show_on_calendar: 'Show in Calendar',
  hide: 'Hide',
  abbreviation_minute: 'dk',
  empty: 'Empty',
  status: 'Status',
  no_current_sub_status: 'No Current Status Detail',
  security_items: 'Security Items',
  security_item: 'Security Item',
  add_security_item: 'Add Security Item',
  edit_security_item: 'Edit Security Item',
  send_activation_mail: 'Send Activation Mail',
  reset_password: 'Reset Password',
  ui_item: 'UI Item',
  items: 'Items',
  authorize: 'Authorize',
  will_be_deleted_permanently: 'This will be permanently deleted. Are you sure?',
  work_order_time_conflict_title: 'Are you sure about adding this work ?',
  work_order_time_conflict_description: 'User has already an asigned work in this time period.',
  display_error_404: 'OOPS! Something went wrong here',
  display_error_401: 'Unauthorized Access Error',
  redirect_to_home: 'Home',
  security_item_type: 'Security Item Type',
  is_certificated_training: 'Is Certificated Training',
  experience: 'Experience',
  empty_topologic_view: 'There is no topological device information for the selected building.',
  instructor: 'Instructor',
  work_order_duration: 'Duration (Minutes)',
  work_constraints: 'Work Constraints',
  device_constraints: 'Device Constraints',
  room_constraints: 'Room Constraints',
  day: 'Day',
  hour: 'Hour',
  minute: 'Minute',
  total_effective_capacity: 'Total Effective Capacity',
  altitude_meter: 'Altitude(m)',
  relative_humidity: 'Relative Humidity',
  please_select_model: 'Please Select Model',
  component: 'Component',
  add_component: 'Add Component',
  add_new_record: 'Add New Record',
  edit_component: 'Edit Component',
  remove_component: 'Remove Component',
  edit_record: 'Edit Record',
  remove_record: 'Remove Record',
  record_name: 'Record Name',
  selected_maintenance_periods: 'Selected Maintenance Periods',
  reference_library: 'Reference Library',
  reference_library_item: 'Reference Library Item',
  add_reference_library_item: 'Add Reference Library Item',
  edit_reference_library_item: 'Edit Reference Library Item',
  dry_bulb_temperature_min_max: 'Dry Bulb Temperature Min Max',
  wet_bulb_temperature_min_max: 'Wet Bulb Temperature Min Max',
  document_status: 'Document Status',
  validity_status: 'Validity Status',
  version: 'Version',
  control_frequency: 'Control Frequency',
  last_check_date: 'Last Check Date',
  add_description: 'Add Description',
  edit_description: 'Edit Description',
  text: 'Text',
  related_device: 'Related Device',
  spare_parts_stock_price: 'Spare Parts Stock Price',
  parts_replacement_price: 'Parts Replacement Price',
  required_man_hour_price: 'Required Man/Hour Price',
  annual_contract_price: 'Annual Contract Price',
  contract_duration: 'Contract Duration (Month)',
  contract_start_date: 'Contract Start Date',
  service_provider: 'Service Provider',
  date_of_warranty_start: 'Date Of Warranty Start',
  warranty_duration: 'Warranty Duration (Month)',
  expected_life_end_date: 'Expected Life End Date',
  suggested_run_hour: 'Suggested Run Hour',
  mean_time_to_failure_theoretical_hour: 'Mean Time To Failure Theoretical Hour',
  mean_time_to_failure_statistical_hour: 'Mean Time To Failure Statistical Hour',
  mean_time_between_failure_theoretical_hour: 'Mean Time Between Failure Theoretical Hour',
  mean_time_between_failure_statistical_hour: 'Mean Time Between Failure Statistical Hour',
  mean_time_to_repair_theoretical_hour: 'Mean Time To Repair Theoretical Hour',
  mean_time_to_repair_statistical_hour: 'Mean Time To Repair Statistical Hour',
  sla: 'SLA (Hour)',
  service_level_agreement: 'Service Level Agreement  (SLA)',
  show_on_organization_chart: 'Show On Organization Chart',
  number_of_trainings_to_be_taken_less_than_n_days: 'Number Of Trainings To Be Taken Less Than {0} Days',
  number_of_users_need_to_be_get_training_less_than_n_days: 'Number Of Users Need To Be Get Training Less Than {0} Days',
  number_of_capacity_equipment_without_scp_form: 'Number of Capacity Equipment without SCP Form',
  training_demand: 'Training Demand',
  please_select_service_provider: 'Please Select Service Provider',
  scheduled_date: 'Scheduled Date',
  scheduled_hour: 'Scheduled Hour',
  actual_date: 'Actual Date',
  actual_time: 'Actual Time',
  mass_work_order: 'Mass Maintenance Plan',
  mass_work_orders: 'Mass Maintenance Plans',
  add_mass_work_order: 'Add Mass Maintenance Plan',
  edit_mass_work_order: 'Edit Mass Maintenance Plan',
  total_plan: 'Total Plan',
  total_duration: 'Total Duration',
  maintenance_group: 'Maintenance Group',
  maintenance_plan_of_device_name: 'Maintenance Plan of {deviceName}',
  preventive_maintenance_deferment_condition_first_part: 'Work is  considered as delayed if the work does not end or is rejected within',
  preventive_maintenance_deferment_condition_second_part: ' days of the scheduled date.',
  selected: 'Selected',
  you_must_select_work_for_all_checked_equipments: 'You must select work for all checked equipments.',
  you_must_select_at_least_one_equipment: 'You must select at least one equipment.',
  last_maintenance_date: 'Last Maintenance Date',
  all_selected_data_will_disappear: 'All selected data will disappear.',
  you_must_select_at_least_one_work: 'You must select at least one work.',
  will_remain_as_maintenance_plan: 'Will remain as maintenance plan.',
  please_enter_a_cancellation_description: 'Please enter a cancellation description.',
  training_not_being_enrolled: 'This training not being enrolled',
  work_orders_pending_approval: 'Work Orders To Be Approved',
  approve: 'Approve',
  approved: 'Approved',
  decline: 'Decline',
  declined: 'Declined',
  showing_count: 'Showing {0} to {1} of {2} entries',
  first: 'First',
  previous: 'Previous',
  forms: 'Forms',
  form: 'Form',
  add_form: 'Add Form',
  edit_form: 'Edit Form',
  image: 'Image',
  definition: 'Definition',
  questions: 'Questions',
  question: 'Question',
  add_question: 'Add Question',
  edit_question: 'Edit Question',
  lifetime: 'Lifetime',
  topology: 'Topology',
  financial: 'Financial',
  edit_description_item: 'Edit Description Item',
  add_description_item: 'Add Description Item',
  edit_service_provider_item: 'Edit Service Provider Item',
  add_service_provider_item: 'Add Service Provider Item',
  edit_financial_item: 'Edit Financial',
  add_financial_item: 'Add Financial',
  confirm: 'Confirm',
  deny: 'Deny',
  work_order_status_descriptions: 'Work Order Status Descriptions',
  work_order_status_description: 'Work Order Status Description',
  add_work_order_status_description: 'Add Work Order Status Description',
  edit_work_order_status_description: 'Edit Work Order Status Description',
  you_will_be_redirected_to_date: 'You will be redirected to {0}.',
  you_will_be_redirected_to_page: 'You will be redirected to {0} page.',
  form_status: 'Form Status',
  form_started: 'Form Started',
  please_hover_for_further_information: 'Please hover for further information.',
  add_new: 'New',
  apply_all: 'Apply All',
  print: 'Print',
  clone: 'Clone',
  started: 'Started',
  not_started: 'Not Started',
  previous_month: 'Previous Month',
  next_month: 'Next Month',
  previous_week: 'Previous Week',
  previous_two_week: 'Previous Two Week',
  next_two_week: 'Next Two Week',
  zoom_in: 'Zoom In',
  zoom_reset: 'Zoom Reset',
  zoom_out: 'Zoom Out',
  collapse: 'Collapse',
  expand: 'Expand',
  find_next: 'Find Next',
  find_previous: 'Find Previous',
  assign_staff: 'Assign Staff',
  assigned_staff: 'Assigned Staff',
  assigned_staffs: 'Assigned Staffs',
  note: 'Note',
  none: 'None',
  form_answers: 'Answered Forms',
  last_modified_date: 'Last Modified Date',
  last_modified_user: 'Last Modified By',
  estimated_duration: 'Estimated Duration',
  actual_duration: 'Actual Duration',
  assigned: 'Assigned',
  upload: 'Upload',
  drag_drop: 'Drag & Drop',
  accepted_extensions: 'Accepted Extensions',
  click_here_to_browse: 'Click here to browse',
  upload_file: 'Upload file',
  form_name: 'Form Name',
  form_code: 'Form Code',
  form_type: 'Form Type',
  max_version: 'Max Version',
  question_name: 'Question Name',
  question_group_name: 'Question Group Name',
  required: 'Required',
  answer_group: 'Answer Group',
  answer_type: 'Answer Type',
  option: 'Option',
  please_answer_the_questions: 'Please answer the questions',
  started_by: 'Started By',
  started_at: 'Started At',
  form_not_started: 'Form Not Started',
  save_finish: 'Save and Finish',
  save_next: 'Save and Next',
  feedback: 'Feedback',
  feedbacks: 'Feedbacks',
  add_feedback: 'Add Feedback',
  answered_at: 'Answered At',
  answered_by: 'Answered By',
  answers: 'Answers',
  signatures: 'Signatures',
  signature: 'Signature',
  prepared_by: 'Prepared By',
  confirmed_by: 'Confirmed By',
  show: 'Show',
  upload_form: 'Upload Form',
  print_empty_form: 'Print Empty Form',
  remaining_day: 'Remaining day',
  revising_date_approaching: 'Revising Date Approaching',
  revising_date_has_passed: 'Revising Date Has Passed',
  sent_by: 'Sent By',
  sent_date: 'Sent Date',
  events_only: 'Events only',
  previous_question: 'Previous Question',
  create_new_version: 'Create a new version.',
  there_is_no_work_order_in_this_status: 'There is no work order in this status.',
  form_process_status: 'Form Process Status',
  show_only_has_feedback_ones: 'Show only has feedback ones',
  version_upgraded: 'Version upgraded.',
  please_answer_all_required_questions: 'Please answer all required questions.',
  wait_until_upload_process_done: 'There is upload processing. Wait until process done or abort process.',
  read_only: 'Read only',
  hide_time_ranges: 'Hide time ranges',
  work_order_count: 'Work Order Count',
  shift_count: 'Shift Count',
  unable_to_delete_shift: 'Past dated shifts cannot be deleted',
  error: 'Error',
  tool: 'Tool',
  add_tool: 'Add Tool',
  edit_tool: 'Edit Tool',
  tool_type: 'Tool Type',
  tool_log_type: 'Process Type',
  tool_types: 'Tool Types',
  add_tool_type: 'Add Tool Type',
  edit_tool_type: 'Edit Tool Type',
  tool_sub_type: 'Tool Sub Type',
  tool_sub_types: 'Tool Sub Types',
  add_tool_sub_type: 'Add Tool Sub Type',
  edit_tool_sub_type: 'Edit Tool Sub Type',
  tool_brand: 'Tool Brand',
  tool_brands: 'Tool Brands',
  add_tool_brand: 'Add Tool Brand',
  edit_tool_brand: 'Edit Tool Brand',
  tool_model: 'Tool Model',
  tool_models: 'Tool Models',
  add_tool_model: 'Add Tool Model',
  edit_tool_model: 'Edit Tool Model',
  warehouse: 'Warehouse',
  warehouses: 'Warehouses',
  add_warehouse: 'Add Warehouse',
  edit_warehouse: 'Edit Warehouse',
  please_type_name: 'Please type a name',
  add_to_selected: 'Add to selected',
  please_select_storage: 'Please select a storage',
  please_type_storage_name: 'Please type a storage name',
  toggle_view: 'Toggle View',
  add_child_to_item: 'Add Child',
  edit_item: 'Edit',
  delete_item: 'Delete',
  please_select_warehouse: 'Please select warehouse',
  selected_warehouse: 'Selected Warehouse',
  quantity: 'Quantity',
  add: 'Add',
  would_you_like_to_detail_entries: 'Would you like to detail entries?',
  calibration: 'Calibration',
  undefined_error: 'Undefined Error!',
  tool_calendar: 'Tool Calendar',
  related_work_orders: 'Related Work Orders',
  history: 'History',
  reservation: 'Reservation',
  next_calibration_date: 'Next calibration date',
  next_maintenance_date: 'Next maintenance date',
  this_field_is_required: 'This field is required.',
  invalid_date_time: 'Invalid date time',
  reserved: 'Reserved',
  lended: 'Lended',
  returned: 'Returned',
  reserve: 'Reserve',
  lend: 'Lend',
  return: 'Return',
  tool_status: 'Tool status',
  estimated_return_date: 'Estimated return date',
  estimated_return_time: 'Estimated return time',
  training_duration_empty: 'Training Duration',
  deselect_label: 'Press to remove',
  selected_label: 'Selected',
  no_option: 'List is empty.',
  select_label: 'Press to select',
  no_rows_to_display: 'No Rows To Display',
  item_saved_successfully: '{0} saved.',
  item_deleted_successfully: '{0} named record deleted.',
  selected_only_save: 'Only selected {0} record saved.',
  actor: 'Actor',
  actors: 'Actors',
  point_definitions: 'Point Definitions',
  point_definition: 'Point Definition',
  add_point_definition: 'Add Point Definition',
  edit_point_definition: 'Edit Point Definition',
  points: 'Points',
  point: 'Point',
  add_point: 'Add Point',
  clone_point: 'Clone Point',
  edit_point: 'Edit Point',
  point_name: 'Point Name',
  point_description: 'Point Description',
  point_type: 'Point Type',
  point_clone_type: 'Place To Clone',
  number_input: 'Numeric Value Input',
  decimal_input: 'Decimal Value Input',
  add_question_group: 'Add Question Group',
  edit_question_group: 'Edit Question Group',
  selected_point: 'Selected Point',
  expected_value: 'Expected Value',
  delete_question: 'Delete Question',
  delete_question_group: 'Delete Question Group',
  add_point_linked_question: 'Add Point Linked Question',
  updated: 'Updated',
  created: 'Created',
  work_order_history_created: 'The work order was created at {2} status for {0} which will last for {1} minutes.',
  scheduled_time_history_updated: 'Scheduler time updated from {0} to {1}',
  duration_history_updated: 'Duration updated from {0} to {1}',
  status_history_updated: 'Work Order Status updated from {0} to {1}',
  inventory_information: 'Inventory Information',
  attachments: 'Ekler',
  comments: 'Comments',
  number_of_flag: 'Number Of Flag',
  flags: 'Flags',
  number_of_abnormal_answers: 'Number of Abnormal Answers',
  attached_pic: 'Attached Pic',
  preparations: 'Preparations',
  users_who_need_the_most_trainings: 'Users Who Need The Most Trainings',
  most_needed_trainings: 'Most Needed Trainings',
  training_count: 'Training Count',
  add_rule: 'Add Rule',
  add_group: 'Add Group',
  minimum: 'Minimum',
  maximum: 'Maximum',
  person_who_needed: 'Person Who Needed',
  options_selected: 'options selected',
  feedback_saved_successfully: 'Feedback saved.',
  feedback_deleted_successfully: 'Feedback deleted.',
  invalid_answer_count: 'Invalid Answer Count',
  show_only_has_invalid_answer_ones: 'Show only has invalid answer ones',
  authorization: 'Authorization',
  method_authorization: 'Methods Authorization',
  edit_authorization: 'Edit Authorization',
  add_authorization: 'Add Authorization',
  action: 'Action',
  action_group: 'Action Group',
  add_action: 'Add Action',
  add_action_group: 'Add Action Group',
  undefined_user: 'Undefined User',
  deleted_user: 'Deleted User',
  target: 'Target',
  message: 'Message',
  you_will_not_be_able_to_copy_for_some_targets: 'You will not be able to copy for some rooms, devices or capacity equipments.',
  spare_parts: 'Spare Parts',
  spare_part: 'Spare Part',
  add_spare_part: 'Add Spare Part',
  edit_spare_part: 'Edit Spare Part',
  point_removed: 'Point removed.',
  copied_points: 'Copied Points',
  username_password_is_required: 'Username and Password fields are required.',
  username_is_required: 'Username field is required.',
  password_is_required: 'Password field is required.',
  contract_definition: 'Contract Definition',
  contract_name: 'Contract Name',
  contract_number: 'Contract No',
  contract_start__date: 'Contract Start Date',
  contract_finish_date: 'Contract Finish Date',
  contract_price: 'Contract Price',
  contract_section: 'Contract Section',
  financial_information: 'Financial Information',
  vendor_information: 'Vendor Information',
  vendor_name: 'Vendor Name',
  authorized_staff: 'Authorized Staff',
  n_of_n_saved: '{0} of {1} records saved',
  remove_added_points: 'Remove added points.',
  contracts: 'Contracts',
  contract_list: 'Contract List',
  end_date_cannot_be_before_start_date: 'End date cannot be before than Start date.',
  start_date_and_end_date_cannot_be_same: 'Start date and End date cannot be the same.',
  contract_save_successfully: 'Contract save successfully.',
  add_file: 'Add File',
  rounds_which_is_not_invalid_most_configuration: 'Rounds Which Is Not Invalid Most Configuration',
  capacity_equipments_which_is_not_invalid_most_configuration: 'Capacity Equipments Which Is Not Invalid Most Configuration',
  work_order_count_by_categories: 'Work Order Count By Categories',
  most_repetitive_problem_inventory_and_point: 'Most Repetitive Problem Inventory And Point',
  count: 'Count',
  generate_gap_report: 'Generate Gap Report',
  staff_who_spends_most_time_with_round_in_n: 'Staff Who Spends Most Time With Round In {0}',
  work_order_number: 'Work Order No',
  start_and_end_dates_are_required: 'Start And End Dates Are Required.',
  edit_file: 'Edit File',
  customize_working_hours: 'Customize Working Hours',
  weekdays_only: 'Weekdays',
  seven_twenty_four: '7/24',
  including_public_holidays: 'Including Public Holidays',
  point_save_successfully: 'Point save successfully.',
  add_preventive_maintenance: 'Add Preventive Maintenance',
  edit_preventive_maintenance: 'Edit Preventive Maintenance',
  reference_type: 'Reference Type',
  reference_date: 'Reference Date',
  total_count: 'Total Count',
  remained_count: 'Remained Count',
  tolerance: 'Tolerance',
  last_maintenance: 'Last Maintenance',
  next_maintenance: 'Next Maintenance',
  create_plan: 'Create Plan',
  remained_count_cannot_be_greater_than_total_count: 'Remained Count Cannot Be Greater Than Total Count',
  pm_save_successfully: 'Preventive maintenance save successfully',
  add_sla: 'Add Sla',
  edit_sla: 'Edit Sla',
  condition: 'Condition',
  contact_person: 'Contact Person',
  criticality_level: 'Criticality Level',
  reaction_time: 'Reaction Time (Hours)',
  work_around_time: 'Work Around Time (Hours)',
  permanent_solution_time: 'Permanent Solution Time (Hours)',
  contact_person_phone_number: 'Contact Person Phone Number',
  contact_person_mail: 'Contact Person Mail',
  file_saved_successfully: ' file_saved_successfully',
  add_section: ' Add Section',
  edit_section: 'Edit Section',
  section_name: 'Section Name',
  contract_attachments: 'Attachments',
  add_attachment: 'Add attachment',
  edit_attachment: 'Edit attachment',
  edit_plan: 'Edit Plan',
  contract_price_can_not_be_less_than_zero: 'Contract price cannot be less than 0.',
  min_value_validation: 'Please enter a value equal to or greater than {0}.',
  start_time_cannot_be_after_end_time: 'Start time cannot be after End time.',
  working_hours: 'Working Hours',
  at_least_one_character_must_be_entered: 'At least one character must be entered.',
  only_decimal_and_integer_must_be_entered: 'Only decimal and integer must be entered.',
  send_back: 'Send Back',
  sent_back: 'Sent Back',
  pending_approval_work_order: 'Pending approval {0} work order',
  are_you_sure_you_want_to_send_it_bak: 'Are you sure you want to send it back ?',
  sent_back_successfully: '{0} work order sent back successfully.',
  search_user: 'Search User',
  ask_status_update_from_to: 'Do you want to {0} update status from {1} to {2}?',
  no_forms_to_display: 'No forms to display',
  status_update_work_order_successfully: ' {0} work order status updated successfully',
  do_you_want_to_continue_answering_the_form: 'Do you want to continue answering the form?',
  all_procedures: 'All Procedures',
  update: 'Update',
  are_you_sure_you_want_to_leave_the_page: 'Are you sure want to leave the page?',
  you_have_unsaved_changes: 'You have unsaved changes',
  save_and_exit: 'Save Exit',
  available: 'Available',
  busy: 'Busy',
  master_training: 'Master Training',
  observer_training: 'Observer Training',
  number_of_total_capacity_equipment: 'Number Of Total Capacity Equipment',
  number_of_total_capacity_equipment_by_infrastructure: 'Number Of Total Capacity Equipment Infrastructure',
  reports: 'Reports',
  work_order_report: 'Work Order Report',
  staff_report: 'Staff Report',
  weekly_round_report: 'Weekly Round Report',
  date: 'Date',
  round_name: 'Round Name',
  device_health_report: 'Device Health Report',
  how_many_times_check: 'How Many Times Check',
  how_many_times_abnormal_answer_received: 'How Many Times Abnormal Answer Received (Point)',
  total: 'Total',
  between_to: 'Between {0} to {1} ',
  main_work_order: 'Main Work Order',
  main_work_order_number: 'Main Work Order No',
  end_of_maintenance_contract: ' End of Maintenance Contract',
  end_of_guarantee_time: 'End of Guarantee Time',
  comment: 'Comment',
  commented_by: 'Commented by',
  commented_at: 'Commented at',
  min: 'min',
  user_assigned: 'Personnel assignment process was made.',
  scheduled_time: 'Scheduled Time',
  you_dont_have_permission_to_home_page: 'You don\'t have permission to see home page',
  close: 'Close',
  exit: 'Exit',
  work_description: 'Work Description',
  work_order_description: 'Work Order Description',
  shifts: 'Shifts',
  pending_maintenance_plans_for_assign: 'Pending Maintenance Plans For Assign',
  all_forms: 'All Forms',
  please_select_your_download_request: 'Please Select Your Download Request',
  deleted_entry: 'Deleted entry',
  is_domain_user: 'Domain User',
  username: 'Domain Username',
  email_username: 'Email / Username',
  priority: 'Priority',
  planned: 'Planned',
  incident: 'Incident',
  type_name: 'Type Name',
  please_select_work_order_category: 'Please Select Incident Category',
  work_order_category: 'Incident Category',
  please_select_priority: 'Please Select Priority',
  add_incident: 'Add Incident',
  incidents: 'Incidents',
  id_no: 'No',
  daily_tasks: 'Daily Tasks',
  my_daily_tasks: 'My Daily Tasks',
  tasks: 'Tasks',
  my_tasks: 'My Tasks',
  task_detail: 'Task Detail',
  task: 'Task',
  create_task: 'Create Task',
  task_no: 'Task No',
  task_category: 'Category',
  status_note: 'Status Note',
  add_task: 'Add Task',
  edit_task: 'Edit Task',
  you_dont_have_permission: 'You don\'t have permission to perform this action.',
  deferment_condition: 'Deferment Time',
  is_delayed: 'Is Delayed',
  time_elapsed_since_last_maintenance: 'Time Elapsed Since Last Maintenance',
  planned_maintenance_date: 'Planned Maintenance Date',
  performed_maintenance_date: 'Performed Maintenance Date',
  periodic_maintenance_report: 'Periodic Maintenance Report',
  send_work_order_notification_to_vendor: 'Send notification to vendor',
  notify_before_days: 'Notify before (Days)',
  incident_report: 'Incident Report',
  historical_record: 'Historical record',
  captcha: 'Captcha',
  in_use_date: 'Date Of In Use',
  staff_who_input_most_invalid_answers_in_n: 'Staff Who Input Most Invalid Answers In {0}',
  staff_who_made_most_round_in_n: 'Staff Who Made Most Round In {0}',
  round_count_in_n: 'Round Count In {0}',
  all_asset: 'All Asset Report',
  preview: 'Preview',
  required_total_training: 'Required Training',
  not_exists_file: 'File not exist',
  spare_part_category: 'Spare Part Category',
  add_spare_part_category: 'Add Spare Part Category',
  edit_spare_part_category: 'Edit Spare Part Category',
  spare_part_sub_category: 'Spare Part Sub Category',
  add_spare_part_sub_category: 'Add Spare Part Sub Category',
  edit_spare_part_sub_category: 'Edit Spare Part Sub Category',
  spare_part_type: 'Spare Part Type',
  add_spare_part_type: 'Add Spare Part Type',
  edit_spare_part_type: 'Edit Spare Part Type',
  spare_part_kind: 'Spare Part Kind',
  add_spare_part_kind: 'Add Spare Part Kind',
  edit_spare_part_kind: 'Edit Spare Part Kind',

  stock_count: 'Stock Count',
  unit_price: 'Average Unit Price',
  currency: 'Currency',
  supply_day_count: 'Supply Day Count',
  critical_stock_count: 'Critical Stock Count',

  add_new_floor: 'Add New Floor',
  add_at_least_one_floor: 'Please add at least one floor.',
  discard_product: 'Discard Product',
  discard_products: 'Discard Products',
  changes_applied_successfully: 'Changes applied successfully'
};
